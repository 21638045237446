@use "../abstracts" as *;

.sent-cards {
  position: relative;
}

#account-update .layout__item {
  @include xs {
    grid-column: 1/1;
  }
}

.myaccount-cards {
  margin-bottom: 1rem;
}

.myaccount-card {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-gray-6);
  }

  .myaccount-image {
    flex: 20%;
    max-width: 20%;
    position: relative;

    &__wrapper {
      img {
        border-radius: var(--ui-roundness);
      }

      overflow: visible;
      z-index: unset;
    }

    &__gift-amount {
      color: white;
      position: absolute;
      font-weight: 500;
      bottom: 1px;
      right: 4px;
      font-size: 0.8rem;
    }

    &__gift-wrapper {
      position: absolute;
      overflow: visible;
      z-index: unset;
      bottom: -6px;
      right: -6px;
      border-radius: var(--ui-roundness);
      border: 2px solid white;
      background-color: white;
      box-shadow: 0px 2.5px 5px 0px rgba(0, 0, 0, 0.25);

      button {
        width: 80px;
        height: 100%;

        &:focus-visible {
          outline-offset: 3px;
        }
      }

      .tooltip {
        &__content {
          white-space: normal;
          padding: 8px;
          width: 110px;
          top: 123%;
          left: 50%;
          text-align: center;
          border: 1px solid var(--color-gray-4);
          background-color: #f6f6f6;
          box-shadow: none;

          p {
            font-size: 0.75rem;
          }

          &::after,
          &::before {
            top: -4px;
            width: 6px;
            height: 6px;
            background-color: #f6f6f6;
            left: calc(50% - 3px);
            border-radius: 0;
            box-shadow: none;
          }

          &::after {
            border-left: 1px solid var(--color-gray-4);
            border-top: 1px solid var(--color-gray-4);
          }
        }
      }

      &.--custom-song {
        width: 55px;

        img {
          width: 100%;
          height: 100%;
          aspect-ratio: unset;
        }
      }
    }
  }

  .myaccount-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 75%;
    max-width: 75%;

    :first-child {
      margin-top: 0;
      margin-bottom: 0;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .status-badge {
        white-space: nowrap;
      }

      p {
        font-weight: 500;
      }
    }

    &__controls {
      display: flex;
      font-size: .875rem;
    }

    &__cancel {
      button {
        text-decoration: underline;
      }
    }

    &__body {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      p,
      span {
        font-size: .875rem;
      }

      div {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    &__recipient {
      font-size: .875rem;
      color: var(--color-gray-2);

      .--dark-text {
        color: var(--color-base);
        font-weight: 500;
      }
    }
  }

  @include xs {
    flex-direction: column;
    margin-top: 0;

    .myaccount-image {
      align-self: center;
      max-width: 100%;

      &__wrapper {
        width: 240px;
        height: 192px;
      }

      &__gift-wrapper {
        left: calc(50% - 40px);
        right: unset;
        bottom: -20px;

        &.--custom-song {
          left: calc(50% - 25px);
        }
      }
    }

    .myaccount-info {
      gap: 1rem;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100%;
      margin-top: 3rem;
      text-align: center;

      &__head,
      &__body {
        flex-direction: column;
        align-items: center;
      }

      &__head {
        gap: 0.5rem;
      }
      &__recipient-name {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.empty-response-wrapper {
  &.--hidden {
    display: none;
  }
}

.order-history-page {
  .auto-renew-text {
    margin-top: 2rem;
  }

  .order-history-title {
    font-size: 1.4625rem;
    line-height: 1.36em;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
  }
}

.digital-gifts-filter {
  display: flex;
  gap: 0.5rem 1.5rem;
  flex-wrap: wrap;

  .checkbox__text {
    white-space: nowrap;
    font-size: .85rem;
    letter-spacing: .6px;
    margin-left: 5px;
  }

  @include xs {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: calc(var(--offset-gap)* -1);
    padding: var(--offset-gap);
    max-width: var(--container-gaps);
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include max(600) {
    max-width: calc(var(--client-width, 100wv) - 2 * var(--offset-gap));
  }
}

#email-preferences,
#manage-membership {
  --ui-vertical-spacing: 1.1rem;
}

.reset-filters,
.filter-email {
  &.--hidden {
    display: none;
  }
}

#pof-update {
  .accordion__content {
    padding: 1.2rem 0 0;
  }

  table {
    thead {
      position: relative;
      top: unset;

      th {
        padding: 0 .70rem;
        color: var(--color-gray-2);
        font-size: 0.7825rem;
        letter-spacing: .2px;

        @include xxs {
          font-size: .7rem;
          padding: 0 .5rem;
        }
      }
    }

    tbody {
      td {
        @include xxs {
          font-size: .7rem;
          padding: 0 .5rem;
        }
      }
    }
  }
}

.payment-info,
.billing-info {
  h2 {
    margin-bottom: .5rem;
  }

  p:not(:last-child) {
    margin-bottom: .25rem
  }
}

.manage-membership-block {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1.25rem;
  border-radius: 10px;
  border: 1px solid var(--color-gray-6);
  background: #f6f6f6;

  svg {
    min-width: 32px;
  }
}

#manage-membership {
  .field {
    --ui-checkbox-size: 40px;
    .checkbox {
       --field-checkbox-width: calc(var(--field-checkbox-size) + 42px);
       width: var(--field-checkbox-width);
    }
    @include sm {
      justify-items: center;
    }
  }
}
