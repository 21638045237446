@use '../abstracts' as *;

.top-banner {
  --banner-roundness: var(--ui-roundness-lg, 0);
  --s-ratio-top-override: initial;
  --s-ratio-bot-override: initial;
  --banner-height: 400px;
  --is-not-full: 1;

  .join-flow .layout__item & {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.--text-only {
    --banner-height: 200px;
  }

  body.landing_page & {
    @include to_sm {
      --s-ratio-bot-override: .5;
    }
  }

  .top-content-wrapper & {
    --s-ratio-bot-override: 0;
  }

  &.--full-banner {
    --is-not-full: 0;
    --banner-roundness: 0px;
    --s-ratio-top-override: 0;
    --s-ratio-bot-override: 0;
    position: relative;

    .container {
      position: static;
    }

    &.--image-only {
      height: var(--banner-height);
    }

    @include to_xs {
      aspect-ratio: var(--banner-aspect-ratio, unset);
    }
  }

  &:last-of-type {
    --s-ratio-bot: var(--s-ratio-bot-override, 1);
  }

  &:first-child {
    --s-ratio-top: var(--s-ratio-top-override, .5);
  }

  &__image {
    border-radius: var(--banner-roundness);
    overflow: hidden;

    .top-banner:not(.--image-only) &,
    .top-banner.--full-banner & {
      position: absolute;
      width: calc(100% - (var(--is-not-full) * var(--offset-gap) * 2));
      height: 100%;
      left: calc(var(--is-not-full) * var(--offset-gap));
      top: 0;

      @include sm {
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
      }

      img,
      &>* {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0;

        @include sm {
          position: relative;
        }
      }

      img {
        object-fit: cover;
        object-position: center;
      }
    }

    .--aside .top-banner:not(.--image-only).--has-image-and-text & {
      width: 100%;
      left: 0;
    }

    .top-banner.--has-image-and-text.--video-content & {
      @include to_sm {
        position: relative;
      }
    }

    &.--bg-gray {
      background-color: var(--background-color-gray);
    }

    &.--bg-white {
      background-color: var(--color-white);
    }
  }

  &__video {
    position: relative !important;
    height: auto !important;

    video {
      display: grid;
      border-radius: var(--banner-roundness);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    .top-banner:not(.--full-banner) & {
      @include to_xs {
        aspect-ratio: var(--banner-aspect-ratio, unset);
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    left: 0;
    top: 0;
    position: relative;
    width: 100%;
    z-index: 0;
    --heading-scale: 1;
    min-height: var(--banner-height);

    .top-banner.--has-image-and-text.--video-content & {
      @include to_sm {
        min-height: unset;
        position: absolute;
      }
    }

    @include sm {
      --headings-color: var(--color-gray-1);
      color: var(--headings-color);
      padding: 2rem 0 0;
      min-height: auto;
      position: relative;

      h1,
      h2,
      h3,
      p {
        --headings-color: var(--color-gray-1);
        color: var(--headings-color);
      }
    }
  }

  &__link {
    display: grid;

    &:focus-visible {
      border-radius: var(--banner-roundness);
      outline-offset: 3px;
    }
  }

  &__overlay {
    width: 40%;
    min-width: 380px;
    height: fit-content;
    --inset-value: 0px;
    margin: auto;
    padding: 2rem;
    border-radius: var(--banner-roundness);

    &.--overlay-left {
      margin-left: 5%;

      :not(.--aside) & {
        margin-left: 7%;
      }

      @include sm {
        margin: 0 !important;
      }
    }

    &.--overlay-right {
      margin-right: 5%;

      @include sm {
        margin: 0;
      }
    }

    &.--bg-white {
      background: rgba($color-white, .8039215686);

      @include sm {
        background: transparent;
      }
    }

    &.--bg-gray {
      background: rgba($color-gray-1, .6);

      @include sm {
        background: transparent;
      }
    }

    &.--bg-none {
      background: none;
    }

    @include sm {
      width: auto;
      min-width: auto;
      margin: 0;

      padding: 0;
    }
  }
}