.--progress-bar {
    margin-top: 3rem;
    padding: 1.5rem 0;
}

.card-send {
    grid-template-columns: 1fr 2fr;

    textarea {
        width: 100%;
    }
}