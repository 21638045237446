@use "./../abstracts" as *;

.checkmark {
  position: relative;
  z-index: 0;

  &__item {
    --checkbox-color: var(--color-crimson);
    display: flex;
    align-items: flex-start;
  }

  &__box {
    color: var(--checkbox-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;

    .check-icon {
      width: 16px;
      height: 8px;
      border-bottom: 2px currentColor solid;
      border-left: 2px currentColor solid;
      top: -1px;
      transform: scale(0.8) rotate(-45deg);
      position: relative;
      color: currentColor;
      @include trns(0.1s);
    }

  }

  &__text {
    margin-left: 10px;
  }

  &__bullet {
    margin-top: 2px;
  }
}
