@use '../../abstracts' as *;

.rich-text-content {
  all: revert;
  a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    all: revert;
  }
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 1.4;
  max-width: 100%;
  margin: 0 auto;
  width: calc(var(--product-width) - var(--preview-spacing,0) * var(--preview-overflows) * 2);
  font-family:"Gordita Content", sans-serif;
  table {
      border-collapse: collapse
  }

  table:not([cellpadding]) td,table:not([cellpadding]) th {
      padding: .4rem
  }

  table[border]:not([border="0"]):not([style*=border-width]) td,table[border]:not([border="0"]):not([style*=border-width]) th {
      border-width: 1px
  }

  table[border]:not([border="0"]):not([style*=border-style]) td,table[border]:not([border="0"]):not([style*=border-style]) th {
      border-style: solid
  }

  table[border]:not([border="0"]):not([style*=border-color]) td,table[border]:not([border="0"]):not([style*=border-color]) th {
      border-color: #ccc
  }

  figure {
      display: table;
      margin: 1rem auto
  }

  figure figcaption {
      color: #999;
      display: block;
      margin-top: .25rem;
      text-align: center
  }

  hr {
      border-color: #ccc;
      border-style: solid;
      border-width: 1px 0 0 0;
      margin-block-start: 1em;
      margin-block-end: 1em;
  }

  code {
      background-color: #e8e8e8;
      border-radius: 3px;
      padding: .1rem .2rem
  }

  .mce-content-body:not([dir=rtl]) blockquote {
      border-left: 2px solid #ccc;
      margin-left: 1.5rem;
      padding-left: 1rem
  }

  .mce-content-body[dir=rtl] blockquote {
      border-right: 2px solid #ccc;
      margin-right: 1.5rem;
      padding-right: 1rem
  }

  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  &:not(.--empty){
    margin-top: calc(var(--global-inter-content-spacing,2rem) - .4em);
  }
  h1, h2, h3, h4, h5, h6 {
    margin-block-start: .6em;
    margin-block-end: .4em;
    line-height: 1.466666em
  }
  p {
    margin-block-start: .4em;
    margin-block-end: .4em;
    line-height: 1.666666em
  }
  h1, h2 {
    line-height: 1.266666em
  }
  h1 {
    font-size: 1.866em;
  }
  h3 {
    font-size: 1.37em;
  }
  p {
    font-size: 18px;
  }
  hr {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  @media (max-width: 500px) {
    p {
      font-size: 16px;
      text-align: left;
    }
    h1 {
      font-size: 1.54em;
    }
    h3 {
      font-size: 1.27em;
    }
  }
}

