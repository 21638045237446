.search_page{
  .featured-cards{
    display: none;
  }
  .result-list-block{
    --s-ratio-bot: var(--s-ratio-bot-override, 0);
  }
  &.--no-search-results{
    .result-list-block{
      .layout__item.--result-list{
        display: none;
      }
    }
    .featured-cards{
      display: block;
    }
  }
}