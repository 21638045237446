@use "./../abstracts" as *;

.featured-cards{
    .btn-addon {
        height: 100%;
        display: grid;
        align-content: space-between;
    }

    .btn-send {
        margin-top: 1rem;
        min-width: 50px;
    }

    .--text-center {
        .inline{
            justify-content: center;
        }
        .text-block {
            max-width: var(--container-width);
            margin: 0 auto;
            text-align: center;
        }
    }

    .--text-left {
        --columns: 2;
    }

    .--text-right {
        --columns: 2;
        direction: rtl;

        .layout__item {
            direction: ltr;
        }
    }

    .--icon {
        background-size: 30px;
        background-repeat: no-repeat;
        padding-left: 35px;
    }
}