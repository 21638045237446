@use '../abstracts' as *;

.app-download-banner{
    &:not(.--has-content) {
        margin: var(--spacing) 0;
        padding: calc(var(--spacing) * 0.5) 0;
        --ui-vertical-spacing: 0;
    }
    &.--has-content {
        --s-ratio-top-override: .5;
        padding-bottom: calc(var(--s-padding-bot) * .25);
        @include xs {
            --s-ratio-top-override: .8;
            padding-bottom: calc(var(--s-padding-bot) * .5);
        }
        .app-icons {
            display: inline-flex;
            &:not(:first-child) {
                margin-top: .5rem;
            }
        }
        .text-block {
            border-bottom: 1px var(--ui-divider-color) solid;
            padding-bottom: var(--s-padding-bot);
        }
    }
    &.--remove-spacing {
        margin-top: 0;
        padding: 0;
    }
    &__content{
        display: flex;
        justify-content: center;
        align-items: center;
        h2{
            padding-right: 15px;
        }
        @include sm {
            flex-direction: column;
            h2{
                padding: 0 0 calc(var(--spacing)*0.5) 0;
            }
        }
    }
}