@use "../../abstracts/" as *;

.pickup-page {
  background-color: var(--background-color-gray);

  .footer-newsletter {
    box-shadow: inset 0 1px 0 0 var(--ui-divider-color);
  }
}

.product-pickup {
  &:not(.--webview) {
    @include xxs {
      box-shadow: 0 15px 45px 0 #8b1f2a14;
      background-color: var(--color-white);
      --s-ratio-bot: 1.55;

      &.--is-receiver {
        margin-bottom: 1.5rem;
      }
    }
  }

  &.--webview {
    --s-ratio-top: 1 !important;
    --s-ratio-bot: 1 !important;
  }

  @include to_xxs {
    --s-ratio-top: 1 !important;
  }

  .layout__item.--instructions {
    margin: -1rem 0 -0.5rem;
    display: flex;
    justify-content: center;

    .text-block {
      text-align: left;
    }
  }

  &__container {
    @include to_xxs {
      width: unset !important;
    }

    @include xxs {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__box {
    --pickup-padding: 3.2rem;
    padding: var(--pickup-padding);
    border-radius: 15px;
    box-shadow: 0 15px 45px 0 #8b1f2a14, 0 0 0 1px #c329390d;
    background-color: var(--color-white);

    @include md {
      --pickup-padding: 3rem;
    }

    @include xs {
      --pickup-padding: 2rem;
      padding-top: 2.5rem;
    }

    @include xxs {
      --pickup-padding: 0rem;
      padding-top: 0rem;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &__layout {
    width: 100%;
    max-width: var(--product-width, auto);
    grid-template-columns: minmax(1px, 1fr);
  }

  .product-preview__content {
    max-width: 100%;
  }

  .rich-text-content {
    width: 100%;
  }

  .product-preview.--smashup {
    #agi-expression-container {
      position: relative !important;
      z-index: 0 !important;
    }

    #expression-close-button {
      display: none !important;
    }
  }

  .product-preview.--picsnwishes,
  .product-preview.--creatacard {
    --agub-music-cac: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M3.25 14.5C4.49264 14.5 5.5 13.4926 5.5 12.25C5.5 11.0074 4.49264 10 3.25 10C2.00736 10 1 11.0074 1 12.25C1 13.4926 2.00736 14.5 3.25 14.5Z" fill="white"/%3E%3Cpath d="M12.25 13.75C13.4926 13.75 14.5 12.7426 14.5 11.5C14.5 10.2574 13.4926 9.25 12.25 9.25C11.0074 9.25 10 10.2574 10 11.5C10 12.7426 11.0074 13.75 12.25 13.75Z" fill="white"/%3E%3Cpath d="M14.5 1L5.5 3.25V6.25L14.5 4V1Z" fill="white"/%3E%3Cpath d="M14.5 1H15.25V0.0394177L14.3181 0.272393L14.5 1ZM5.5 3.25L5.3181 2.52239L4.75 2.66442V3.25H5.5ZM4.75 12.25C4.75 13.0784 4.07843 13.75 3.25 13.75V15.25C4.90685 15.25 6.25 13.9069 6.25 12.25H4.75ZM3.25 13.75C2.42157 13.75 1.75 13.0784 1.75 12.25H0.25C0.25 13.9069 1.59315 15.25 3.25 15.25V13.75ZM1.75 12.25C1.75 11.4216 2.42157 10.75 3.25 10.75V9.25C1.59315 9.25 0.25 10.5931 0.25 12.25H1.75ZM3.25 10.75C4.07843 10.75 4.75 11.4216 4.75 12.25H6.25C6.25 10.5931 4.90685 9.25 3.25 9.25V10.75ZM13.75 11.5C13.75 12.3284 13.0784 13 12.25 13V14.5C13.9069 14.5 15.25 13.1569 15.25 11.5H13.75ZM12.25 13C11.4216 13 10.75 12.3284 10.75 11.5H9.25C9.25 13.1569 10.5931 14.5 12.25 14.5V13ZM10.75 11.5C10.75 10.6716 11.4216 10 12.25 10V8.5C10.5931 8.5 9.25 9.84315 9.25 11.5H10.75ZM12.25 10C13.0784 10 13.75 10.6716 13.75 11.5H15.25C15.25 9.84315 13.9069 8.5 12.25 8.5V10ZM14.3181 0.272393L5.3181 2.52239L5.6819 3.97761L14.6819 1.72761L14.3181 0.272393ZM4.75 3.25V6.25H6.25V3.25H4.75ZM5.6819 6.97761L14.6819 4.72761L14.3181 3.27239L5.3181 5.52239L5.6819 6.97761ZM15.25 4V1H13.75V4H15.25ZM6.25 12.25V6.25H4.75V12.25H6.25ZM15.25 11.5V4H13.75V11.5H15.25Z" fill="white"/%3E%3C/svg%3E');
    --agub-music-off: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M3.25 14.5C4.49264 14.5 5.5 13.4926 5.5 12.25C5.5 11.0074 4.49264 10 3.25 10C2.00736 10 1 11.0074 1 12.25C1 13.4926 2.00736 14.5 3.25 14.5Z" fill="white"/%3E%3Cpath d="M12.25 13.75C13.4926 13.75 14.5 12.7426 14.5 11.5C14.5 10.2574 13.4926 9.25 12.25 9.25C11.0074 9.25 10 10.2574 10 11.5C10 12.7426 11.0074 13.75 12.25 13.75Z" fill="white"/%3E%3Cpath d="M14.5 1L5.5 3.25V6.25L14.5 4V1Z" fill="white"/%3E%3Cpath d="M14.5 1H15.25V0.0394177L14.3181 0.272393L14.5 1ZM5.5 3.25L5.3181 2.52239L4.75 2.66442V3.25H5.5ZM4.75 12.25C4.75 13.0784 4.07843 13.75 3.25 13.75V15.25C4.90685 15.25 6.25 13.9069 6.25 12.25H4.75ZM3.25 13.75C2.42157 13.75 1.75 13.0784 1.75 12.25H0.25C0.25 13.9069 1.59315 15.25 3.25 15.25V13.75ZM1.75 12.25C1.75 11.4216 2.42157 10.75 3.25 10.75V9.25C1.59315 9.25 0.25 10.5931 0.25 12.25H1.75ZM3.25 10.75C4.07843 10.75 4.75 11.4216 4.75 12.25H6.25C6.25 10.5931 4.90685 9.25 3.25 9.25V10.75ZM13.75 11.5C13.75 12.3284 13.0784 13 12.25 13V14.5C13.9069 14.5 15.25 13.1569 15.25 11.5H13.75ZM12.25 13C11.4216 13 10.75 12.3284 10.75 11.5H9.25C9.25 13.1569 10.5931 14.5 12.25 14.5V13ZM10.75 11.5C10.75 10.6716 11.4216 10 12.25 10V8.5C10.5931 8.5 9.25 9.84315 9.25 11.5H10.75ZM12.25 10C13.0784 10 13.75 10.6716 13.75 11.5H15.25C15.25 9.84315 13.9069 8.5 12.25 8.5V10ZM14.3181 0.272393L5.3181 2.52239L5.6819 3.97761L14.6819 1.72761L14.3181 0.272393ZM4.75 3.25V6.25H6.25V3.25H4.75ZM5.6819 6.97761L14.6819 4.72761L14.3181 3.27239L5.3181 5.52239L5.6819 6.97761ZM15.25 4V1H13.75V4H15.25ZM6.25 12.25V6.25H4.75V12.25H6.25ZM15.25 11.5V4H13.75V11.5H15.25Z" fill="white"/%3E%3Cpath d="M2.00001 2.00001L14.3744 14.3744" stroke="white" stroke-width="2" stroke-linecap="round"/%3E%3C/svg%3E');
    --agub-reset-cac: url('data:image/svg+xml,%3Csvg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6.98716 1.13809e-05C5.11666 0.00337025 3.41834 0.740458 2.1645 1.9387L1.15644 0.930644C0.729665 0.50387 0 0.806112 0 1.40964V5.19356C0 5.56769 0.303286 5.87097 0.677419 5.87097H4.46134C5.06487 5.87097 5.36711 5.14131 4.94036 4.71454L3.76194 3.53611C4.6331 2.72041 5.76035 2.26857 6.95794 2.25824C9.56595 2.23572 11.7643 4.3463 11.7418 7.04088C11.7204 9.59703 9.64803 11.7419 7 11.7419C5.83916 11.7419 4.74202 11.3276 3.87738 10.569C3.7435 10.4515 3.54132 10.4587 3.41538 10.5846L2.29589 11.7041C2.15837 11.8416 2.16517 12.0658 2.30949 12.1961C3.55075 13.3173 5.19564 14 7 14C10.866 14 14 10.866 14 7.00006C14 3.13852 10.8487 -0.00690394 6.98716 1.13809e-05Z" fill="white"/%3E%3C/svg%3E');

    #muteButton,
    #replay-animation-button,
    #play-pause-button {
      padding-top: 0px !important;
      background: none;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;

      &::before {
        content: "";
        background-position: center center;
        background-repeat: no-repeat;
        color: var(--color-primary);
        background-color: var(--color-primary);
        background-size: 14px;
        position: relative;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        top: 0;
        @include trns;
      }

      &:hover,
      &:active {
        cursor: pointer;
        color: var(--color-primary-darker);

        &:before {
          background-color: var(--color-primary-darker);
        }
      }
    }
  }

  .product-preview.--picsnwishes {
    --product-aspect-ratio: 2082 / 2778;
    --product-width: 600px;

    .product-preview__wrapper {
      position: relative;
    }

    .loader {
      display: none;
    }

    .product-preview__content {
      margin: 0 auto;

      .App {
        max-height: unset;
      }

      &.no-before::before {
        display: none;
      }

      &::before {
        background: #f2f2f2;
        animation: none;
      }
    }

    .pnw-loader {
      position: absolute;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      display: grid;
      top: 0;
      align-items: center;
      justify-items: center;
      align-content: center;
      gap: 1rem;

      &__spinner {
        width: 25%;
        mix-blend-mode: multiply;
      }

      &__button,
      &__loading {
        min-width: 256px;
        min-height: 42px;

        svg {
          height: 19px;
          width: 19px;
        }

        @include max(500) {
          min-width: 205px;
          min-height: 40px;
        }
      }

      &__copy {
        text-align: center;
        --h3-scale: 0.9;
        padding: 0 var(--pickup-padding);

        @include xs {
          padding: 0 var(--offset-gap);
          --h3-scale: 0.75;
        }
      }
    }

    #muteButton {
      font-size: 0;
      gap: 0;
      width: 32px;
      height: 32px;
      bottom: 2rem;
      right: 2rem;

      @include xs {
        bottom: 1rem;
        right: 1rem;
      }

      &::before {
        top: 0;
        background-image: var(--agub-music-cac);
        @include trns;
      }

      &.selected {
        &::before {
          background-image: var(--agub-music-off);
        }
      }

    }

    .closeLightbox {
      border-radius: 50%;
      position: absolute;
      background: transparent;
      border: none;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      font-size: 0;
      z-index: 1;
      background-color: var(--color-gray-1);
      opacity: 0.7;

      &:after,
      &:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        right: 8px;
        top: 15px;
        background: var(--color-gray-6);
      }

      &:after {
        transform: rotate(45deg);
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:focus-visible {
        outline-offset: 0;
      }

      @include mouse {
        &:hover {
          cursor: pointer;
          opacity: 1;
          @include trns;
        }
      }
    }

    .product-pickup__footer {
      .copyright__logo img {
        height: 36px;
      }

      @include xxs {
        padding: 1.45rem var(--pickup-padding);
      }
    }
  }

  .product-preview.--creatacard {
    .product-preview__wrapper {
      overflow: hidden;
      padding: 0 var(--pickup-padding);
      margin: 0 calc(-1 * var(--pickup-padding));

      @include xs {
        padding: 0 var(--offset-gap);
        margin: 0 calc(-1 * var(--offset-gap));
      }
    }

    .product-preview__content {
      margin: 0;
      padding-bottom: 1rem;
    }

    #envelope-linear-gradient {
      background: linear-gradient(rgba(255, 255, 255, 0), white 10%) !important;
    }

    #cac-card3d-loader {
      margin: 0 auto;
      width: 90%;
      height: 50vh;
      justify-content: center;
      align-items: center;
      display: flex;

      .agub-spinner {
        background: url(https://ak.imgag.com/product/siteassets/general/3551792/image.gif) no-repeat scroll center 10% white;
        background-size: 200px;
        padding: 100px;
      }
    }

    #replay-animation-button,
    #play-pause-button {
      color: var(--color-primary);
      font-weight: 500;
      letter-spacing: .5px;
      font-size: .825rem;
      background: none;
    }

    #replay-animation-button {
      &::before {
        background-image: var(--agub-reset-cac);
      }
    }

    #play-pause-button {
      &::before {
        background-image: var(--agub-music-cac);
      }

      &.sound-off {
        &::before {
          background-image: var(--agub-music-off);
        }
      }
    }

    #giftCardContainer {
      @include xxs {
        margin: 70px 0 0;
        transform: scale(1.5) !important;
      }
    }

    #giftCard {
      width: 140px !important;
      height: 90px !important;
    }

    #giftcard-title {
      font-size: 1rem;
      font-weight: 500 !important;
      margin-bottom: 1rem;
    }

    #static-gift-title {
      margin-top: 1rem;
      letter-spacing: 1px;
      font-size: 0.8rem;
    }
  }

  &__gift {
    @include to_xs {
      gap: 2rem !important;
    }

    .inline__item:only-child {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .product-pickup.--has-message & {
      border-top: 1px var(--ui-divider-solid-color) solid;
      padding-top: 1.25rem;
      margin-top: -0.5rem;
    }

    .gift-attached {
      box-shadow: none;
      border-radius: 0;
      padding: 0;

      @include to_xs {
        margin-right: 1rem;
      }

      &__description {
        text-align: left;
        text-wrap: balance;
      }

      &__grid {
        @include md {
          grid-template-columns: 108px 1fr;
          max-width: unset;
        }

        @include xxs {
          grid-template-columns: 95px 1fr;
        }
      }

      &__title {
        margin-bottom: 4px;
        margin-top: 1px;
      }

      .status-badge {
        margin-top: 5px;
      }
    }

    @include xs {
      .btn {
        width: 100%;
      }
    }
  }


  &__footer {
    border-top: 1px var(--ui-divider-solid-color) solid;
    background-color: #f7f7f7;
    padding: 1.45rem var(--pickup-padding);
    margin: 0 calc(var(--pickup-padding) * -1) calc(var(--pickup-padding) * -1);
    border-radius: 0 0 15px 15px;

    @include xxs {
      border-radius: 0;
      padding: 1.45rem var(--offset-gap) var(--s-padding-bot);
      margin: 0 calc(var(--offset-gap) * -1) calc(var(--s-padding-bot) * -1);
    }

    &>.inline {
      align-items: center;
    }

    .accordion__container.--active .accordion__content {
      padding: .725rem 0 0;
    }

    .copyright__logo {
      display: grid;

      img,
      svg {
        height: 52px;
        width: auto;

        @include xs {
          margin: 0 auto;
        }

        @include xxs {
          height: 48px;
        }
      }

      img {
        mix-blend-mode: multiply;
      }
    }

    .accordion__container {
      .btn-inline-action {
        margin-left: auto;

        @include xs {
          margin: 0 auto;
        }
      }
    }

    .inline__item.--copyright {
      flex: 2.5;

      @include xs {
        flex-grow: 1;
      }
    }

    .inline__item.--logo {
      flex: 1;

      @include xs {
        flex-grow: 1;
      }
    }

    .product-pickup.--no-logo & .inline__item.--logo {
      @include xs {
        display: none;
      }
    }

    .product-pickup.--no-message.--no-gift & {
      margin-top: 1rem;
    }

    &-copy {
      text-align: right;

      @include xs {
        text-align: center;
      }
    }
  }

  &.--has-gift {
    .product-preview__wrapper.--print {
      .product-preview__content {
        padding-bottom: 3rem;
      }
    }
  }

  &.--no-envelope {
    .product-preview.--creatacard {
      .product-preview__wrapper {
        padding: 4rem calc(2 * var(--pickup-padding));
        margin: -4rem calc(-1 * var(--pickup-padding));

        @include md {
          padding: 4rem calc(4 * var(--pickup-padding));
          margin: -4rem calc(-1 * var(--pickup-padding));
        }

        @include xxs {
          padding: 4rem calc(5 * var(--offset-gap));
          margin: -5rem calc(-1 * var(--offset-gap));
        }

        #play-pause-button,
        #replay-animation-button {
          @include xxs {
            margin: 38px auto 0;
            transform: scale(1.5) !important;
          }
        }
      }
    }
  }

  &.--has-logo.--no-credits.--no-copy {
    .product-pickup__footer {
      .inline {
        justify-content: center;

        .inline__item.--logo {
          flex: unset;
        }
      }
    }
  }

  &.--no-logo.--has-credits,
  &.--no-logo.--has-copy {
    .product-pickup__footer {
      .inline {
        justify-content: center;

        .inline__item.--copyright {
          flex-grow: 1;
        }

        .accordion__container .btn-inline-action {
          margin-left: auto;
          margin-right: auto;
        }

        .accordion__content .text-block {
          text-align: center;
        }
      }

      &-copy {
        text-align: center;
      }
    }
  }
}