.faq-search {
    &:first-child {
        --s-ratio-top: 1;
        margin-top: 1.5rem;
    }

    &:last-of-type {
        --s-ratio-bot: 1;
    }

    #faq-search {
        &.--bg-grey {
            background: var(--color-gray-8);
            padding: 2rem 1rem;
        }

        .--single-field-form-width {
            h1 {
                margin-bottom: 1rem;
            }

            max-width: 600px;
            margin: auto;

            .field {
                &__header {
                    justify-content: center;
                    text-align: center;
                }

                &__label {
                    margin-bottom: .375rem;
                }

                &__input {
                    background: #fff;
                }
            }
        }

        .faq-search__block-holder {
            padding: 5px 0;
        }
    }
}