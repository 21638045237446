@use "../abstracts/" as *;

// Styling overrides for tealium banner and cookie preferences
#privacy_manager {
    &.privacy_prompt {
        width: 100%;
        max-width: 550px;
        background-color: var(--color-white);
        padding: 20px 10px;
        font-size: 1rem;
        line-height: 1.4em;
        padding: 2rem;
        border-radius: 10px;
        box-shadow: var(--ui-box-shadow);
        z-index: 100;

        a {
            text-decoration: none;
            font-weight: 500;
            color: var(--color-primary-lighter);
            text-underline-offset: 3px;

            &:hover {
                text-decoration: underline;
                color: var(--color-primary-darkest);
            }
        }

        .pm-inner {
            flex-direction: row;

            @include xs {
                flex-direction: column;

                .pm-innerl {
                    padding-bottom: 1rem;
                }
            }
        }

        .pm-button-container {
            flex-flow: column;

            @include xs {
                flex-flow: row;
            }

            button {
                --color: var(--color-white);
                --background: var(--color-gray-1);
                --outline: none;
                --color-hover: var(--color-white);
                --background-hover: var(--color-primary-lighter);
                --outline-hover: none;
                --color-active: var(--color-white);
                --background-active: var(--color-primary-darker);
                --outline-active: none;
                background: var(--background);
                min-height: var(--ui-btn-height,42px);
                padding: 6px var(--ui-btn-spacing,1.75rem);
                border-radius: var(--ui-roundness,0);
                font-size: var(--ui-btn-size,.825rem);
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                width: 165px;
                -webkit-transition: background .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-transform .3s var(--transition-delay,0s) var(--reveal-easing);
                transition: background .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-transform .3s var(--transition-delay,0s) var(--reveal-easing);
                -o-transition: box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),transform .3s var(--transition-delay,0s) var(--reveal-easing),background .3s var(--transition-delay,0s) var(--reveal-easing);
                transition: box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),transform .3s var(--transition-delay,0s) var(--reveal-easing),background .3s var(--transition-delay,0s) var(--reveal-easing);
                transition: box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),transform .3s var(--transition-delay,0s) var(--reveal-easing),background .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-transform .3s var(--transition-delay,0s) var(--reveal-easing);

                &:hover {
                    transition: all .2s var(--transition-delay,0s) var(--easing);
                    background: var(--background-hover);
                }

                &#decline_cookies {
                    --color: var(--color-base);
                    --background: var(--color-gray-6);
                    --outline: none;
                    --color-hover: var(--color-base);
                    --background-hover: var(--color-gray-5);
                    --outline-hover: none;
                    --color-active: var(--color-base);
                    --background-active: var(--color-gray-4-5);
                    --outline-active: none;

                    color: var(--color);

                    &:hover {
                        color: var(--color-hover);
                    }
                }

                &:focus-visible {
                    outline-offset: 2px;
                    outline-style: auto;
                    outline-color: var(--color-base);
                }
            }
        }
    }
}

.privacy_prompt.consent_preferences {
    border-radius: 10px;
    border: none;
    padding: 2rem;

    h1 {
        color: var(--color-jet);
        font-weight: 500;
        font-size: 1.5rem;
    }

    table {
        --table-odd-background: #F7F7F7;
        --table-divider-color: #DDD;
        margin-top: 2rem;

        tr {
            border: 1px solid var(--table-divider-color);

            &:nth-child(odd) {
                background-color: var(--table-odd-background);
            }

            th {
                background-color: var(--color-white);
                border-bottom: none;
                font-weight: 400;
                text-align: center;
                color: var(--color-jet);
                text-transform: none;
            }

            td {
                border: 1px solid var(--table-divider-color);

                &:first-child {
                    color: var(--color-jet);
                    padding-left: 15px;
                }
            }
        }
        @media (max-width:768px) {
            tr td{
                &:first-child,
                &.category-description {
                    display: table-row;
                    border: none;
                }
                p {
                    padding:5px
                }
            }

            th {
                &:first-child {
                    width: inherit;
                }
                &:nth-child(2) {
                    display:none;
                }
                &:nth-child(3) {
                    text-align: right;
                }
            }
        }
    }

    [role="switch"].toggle {
        --field-roundness: 30px;
        background-color: var(--color-jet);
        border: 3px solid var(--color-jet);
        box-shadow: none;
        font-size: .5rem;
        padding: 0px 0;
        width: 42px;
        height: 24px;
        border-radius: var(--field-roundness);
        display: flex;
        flex-direction: row-reverse;
        margin: auto;
    }

    [role="switch"].toggle > span.off,
    [role="switch"].toggle > span.on {
        background-color: var(--color-white);
        padding: 4px;
        font-size: .375rem;
        color: var(--color-white);
        width: 18px;
        display: inline-block;
        height: 18px;
        border-radius: 20px;
    }

    [role="switch"][aria-checked="true"].toggle > span.on {
        display: inline-block;
    }

    [role="switch"][aria-checked="false"].toggle {
        background-color: var(--color-gray-4-5);
        border: 3px solid var(--color-gray-4-5);
        font-size: .5rem;
    }

    [role="switch"][aria-checked="true"].toggle > span,
    [role="switch"][aria-checked="true"].toggle:focus > span {
        background-color: var(--color-white);
    }

    .close_btn_thick {
        background: 0 0;
        border: none;
        right: .5rem;
        top: .5rem;
        font-size: 1.125rem;
        font-weight: 700;
        border-radius: 50%;
        height: 44px;
        width: 44px;

        &:hover {
            background-color: #efefef;
        }

        &::after {
            content: "\2715";
        }

        &:focus,
        &:focus-visible   {
            outline: 2px solid;
        }
    }

    .privacy_prompt_content {
        font-size: .9375rem;
    }

    .privacy_prompt_footer {
        padding-top: 8px;

        .button {
            --color: var(--color-white);
            --background: var(--color-gray-1);
            --outline: none;
            --color-hover: var(--color-white);
            --background-hover: var(--color-primary-lighter);
            --outline-hover: none;
            --color-active: var(--color-white);
            --background-active: var(--color-primary-darker);
            --outline-active: none;
            background: var(--background);
            border: none;
            color: var(--color);
            min-height: var(--ui-btn-height,42px);
            padding: 6px var(--ui-btn-spacing,1.75rem);
            border-radius: var(--ui-roundness,0);
            font-size: var(--ui-btn-size,.825rem);
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            -webkit-transition: background .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-transform .3s var(--transition-delay,0s) var(--reveal-easing);
            transition: background .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-transform .3s var(--transition-delay,0s) var(--reveal-easing);
            -o-transition: box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),transform .3s var(--transition-delay,0s) var(--reveal-easing),background .3s var(--transition-delay,0s) var(--reveal-easing);
            transition: box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),transform .3s var(--transition-delay,0s) var(--reveal-easing),background .3s var(--transition-delay,0s) var(--reveal-easing);
            transition: box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),transform .3s var(--transition-delay,0s) var(--reveal-easing),background .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-box-shadow .3s var(--transition-delay,0s) var(--reveal-easing),-webkit-transform .3s var(--transition-delay,0s) var(--reveal-easing);

            &:hover {
                transition: all .2s var(--transition-delay,0s) var(--easing);
                background: var(--background-hover);
            }

            &:focus,
            &:focus-visible   {
                outline: 2px solid;
                outline-style: auto;
                outline-color: var(--color-base);
            }

        }
    }

    @include xs {
        width: 90%;
        padding: 1rem;
    }
}

.privacy_prompt.consent_preferences [role="switch"].toggle:focus,
.privacy_prompt [role="switch"].toggle:focus-visible  {
    outline: 2px solid;
    box-shadow: none;
    outline-offset: 2px;
}

#__tealiumGDPRcpPrefs .__tealiumPrivacyBackdrop {
    opacity: 0.35 !important;
    height: 120%;
}

.ag-teal {
    &.btn-simple {
        height: auto;
    }
}
