@use "../abstracts" as *;

.dialogs {
  position: absolute;
  z-index: 150;
}

.dialog {
  --header-sticky: 0;
  --dialog-speed: 0.2s;
  --dialog-offset: 20px;
  --dialog-scale: 0.9;
  --dialog-spacing: 3.2rem;
  --dialog-roundness: 10px;

  @include sm {
    --dialog-spacing: 2.8rem;
  }

  @include xs {
    --dialog-spacing: 2rem;
  }

  @include xxs {
    --dialog-spacing: 1.5rem;
  }

  &.--smaller-spacing {
    --dialog-spacing: 1.7125rem;
    --global-inter-content-spacing: 1.125rem;

    .dialog__header {
      margin-top: 8px;
      margin-bottom: calc(.75 * var(--global-inter-content-spacing));
      padding-bottom: 0;
    }

    @include xs {
      --dialog-spacing: 1.3125rem;
      --global-inter-content-spacing: 1.125rem;
    }

    @include xxs {
      --dialog-spacing: 1.3rem;
      --global-inter-content-spacing: 1rem;
    }
  }

  &.--small-spacing {
    --dialog-spacing: 2.5rem;

    @include xs {
      --dialog-spacing: 2rem;
    }

    @include xxs {
      --dialog-spacing: 1.5rem;
    }
  }

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // opacity: 0;
  position: fixed;
  display: grid;
  padding: 0;
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  align-items: stretch;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  @include trns(var(--dialog-speed), 2);

  &::backdrop {
    display: none;
  }

  &.--is-hidden {
    display: none;
  }

  &.--no-entries {

    .dialog__header,
    .tabs__control {
      display: none;
    }
  }

  &[open] {
    // opacity: 1;
    pointer-events: all;
  }

  &.--hide-content {
    pointer-events: none;
  }

  &__backdrop {
    opacity: 0;
    @include setInset;
    background: var(--ui-backdrop);
    @include trns(var(--dialog-speed), 2);

    .dialog.--has-blur & {
      backdrop-filter: blur(7px);
    }

    .dialog[open] & {
      opacity: 1;
    }

    .dialog.--hide-backdrop & {
      opacity: 0;
    }
  }

  &__wrapper {
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    display: grid;
    height: var(--client-height, 100vh);
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;
    scroll-padding-top: 10rem;
    scroll-padding-bottom: 10rem;

    &.dialog.--has-blur {
      opacity: 0;
      @include trns(var(--dialog-speed), 2);
    }

    .dialog.--has-blur[open] & {
      opacity: 1;
    }

    .dialog.--top & {
      align-items: start;
    }

    .dialog.--bottom & {
      align-items: end;
    }

    .dialog.--full-height & {
      align-items: stretch;
    }

    .dialog.--right & {
      justify-content: flex-end;
    }

    .dialog.--left & {
      justify-content: flex-start;
    }

    .dialog:not(.--full-height) & {
      .dialog__inner {
        margin: var(--spacing) 0;
      }
    }

    .dialog.--sm-left & {
      @include sm {
        justify-content: flex-start;
      }
    }

    .dialog.--sm-right & {
      @include sm {
        justify-content: flex-end;
      }
    }

    .dialog[open] & {
      overflow-y: auto;
    }

    .dialogs.--with-scrollbar.--has-active .dialog[open] & {
      overflow-y: scroll;
    }

    .dialogs.--with-scrollbar.--has-active .dialog.--hide-backdrop & {
      overflow-y: scroll;
    }
  }

  &__header {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px var(--ui-divider-color) solid;

    &.--no-divider {
      margin-bottom: 0rem !important;
      padding-bottom: 0.2rem;
      border-bottom: none;
    }

    &.--divider-xs {
      margin-bottom: 0rem !important;
      padding-bottom: 0rem;
      border-bottom: none;

      @include xs {
        padding-bottom: 1rem;
        border-bottom: 1px var(--ui-divider-color) solid;
      }

      .dialog.--small-spacing & {
        @include xxs {
          padding-top: 1rem;
        }
      }
    }

    &.--forward {
      z-index: 11;
      position: relative;
    }

    &.--no-border {
      border-bottom: none;
    }
  }

  &__content {
    width: var(--dialog-width, auto);
    max-width: calc(var(--client-width, 100vw) - var(--offset-gap) * 2);
    position: relative;
    padding: var(--dialog-spacing);
    padding-top: calc(var(--dialog-spacing) + 22px);
    background: var(--color-white);
    opacity: 0;
    transform: scale(var(--dialog-scale)) translateY(var(--dialog-offset));
    outline-offset: -1px;
    outline-color: var(--color-base);
    transition: opacity var(--dialog-speed) var(--transition-delay, 0s) var(--reveal-easing), transform var(--dialog-speed) var(--transition-delay, 0s) var(--reveal-easing);

    .dialog:not(#send-preview, .--product-quickview) & {
      will-change: transform, opacity;
    }

    @include max(360) {
      max-width: calc(var(--client-width, 100vw) - var(--offset-gap) * 1.25);
    }

    &.--inner-spacing {
      padding: 0 !important;

      .dialog__content-inner {
        padding: var(--dialog-spacing);
      }
    }

    &.--small {
      --dialog-width: 600px;

      @include xs {
        --dialog-width: 400px;
      }
    }

    .dialog.--right &,
    .dialog.--left & {
      width: 440px;
    }

    .dialog:not(.--full-height) & {
      border-radius: var(--dialog-roundness);
      padding-top: var(--dialog-spacing);
    }

    .dialog.--full-height & {
      box-shadow: 0 20rem 0 var(--color-white), 0 -20rem 0 0 var(--color-white);
    }

    .dialog:not(.--full-height, .--smaller-spacing, .--small-spacing) & {
      @include xs {
        padding-top: calc(var(--dialog-spacing) * 1.35);
        padding-bottom: calc(var(--dialog-spacing) * 1.35);
      }
    }

    .dialog.--xxs-top-spacing & {
      @include xxs {
        padding-top: calc(var(--dialog-spacing) * 1.8);
      }
    }

    .dialog[open]:not(.--hide-content) & {
      transform: scale(1) translateY(0px);
    }

    .dialog.--left & {
      transform: scale(var(--dialog-scale)) translateX(calc(var(--dialog-offset) * -1));
    }

    .dialog.--left[open]:not(.--hide-content) & {
      transform: scale(1) translateX(0px);
    }

    .dialog.--right & {
      transform: scale(var(--dialog-scale)) translateX(calc(var(--dialog-offset) * 1));
    }

    .dialog.--right[open]:not(.--hide-content) & {
      transform: scale(1) translateX(0px);
    }

    .dialog.--left.--full-height & {
      transform: translateX(calc(var(--dialog-offset) * -2));
    }

    .dialog.--sm-right.--full-height & {
      @include sm {
        transform: translateX(calc(var(--dialog-offset) * 2));
      }
    }

    .dialog.--left.--full-height[open]:not(.--hide-content) & {
      transform: translateX(0px);
    }

    .dialog.--right.--full-height & {
      transform: translateX(calc(var(--dialog-offset) * 2));
    }

    .dialog.--sm-left.--full-height & {
      @include sm {
        transform: translateX(calc(var(--dialog-offset) * -2));
      }
    }

    .dialog.--right.--full-height[open]:not(.--hide-content) & {
      transform: translateX(0px);
    }

    .dialog.--top & {
      transform: scale(var(--dialog-scale)) translateY(calc(var(--dialog-offset) * -1));
    }

    .dialog.--top[open]:not(.--hide-content) & {
      transform: scale(1) translateY(0px);
    }

    &>* {
      &:not(:last-child, :nth-last-child(2)) {
        margin-bottom: var(--ui-vertical-spacing, 1rem);
      }
    }

    .dialog[open] & {
      opacity: 1;
    }

    .dialog.--hide-content & {
      opacity: 0;
      pointer-events: none;
    }

    &.--width-medium {
      width: 600px;
    }

    &.--width-small {
      width: 500px;
    }

    &.--width-xsmall {
       width: 390px;
    }

    .dialog[data-sequential].--is-open & {
      transform: none !important;
    }
  }

  &__overlay-btn {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 12;

    @include xxs {
      top: 0.5em;
      right: 0.5em;
    }

    &.--overlay {
      top: -.6em;
      right: -.6em;
      background-color: var(--color-white);
      box-shadow: var(--ui-box-shadow);
    }

    .css-icon {
      --size: 36px;
      --scale: 0.74;
    }
  }

  &.--sidelined {
    z-index: var(--dialog-index, -1);
  }

  .content-stack-blocks {
    section {
      display: none !important;
      visibility: hidden !important;
      &.--show-in-dialog {
        display: block !important;
        visibility: visible !important;
      }
    }
  }
}

.dialog-trigger {
  * {
    pointer-events: none;
  }
}

body.--has-scrollbar.--scroll-freeze {
  .sfc-app-modal__container {
    overflow-y: scroll;
  }
}