@use "./../abstracts/mixins/breakpoints.scss" as *;

.container {
  --container-width-form: 750px;
  width: 100%;
  max-width: var(--container-gaps);
  padding-left: var(--offset-gap);
  padding-right: var(--offset-gap);
  margin: 0 auto;
  position: relative;
  .container:not(.--persistent) {
    padding: 0;
    max-width: 100%;
  }
  &:not(:first-child) {
    margin-top: calc(var(--spacing) * 0.75);
    &.content-wrapper{
      margin-top: 0;
    }
    .--no-interpad & {
      margin-top: calc(var(--spacing) * 0);
    }
    .--more-spacing {
      margin-top: calc(var(--spacing));
    }
  }

  &.--relative {
    position: relative;
  }

  .--no-size {
    margin-bottom: 0px !important;
    height: 0px !important;
  }

  main > & {
    margin-bottom: 0px !important;
  }

  &.--narrow {
    @include to_md {
      max-width: calc(var(--container-gaps) * 0.9);
    }
  }

  &.--narrower {
    @include to_sm {
      max-width: 940px;
    }
  }

  &.--narrowest {
    @include to_sm {
      max-width: 800px;
    }
  }

  &.--half {
    @include to_md {
      max-width: calc(var(--container-gaps) * 0.5);
    }
  }

  &.--sm-full {
    @include sm {
      max-width: 100%;
      width: 100%;
    }
  }

  @include xs {
    width: 100%;
  }
  &.--contain-width-small {
    @include to_xs {
      max-width: calc(600px + var(--offset-gap) * 2);
    }
  }
  &.--contain-width-form {
    @include to_sm {
      max-width: var(--container-width-form);
    }
  }
}
