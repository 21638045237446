@use '../../abstracts/' as *;

.product-thumb {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  align-items: center;
  @include xs {
    margin-top: 1.725rem;
    justify-content: start;
  }
  @include xxs {
    margin-top: 1.625rem;
  }
  @include to_xs{
    max-width: 340px;
    margin-left: 4rem;
    &__image {
      order: 2;
    }
    &__content {
      order: 1;
      text-align: right;
      text-wrap: balance;
    }
  }
  @include sm {
    margin-left: 2rem;
  }
  @include xs {
    margin-left: 0rem;
  }

  &__image{
    width: 118px;
    img {
      background-color: rgba(0,0,0,.048);
    }
    @include xs {
      width: 78px;
      align-self: start;
    }
    &.--creatacard {
      width: auto;
      --product-mask: none;
      --no-envelope-width: 118px;
      --no-envelope-width-xs: 78px;
      &.--bifoldportrait {
        --product-mask: url("#cac-envelope-bifoldportrait-mask");
        --no-envelope-width: 65px;
        --no-envelope-width-xs: 50px;
      }
      &.--doublesidedlandscape {
        --product-mask: url("#cac-envelope-doublesidedlandscape-mask");
      }
      &.--doublesidedportrait {
        --product-mask: url("#cac-envelope-doublesidedportrait-mask");
        --no-envelope-width: 68px;
        --no-envelope-width-xs: 52px;
      }
      .product-thumb__base,
      .product-thumb__personalized:not(.--no-envelope) {
        width: 118px;
        @include xs {
          width: 78px;
        }
      }
      .product-thumb__personalized.--no-envelope {
        width: var(--no-envelope-width, 65px);
        box-shadow: 0 4px 17px rgba(51, 51, 51, 0.1);
        border-radius: var(--ui-roundness);
        @include xs {
          width: var(--no-envelope-width-xs, 50px);
        }
      }
    }
  }
  &__title {
    font-size: 0.875rem;
    text-wrap: balance;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__content{
    font-weight: 500;
    font-size: .945rem;
    letter-spacing: .3px;
  }
  &__action{
    margin-bottom: 3px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.5875rem;
    color: var(--color-primary-lighter);
  }
  &__personalized:not(.--no-envelope) {
    filter: drop-shadow(2px 4px 8px #0002);
    .lazy-load {
      clip-path: var(--product-mask, none);
    }
  }

  &.--simple-thumb {
    max-width: 100%;
    grid-template-columns: 1fr 1.7fr;
    margin-left: 0rem;
    .product-thumb {
      &__image {
        width: auto;
        order: 1;
      }
      &__content {
        order: 2;
        text-align: left;
        text-wrap: balance;
      }
    }
  }

  &__join-wrapper {
    .product-thumb {
      margin-top: 1rem;
      max-width: 80%;
      @include xxs {
        max-width: 100%;
      }
    }
    .solid-divider-line {
      margin: 2rem 0 1rem;
    }
  }
}