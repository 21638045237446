@use "./../abstracts" as *;

.image-text-tile-block {
    .layout {
        .image-tile {
            &__list {
                gap: 3rem;

                @include xs {
                    text-align: center;
                }

                @include sm {
                    gap: 2rem;
                }

                &.even {
                    &.--columns-4 {
                        @include sm {
                            --columns: 4;
                        }

                        @include xs {
                            --columns: 2;
                        }
                    }

                    &.--columns-3 {
                        @include sm {
                            --columns: 3;
                        }

                        @include xs {
                            --columns: 2;
                        }
                    }
                }

                &.--content-width {
                    max-width: 900px;
                    margin: auto;

                    .--switch {
                        .image-tile__image {
                            @include sm {
                                order: 1;
                            }
                        }
                    }

                    .image-tile__image {
                        max-width: 280px;
                        margin: auto;
                    }

                    .image-tile__figure {
                        grid-template-columns: auto auto;

                        @include xs {
                            grid-template-columns: 1fr;
                        }
                    }
                }

                &.--mobile-double {
                    grid-template-columns: repeat(var(--columns, 1), 1fr);
                }
            }

            &__link {
                text-decoration: none;
                outline-offset: 3px;
                display: block;

                &:hover,
                &:focus {
                    text-decoration: underline;

                    .image-tile__image.--rollover {
                        opacity: 0;
                        @include trns;
                    }

                    .image-tile__rollover {
                        opacity: 1;
                        @include trns;
                    }
                }
            }

            &__figure {
                .--image-large {
                    position: relative;
                    max-width: 215px;
                    margin: auto;
                }

                .--image-medium-large {
                    position: relative;
                    max-width: 160px;
                    margin: auto;
                }

                .--image-medium {
                    position: relative;
                    max-width: 130px;
                    margin: auto;
                }

                .--image-small {
                    position: relative;
                    max-width: 80px;
                    margin: auto;
                }

                .--image-full {
                    position: relative;
                    margin: auto;
                }

                &.--switch {
                    .image-tile__image {
                        order: 2;

                        @include sm {
                            order: 1;
                        }
                    }
                }

                &.--image-small {
                    grid-template-columns: 90px auto;
                    gap: 1rem;

                    @include sm {
                        grid-template-columns: 70px auto;
                    }

                    .image-tile__caption {
                        margin: 0;
                    }
                }
            }

            &__sub-text {
                margin-top: calc(var(--vertical-spacing) * 1 * var(--scale));
            }

            &__image {
                position: relative;
                opacity: 1;
                @include trns;
            }

            &__rollover {
                position: absolute;
                top: 0;
                opacity: 0;
                @include trns;
            }

            &__caption {
                margin-top: calc(var(--vertical-spacing) * 1 * var(--scale));

                &.--align-left {
                    text-align: left;
                }

                &.--align-center {
                    text-align: center;
                }
            }

            &__title {
                font-weight: 500;
                letter-spacing: 0px;
                font-size: 1rem;
                padding-bottom: 1px;

                &.--align-center {
                    text-align: center;
                }
            }

            &__text {
                margin-top: .75rem;

                &.--image-small,
                &.--image-medium,
                &.--image-medium-large,
                &.--image-large {
                    margin: auto;
                    max-width: 100%;
                }

            }
        }

        &.flex-center {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .layout__item {
                width: 28%;

                @include sm {
                    width: 46%;
                }

                @include xxs {
                    width: 100%;
                }
            }
        }

        .number-format {
            color: var(--color-white);
            background: var(--color-gray-1);
            border-radius: 50px;
            width: 25px;
            height: 25px;
            display: inline-block;
            font-size: .875rem;
            margin-right: 5px;
            line-height: 1.8;
        }
    }
}