@use "./../abstracts" as *;

.gallery {
    --s-ratio-bot-override: 0.5;
    &__content {
        --gallery-gap: calc(var(--gap) * 1.5);
        --gallery-columns: 1;
        &.--gap-larger{
            --gallery-gap: calc(var(--gap) * 2.5);
        }
        &.--no-gap{
            --gallery-gap: 0;
        }
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        gap: var(--gallery-gap);
        &.--columns-2{
            --gallery-columns: 2;
        }
        &.--columns-3{
            --gallery-columns: 3;
        }
        &.--columns-4{
            --gallery-columns: 4;
        }
        &.--columns-5{
            --gallery-columns: 5;
        }
        &.--columns-6{
            --gallery-columns: 6;
        }
    }
    &__image-item{
        min-width: calc(100% / var(--gallery-columns, 1) - 20px);
        display: grid;
        flex: 1;
        @include sm{
            min-width: 100%;
        }
    }
}