@use "../abstracts/" as *;

.columns {
  display: flex;
  --column-gap: calc(var(--gap) * 4);
  gap: calc(var(--column-gap) * var(--column-gap-scale, 1));
  justify-content: center;
  &.--center {
    align-items: center;
  }

  &.--medium {
    --column-gap-scale: 1.5;
  }
  &.--large {
    --column-gap-scale: 2;
  }

  @include sm {
    --column-gap: calc(var(--gap) * 2);
  }
  @include xs {
    flex-direction: column;
    &.--center {
      align-items: unset;
    }
  }
}

.column {
  flex: 1;
  &.--grow-2 {
    flex: 2;
  }
  &.--grow-3 {
    flex: 3;
  }
  &.--shrink-2 {
    flex: calc(1 / 2);
  }
  &.--shrink-3 {
    flex: calc(1 / 3);
  }
}