@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes reRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    opacity: var(--start-t, 0.5);
  }

  100% {
    width: 450px;
    height: 450px;
    opacity: var(--end-t, 0);
  }
}

// Fades
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: var(--animation-out-opacity, 1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInTop {
  0% {
    transform: translateY(calc(1 * var(--translate-value, 20px)));
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  0% {
    transform: translateY(calc(-1 * var(--translate-value, 20px)));
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(calc(1 * var(--translate-value, 20px)));
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(calc(-1 * var(--translate-value, 20px)));
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeOutTop {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(calc(-1 * var(--translate-value, 20px)));
    opacity: 0;
  }
}

@keyframes fadeOutBottom {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(calc(1 * var(--translate-value, 20px)));
    opacity: 0;
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: translateX(calc(-1 * var(--translate-value, 20px)));
  }
}

// Scales
@keyframes scaleIn {
  0% {
    transform: scale(var(--scale-value, .9));
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  0% {
    transform-origin: top right;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform-origin: top right;
    transform: scale(0.9);
    opacity: 0;
  }
}

@keyframes scaleInRight {
  0% {
    transform: scale(var(--scale-value, .9)) translateX(calc(1 * var(--translate-value, 20px)));
    opacity: 0;
    transform-origin: right;
  }

  100% {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }
}

@keyframes scaleInLeft {
  0% {
    transform: scale(var(--scale-value, .9)) translateX(calc(-1 * var(--translate-value, 20px)));
    opacity: 0;
    transform-origin: left;
  }

  100% {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }
}

@keyframes scaleInBottom {
  0% {
    transform: scale(var(--scale-value, .9)) translateY(calc(1 * var(--translate-value, 20px)));
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes shine-loading-image {
  0% {
    background-position: 50%, 35%;
  }
  60% {
    background-position: 50%, -50%;
  }
  100% {
    background-position: 50%, -50%;
  }
}

@keyframes shine-loading {
  0% {
    background-position: 35%;
  }
  60% {
    background-position: -50%;
  }
  100% {
    background-position: -50%;
  }
}
