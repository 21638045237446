@use "./../abstracts" as *;

// Hide any external elements that were injected as direct children to body
// Update this class list anytime you want to include something that is
// spawned, otherwise the page must display only elements that we can be aware of.
body.product-page:not(.--product-model-creatacard, .--product-model-picsnwishes, .--product-has-photo-modal) {
  & > *:not(
  .page-wrapper,
  .dialogs,
  .resize-proxy,
  .tox,
  .client-logger,
  .grecaptcha-page-container,
  [data-bv-show=inpage_submission],
  .modal-scrollbar-measure,
  #party-js-container,
  .modal-backdrop,
  .dy-onsite-iframe-container,
  .dy-preview-iframe-active,
  .dy-module,
  #adm-bubble,
  .aria-speaker,
  #__tealiumGDPRecModal,
  .dynotifyjs-corner,
  .sfc-app-self-container,
  .clip-svg) {
    display: none !important;
  }
}

body.--static-nav {
  --header-sticky: 0;
}

.grecaptcha-page-container {
  z-index: 151;
  position: relative;
  opacity: 0;
  pointer-events: none;
  @include setTransition(.2s, 1, opacity);
  visibility: hidden;
  body.--scroll-freeze & {
    position: fixed;
    right: var(--scrollbar-gap, 0px);
    transform: translate(0px);
    bottom: 0px;
    overflow: hidden;
    width: 300px;
    max-width: 100%;
    height: 100px;
  }
  &.--activating {
    visibility: visible;
  }
  &.--active {
    opacity: 1;
    visibility: visible;
    .grecaptcha-badge {
      pointer-events: all;
    }
  }
  &.--deactivating.--active {
    opacity: 0;
  }
  .grecaptcha-badge{
    box-shadow: var(--ui-box-shadow) !important;
  }
  body.tox-dialog__disable-scroll & {
    opacity: 0;
  }
}


.--block-transitions {
  --easing: none;
  --reveal-easing: none;
  --lazy-easing: none;
}

.--block-transitions-inside > * {
  --easing: none;
  --reveal-easing: none;
  --lazy-easing: none;
}

.--stagger-transitions {
  --transition-delay: .2s;
}

.--stagger-transitions-inside > * {
  --transition-delay: .2s;
}

:focus-visible, .js-focus-visible {
  outline-style: auto;
  outline-offset: 0px;
  outline-color: var(--color-base);
}

//Safari and Mozilla browsers
@supports ((font: -apple-system-body) and (-webkit-appearance: none)) or (-moz-appearance:none) {
  :focus-visible {
    border-radius: 2px;
    outline: 2px solid var(--color-outline);
    &:not(.app-content__link) {
      transform: translateZ(0);
    }
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

#error-buffer-capture-output,
#output-buffer-capture-output {
  position: fixed;
  z-index: 10000;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 7.625rem;
  scroll-padding-bottom: 1rem;
  -webkit-text-size-adjust: 100%;
  @include sm {
    scroll-padding-top: 6rem;
    scroll-padding-bottom: 2rem;
  }
}

body {
  &.favorites-page {
    .product__actions .add-to-favorites {
      display: none;
    }
  }

}

a,
button,
input,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  padding-right: 0;
  padding-left: 0;
}

a,
button {
  cursor: pointer;
  text-underline-offset: 3px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

button *, a *{
  pointer-events: none;
}

img {
  border: none;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

#maze-contextual-widget-host {
  z-index: 100 !important;
}


#maze-contextual-widget-host:not(.--visible) {
  display:none!important;
}