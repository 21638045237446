@use './../abstracts/' as *;

.css-icon {
  --scale: 1;
  --size: 20px;
  --weight: 2px;
  --icon-size: calc(var(--size) * var(--scale));
  --left-offset: 2px;
  margin-top: var(--icon-offset, 0px);
  position: relative;
  display: inline-flex;
  min-width: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  color: currentColor;
  transform: scale(var(--scale, 1)) rotate(var(--rotate, 0deg)) translateX(var(--translate-x, 0px)) translateY(var(--translate-y, 0px));
  @include trns;

  &:before,
  &:after {
    @include trns;
  }

  a:active &,
  button:active & {
    @include trns(0);

    &:before,
    &:after {
      @include trns(0);
    }
  }

  &.--cross {
    display: flex;
    align-items: center;
    justify-content: center;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 10%;
      width: 80%;
      height: var(--weight);
      top: calc(50% - var(--weight) / 2);
      background: currentColor;
      @include setTransition(.2s, 1, all);
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  &.--plus {
    display: flex;
    align-items: center;
    justify-content: center;
    --size: 14px;
    .btn &{
      margin-left: -3px;
      --size: 10px;
    }
    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0%;
      width: 100%;
      height: var(--weight);
      top: calc(50% - var(--weight) / 2);
      background: currentColor;
    }

    &:after {
      transform: rotate(0deg);
    }

    &:before {
      transform: rotate(90deg);
    }
  }

  &.--minus {
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      left: 15%;
      width: 70%;
      height: var(--weight);
      top: calc(50% - var(--weight) / 2);
      background: currentColor;
    }
  }

  &.--loader {
    .add-to-favorites & {
      --size: 18px;
    }
    .--stop-animation & {
      display: none;
    }
    &::before,
    .glow:before {
      animation: rotate .65s linear 3;
      .--loading &,
      .--loading-complete & {
        animation: reRotate .65s linear infinite;
      }
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 50%;
      background: conic-gradient(from 0turn at 50% 50%, #fff0 40%, currentColor);
      padding: var(--weight);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: source-out;
      mask-composite: exclude;
      [aria-hidden="true"]:not(.page-wrapper) & {
        display: none;
      }
    }

    .glow {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: .5;
      mask-image: radial-gradient(circle,#0000, #000 80%);
      &:before {
        background: conic-gradient(from 0turn at 50% 50%, #fff0 0%, currentColor);
        mask: none;
      }
      .btn.--secondary &, .field__button &, .add-to-favorites &{
        display: none;
      }
    }
  }

  &.--arrow {
    --scale: 0.7;
    --translate-x: calc(5px - 8px + 5px * var(--active, 0));

    &.--back {
      --rotate: 180deg;
    }

    &:before {
      content: '';
      position: absolute;
      right: var(--icon-right, 1px);
      top: calc(50% - 6px);
      width: 12px;
      height: 12px;
      left: var(--left-offset);
      border-top: var(--weight) solid currentColor;
      border-right: var(--weight) solid currentColor;
      transform: rotate(45deg);
      @include setTransition(.2s, 1, all);
    }
  }

  &.--arrow-tail {
    --tail-scale: 1;
    --arrow-scale: .45;
    --scale: 1;
    --translate-x: -1px;
    @include trns;

    &.--back {
      --rotate: 180deg;
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - var(--weight) / 1.5);
      right: calc(1px + 50% - 100% * var(--tail-scale, 1) / 2);
      height: var(--weight);
      // opacity: 0;
      width: calc(100% * var(--tail-scale, 1) - 1px);
      background-color: currentColor;
    }

    &:before {
      content: '';
      position: absolute;
      top: calc((50% - 100% * var(--arrow-scale) / 2) - var(--weight) / 2);
      width: calc(100% * var(--arrow-scale));
      height: calc(100% * var(--arrow-scale));
      transform-origin: calc(50% - var(--weight) / 2) calc(50% - var(--weight) / 2);
      right: calc(50% - 100% * var(--tail-scale) / 2);
      border-top: var(--weight) solid currentColor;
      border-right: var(--weight) solid currentColor;
      transform: rotate(45deg);
    }
  }

  &.--history {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99166 1.66663C5.39166 1.66663 1.66666 5.39996 1.66666 9.99996C1.66666 14.6 5.39166 18.3333 9.99166 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99996C18.3333 5.39996 14.6 1.66663 9.99166 1.66663ZM10 16.6666C6.31666 16.6666 3.33333 13.6833 3.33333 9.99996C3.33333 6.31663 6.31666 3.33329 10 3.33329C13.6833 3.33329 16.6667 6.31663 16.6667 9.99996C16.6667 13.6833 13.6833 16.6666 10 16.6666Z' fill='%23405864'/%3E%3Cpath d='M10.4167 5.83337H9.16667V10.8334L13.5417 13.4584L14.1667 12.4334L10.4167 10.2084V5.83337Z' fill='%23405864'/%3E%3C/svg%3E%0A");
  }

  &.--play-pause {
    --fragment-offset: 1%;
    --fragment-1-path: polygon(calc(27% + var(--fragment-offset, 0%)) 84%, calc(27% + var(--fragment-offset, 0%)) 16%, calc(56% + var(--fragment-offset, 0%)) 33%, calc(56% + var(--fragment-offset, 0%)) 67%);
    --fragment-2-path: polygon(calc(51% + var(--fragment-offset, 0%)) 70%, calc(51% + var(--fragment-offset, 0%)) 30%, calc(85% + var(--fragment-offset, 0%)) 50%, calc(85% + var(--fragment-offset, 0%)) 50%);

    &.--playing {
      --fragment-1-path: polygon(25% 80%, 25% 20%, 43% 20%, 43% 80%);
      --fragment-2-path: polygon(57% 80%, 57% 20%, 75% 20%, 75% 80%);
    }

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: currentColor;
      clip-path: var(--fragment-1-path);
    }

    &::after {
      clip-path: var(--fragment-2-path);
    }
  }
  &.--gray-2 {
    color: var(--color-gray-2);
  }
}