@use "../../abstracts/" as *;

.badge,
.badge-inline {
  --badge-bg: var(--color-cardinal);
  --badge-icon-active: 1;
  --badge-size: 22px;

  @include sm {
    --badge-size: 18px;
  }

  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--ui-roundness, 0px);
  overflow: hidden;

  &.--free {
    --badge-bg: var(--color-free);
    --badge-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8.088 2.07a2.25 2.25 0 0 0-.862-.172H3.67a1.875 1.875 0 0 0-1.875 1.875V7.33a2.25 2.25 0 0 0 .658 1.591l4.865 4.864a2.25 2.25 0 0 0 3.181 0l3.183-3.182a2.25 2.25 0 0 0 0-3.182L8.818 2.557a2.25 2.25 0 0 0-.73-.488ZM5.495 7.18a1.575 1.575 0 1 0 0-3.15 1.575 1.575 0 0 0 0 3.15Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    --badge-icon-active: 0 !important;
    --active: 1 !important;
    --inactive: 0 !important;
  }

  &__icon {
    .--free & {
      width: 0px;
      max-width: 0px;
      margin-right: 0px;
      opacity: 0;
    }
  }

  &.--new {
    --badge-bg: var(--color-new);
    --badge-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='m14.947 7.56-1.24-1.413.173-1.874a.67.67 0 0 0-.513-.713l-1.84-.42-.96-1.62a.664.664 0 0 0-.834-.273L8 2l-1.727-.74a.662.662 0 0 0-.833.273l-.967 1.614-1.833.413a.664.664 0 0 0-.52.713l.173 1.867-1.24 1.42a.67.67 0 0 0 0 .88l1.24 1.413-.173 1.88a.67.67 0 0 0 .513.714l1.84.42.96 1.613a.67.67 0 0 0 .84.273L8 14l1.727.74a.662.662 0 0 0 .833-.273l.96-1.62 1.84-.42a.663.663 0 0 0 .513-.714L13.7 9.84l1.24-1.413a.644.644 0 0 0 .007-.867Zm-6.28 3.773H7.333V10h1.334v1.333ZM8 8.667A.669.669 0 0 1 7.333 8V5.333c0-.366.3-.666.667-.666.367 0 .667.3.667.666V8c0 .367-.3.667-.667.667Z'/%3E%3C/svg%3E");
  }

  &.--music {
    --badge-bg: var(--color-music);
    --badge-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M14 2v8.333a2.333 2.333 0 1 1-1.333-2.106V4.313L6 5.733v5.934A2.333 2.333 0 1 1 4.667 9.56V4L14 2Z'/%3E%3C/svg%3E");
  }

  &.--animated {
    --badge-bg: var(--color-animated);
    --badge-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M2.667 1.333h6.666v1.334H2.667v6.666H1.333V2.667c0-.74.594-1.334 1.334-1.334ZM5.333 4h8c.74 0 1.334.593 1.334 1.333v8a1.33 1.33 0 0 1-1.334 1.334h-8c-.74 0-1.333-.594-1.333-1.334v-8C4 4.593 4.593 4 5.333 4ZM8 6.667V12l4-2.667-4-2.666Z'/%3E%3C/svg%3E");
  }

  &.--foil {
    --badge-bg: var(--color-foil);
    --badge-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M13.188 1.21c-.658-.232-1.356-.272-1.97-.12-.612.153-1.2.516-1.51 1.134l-.13.266H2.937a1.78 1.78 0 0 0-1.78 1.78v9.013a1.71 1.71 0 0 0 1.706 1.71c2.442.007 6.157.012 6.785-.002.409-.01.762-.1 1.05-.217.701-.288 1.003-.95 1.148-1.4.292-.908.656-2.034 1.021-3.161.247-.727.495-1.463.728-2.155.324-.963.618-1.845.835-2.507a67.3 67.3 0 0 0 .341-1.076c.015-.052.04-.14.052-.214.053-.336.012-.926-.184-1.487-.2-.57-.62-1.27-1.45-1.563ZM8.885 3.958c-.69 1.466-1.66 3.544-2.394 5.117-.438.94.056 1.978.896 2.382.934.449 2.01 1.141 2.669 1.993a1.54 1.54 0 0 1-.443.076c-.603.014-4.288.008-6.747.002a.244.244 0 0 1-.243-.244V4.27c0-.173.14-.313.313-.313h5.95Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  }

  &.--glitter {
    --badge-bg: var(--color-glitter);
    --badge-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M5.397 10.81a1.039 1.039 0 0 0 1.204-.004c.178-.13.313-.31.387-.518l.447-1.373c.115-.344.308-.657.564-.914.257-.256.57-.45.913-.565l1.391-.451a1.048 1.048 0 0 0 .575-1.47 1.039 1.039 0 0 0-.619-.51l-1.375-.447a2.338 2.338 0 0 1-1.48-1.477l-.452-1.388a1.043 1.043 0 0 0-1.973.017l-.457 1.4a2.323 2.323 0 0 1-1.44 1.449l-1.39.448a1.061 1.061 0 0 0-.644.67 1.051 1.051 0 0 0 .66 1.304l1.375.445a2.33 2.33 0 0 1 1.48 1.488l.452 1.391c.072.204.206.38.382.504Zm6.137 4.041a.8.8 0 0 0 1.226-.398l.248-.762a1.067 1.067 0 0 1 .68-.68l.772-.252a.793.793 0 0 0 .53-.64.795.795 0 0 0-.553-.88l-.764-.25a1.075 1.075 0 0 1-.68-.678l-.252-.773a.784.784 0 0 0-.293-.39.796.796 0 0 0-1.03.085.801.801 0 0 0-.195.315l-.247.762a1.071 1.071 0 0 1-.665.68l-.773.251a.797.797 0 0 0-.39 1.23.8.8 0 0 0 .398.288l.763.247c.16.054.304.143.422.261.12.12.207.263.258.422l.253.774a.8.8 0 0 0 .292.388Z'/%3E%3C/svg%3E");
  }

  &.--custom-song {
    --badge-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='m6.487 7.579.688-.394c.228-.13.4-.319.512-.526a1.384 1.384 0 0 0 .148-.877l6.683-1.432v7.17a2.008 2.008 0 1 1-1.147-1.812V6.34L7.634 7.562v5.106a2.008 2.008 0 1 1-1.147-1.813V7.579Zm.148-1.814L5.436 5.08a2.012 2.012 0 0 1-.831-.95l-.6-1.37a.256.256 0 0 0-.085-.108.211.211 0 0 0-.123-.04.211.211 0 0 0-.122.04.256.256 0 0 0-.085.108l-.6 1.37c-.18.41-.471.744-.831.95l-1.198.685a.247.247 0 0 0-.094.098.294.294 0 0 0 0 .279.247.247 0 0 0 .094.098l1.198.685c.36.205.652.539.831.95l.6 1.37c.02.044.049.08.085.107a.211.211 0 0 0 .244 0 .256.256 0 0 0 .086-.107l.6-1.37c.18-.411.471-.745.831-.95l1.199-.686a.247.247 0 0 0 .093-.097.294.294 0 0 0 0-.28.247.247 0 0 0-.093-.097Zm-.15-3.24L5.97 2.23a.106.106 0 0 1-.04-.042.126.126 0 0 1 0-.12.106.106 0 0 1 .04-.041l.514-.294a.862.862 0 0 0 .356-.407L7.098.74a.11.11 0 0 1 .037-.046.09.09 0 0 1 .052-.017.09.09 0 0 1 .052.017.11.11 0 0 1 .037.046l.257.587c.077.176.202.32.356.407l.514.294c.016.01.03.024.04.042a.126.126 0 0 1 0 .12.106.106 0 0 1-.04.041l-.514.294a.862.862 0 0 0-.356.407l-.257.587a.11.11 0 0 1-.037.046.09.09 0 0 1-.052.017.09.09 0 0 1-.052-.017.11.11 0 0 1-.037-.046l-.257-.587a.861.861 0 0 0-.356-.407Z'/%3E%3C/svg%3E");
    --badge-icon-sm: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='m6.487 7.579.688-.394c.228-.13.4-.319.512-.526a1.384 1.384 0 0 0 .148-.877l6.683-1.432v7.17a2.008 2.008 0 1 1-1.147-1.812V6.34L7.634 7.562v5.106a2.008 2.008 0 1 1-1.147-1.813V7.579Zm.148-1.814L5.436 5.08a2.012 2.012 0 0 1-.831-.95l-.6-1.37a.256.256 0 0 0-.085-.108.211.211 0 0 0-.123-.04.211.211 0 0 0-.122.04.256.256 0 0 0-.085.108l-.6 1.37c-.18.41-.471.744-.831.95l-1.198.685a.247.247 0 0 0-.094.098.294.294 0 0 0 0 .279.247.247 0 0 0 .094.098l1.198.685c.36.205.652.539.831.95l.6 1.37c.02.044.049.08.085.107a.211.211 0 0 0 .244 0 .256.256 0 0 0 .086-.107l.6-1.37c.18-.411.471-.745.831-.95l1.199-.686a.247.247 0 0 0 .093-.097.294.294 0 0 0 0-.28.247.247 0 0 0-.093-.097Zm-.15-3.24L5.97 2.23a.106.106 0 0 1-.04-.042.126.126 0 0 1 0-.12.106.106 0 0 1 .04-.041l.514-.294a.862.862 0 0 0 .356-.407L7.098.74a.11.11 0 0 1 .037-.046.09.09 0 0 1 .052-.017.09.09 0 0 1 .052.017.11.11 0 0 1 .037.046l.257.587c.077.176.202.32.356.407l.514.294c.016.01.03.024.04.042a.126.126 0 0 1 0 .12.106.106 0 0 1-.04.041l-.514.294a.862.862 0 0 0-.356.407l-.257.587a.11.11 0 0 1-.037.046.09.09 0 0 1-.052.017.09.09 0 0 1-.052-.017.11.11 0 0 1-.037-.046l-.257-.587a.861.861 0 0 0-.356-.407Z'/%3E%3C/svg%3E");
    --badge-bg-gradient: linear-gradient(to right, #ca3fa0, #7336ff);
    --badge-bg: transparent;
  }



  &__wrapper {
    position: relative;
  }

  &__text {
    background: var(--badge-bg);
    text-transform: uppercase;
    color: var(--color-white);
    font-weight: 700;
    font-size: 0.5875rem;
    letter-spacing: 0.8px;
    padding: 1px 8px 0 calc(8px + 22px* var(--badge-icon-active));

    .product__badges & {
      padding-top: 2px;
    }

    @include sm {
      padding: 1px 8px 0 calc(8px + 18px* var(--badge-icon-active));
      font-size: .495rem;
    }

    height: var(--badge-size);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 8px;
    border-radius: var(--ui-roundness, 0px);
    opacity: var(--active);
    transform: translateX(calc(-18px * var(--inactive)));
    @include trns;
  }
}

.badge {

  .product.--focused & {
    --inactive: 0;
    --active: 1;
  }

  &__icon {
    background-color: var(--badge-bg);
    background-image: var(--badge-icon);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--ui-roundness, 0px);
    width: var(--badge-size);
    height: var(--badge-size);
    margin-left: 0;
    position: absolute;
    z-index: 1;

    @include sm {
      background-size: 14px;
    }

    &:before {
      content: "";
      width: 8px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -5px;
      z-index: -1;
      background-color: inherit;
      border-radius: var(--ui-roundness, 0px) 0 0 var(--ui-roundness, 0px);
    }

    @include trns;

    @include mouse {

      .product:hover &,
      .product:focus-within & {
        transform: translateX(5px);
      }
    }

  }

  &.--custom-song {
    .badge__text {
      --badge-bg: var(--badge-bg-gradient);
    }

    .badge__icon {
      background: var(--badge-icon), var(--badge-bg-gradient);
      background-position: center;
      background-repeat: no-repeat;

      @include sm {
        background: var(--badge-icon-sm), var(--badge-bg-gradient);
        background-position: center;
        background-repeat: no-repeat;
      }

      @include mouse {

        .product:hover &,
        .product:focus-within & {
          background: var(--badge-icon), transparent;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.badge-inline {
  --badge-size: 20px;
  background-color: var(--badge-bg);

  &.--custom-song {
    background: var(--badge-bg-gradient);
  }

  display: inline-flex;
  color: var(--color-white);
  align-self: baseline;
  vertical-align: baseline;
  transform: translateY(2px);

  @include sm {
    --badge-size: 18px;
  }

  &__wrapper {
    display: flex;
    padding: 0 8px 0 6px;

    .badge-inline.--free & {
      padding: 0 8px;
    }
  }

  &__text {
    padding: 2px 0px 0;

    @include sm {
      padding-top: 3px;
      font-size: 0.495rem;
    }

    @include xs {
      padding-top: 1px;
    }
  }

  &__icon {
    background-image: var(--badge-icon);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--ui-roundness, 0px);
    width: var(--badge-size);
    height: var(--badge-size);
    display: inline-flex;
    margin-right: 3px;

    @include xxs {
      background-size: 14px;
    }
  }
}

.status-badge {
  --badge-color: rgba(0, 83, 177, 1);
  --badge-bg: rgba(1, 120, 253, 0.12);

  &.--sent,
  &.--future {
    --badge-color: rgba(51, 51, 51, 1);
    --badge-bg: rgba(236, 236, 236, 1);
  }

  &.--bounced,
  &.--negative {
    --badge-color: rgba(173, 0, 41, 1);
    --badge-bg: rgba(213, 0, 50, 0.12);
  }

  &.--viewed,
  &.--positive {
    --badge-color: rgba(3, 115, 17, 1);
    --badge-bg: rgba(0, 169, 22, 0.12);
  }

  &.--vertical-spacing {
    --vertical-spacing: 12px;
    --scale: 1;
    margin: 10px 0;
    display: inline-block;
  }

  &.--neutral {
    --badge-color: rgba(50, 50, 50, 1);
    --badge-bg: rgba(100, 100, 100, 0.12);
  }

  font-weight: 500;
  color: var(--badge-color);
  background: var(--badge-bg);
  padding: 4px 12px 2px;
  line-height: 1.4;
  border-radius: var(--ui-roundness);
  font-size: 12px;
  letter-spacing: 0.3px;

  &:not(:last-child) {
    margin-bottom: calc(var(--vertical-spacing, 1rem) * .7 * var(--scale));
  }

  h1 &,
  h2 &,
  h3 & {
    transform: translateY(-1px);
    margin-bottom: 0 !important;
  }

  @include xs {
    padding-top: 2px;
  }
}