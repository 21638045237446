@use "./../abstracts" as *;

.slim-footer {
  display: grid;
  place-content: center;

  &__grid {
    display: grid;
    align-items: center;
    gap: 3rem;
    grid-template-columns: 1fr auto 1fr;

    &>* {
      padding: 2rem 0rem 2.3rem;
      display: grid;
      align-content: center;
    }
  }

  &__center {
    width: 1px;
    height: 20px;
    background: var(--ui-divider-color);
  }
}

.footer-newsletter {
  --field-background: white;
  --s-ratio-bot-override: 0.75;
  --s-ratio-top-override: 0.75;
  --color-outline: var(--color-gray-1);
  --ui-input-outline: #8C8C8C;
  color: var(--color-gray-1);
  background-color: var(--background-color-gray);
  position: static;

  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    letter-spacing: 0.7px;

    @include xs {
      text-align: left;
    }
  }

  p {
    text-align: center;

    @include xs {
      text-align: left;
    }
  }

  .required-text {
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
  }

  .terms-text {
    margin-top: 0.75rem;
  }

  .form__message {
    display: flex;
    justify-content: center;
  }

  button {
    width: 100%;

    @include xs {
      display: grid;
      justify-items: start;
    }
  }

  &__content {
    .form__wrapper .--newsletter-fields-layout {
      grid-template-columns: 1.1fr 1.1fr 2fr .8fr;

      @include xs {
        grid-template-columns: 1fr;
      }
    }

    .layout__item.--submit {
      @include to_xs {
        padding-top: 13px;
      }
    }
  }
}

.footer-wrapper {
  align-self: end;
}

.footer {
  --divider-color: #{rgba($color-gray-2, 0.6)};
  --color-outline: var(--color-gray-7);
  background-color: var(--background-color-dark-gray);
  color: var(--color-gray-7);
  align-self: end;

  &>.container {
    position: static;
  }

  &__main,
  &__site-links {
    li {
      display: block;
      margin: 0px;

      a {
        display: block;
        letter-spacing: 0.4px;
        font-size: .875rem;
        padding: 5px 10px;
        text-decoration: none;
        outline-offset: 2px;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  &__title {
    display: block;
    color: var(--color-gray-4);
    font-weight: 400;
    font-size: .875rem;
    letter-spacing: 0.7px;
    margin-bottom: .8rem;

    @include sm {
      font-weight: 500;
      font-size: 0.875rem;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    @include xxs {
      font-size: 12px;

    }
  }

  &__main {
    border-bottom: 1px solid var(--divider-color);
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr 1fr 1fr;
    gap: 2.4rem;
    padding: 2rem 0;

    @include sm {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      padding: 1rem 0 3rem;

      &>div:first-child {
        grid-column: span 4;
      }
    }

    @include xs {
      border: none;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      &>div:first-child {
        grid-column: span 2;
      }
    }

    @include xxs {
      padding: 1rem 0 3rem;
      gap: 2rem 1rem;
    }

    .app-icons {
      --hover-color: #{rgba($color-gray-7, 12.5%)};
      --active-color: var(--color-white);
      --active-weight: 1px;
      display: flex;
      height: auto;
      padding-top: 10px;
      align-items: flex-start;
      justify-content: center;

      @include to_sm {
        flex-flow: column;
      }
    }

    ul {
      margin-left: -10px;
    }
  }

  &__wrapper {
    border-bottom: 1px solid var(--divider-color);
    width: 100%;
    display: grid;
    justify-content: center;
  }

  &__social {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin: 0 auto;
    padding: 20px 30px;
    width: 100%;
    gap: 3px;

    a {
      width: 40px;
      height: 40px;
      display: grid;
      border-radius: 50%;
      grid-template-columns: 1fr;

      &:after {
        pointer-events: none;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        opacity: 0;
        transform: scale(0.3);
        @include trns(0.1s);
        grid-row-start: 1;
        grid-column-start: 1;
      }

      img {
        grid-row-start: 1;
        grid-column-start: 1;
        width: 26px;
        align-self: center;
        justify-self: center;
      }

      &:hover,
      &:focus {
        &::after {
          opacity: 0.1;
          transform: scale(1);
        }
      }

      &:focus-visible {
        &::after {
          @include trns(0);
          transform: scale(1);
        }
      }

      &:active {
        &::after {
          @include trns(0);
          opacity: 0.2;
        }
      }
    }

    @include xs {
      max-width: 300px;
      gap: 0.3rem;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &__header {
    display: block;
    font-size: 1.125rem;
    letter-spacing: 0.4px;
    margin-right: .5rem;
  }

  &__logo {
    text-align: center;

    a {
      display: flex;
      justify-content: center;

      svg {
        width: auto;
        color: var(--color-primary-lighter);
      }
    }
  }

  &__site-links {
    ul {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 1.2rem 0;

      @include sm {
        justify-content: center;
      }

      @include xs {
        align-items: center;
        flex-direction: column;
        text-align: center;
      }
    }

    li a {
      font-weight: 400;
      letter-spacing: 0.4px;
      font-size: 0.875rem;

      @include xs {
        font-size: .725rem;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }

  &__bottom-link {
    margin: 0 -10px;
  }

  &__copyright {
    color: var(--color-gray-4);
    font-size: .7725rem;
    letter-spacing: 0.7px;
    padding-bottom: 20px;
    text-align: center;

    &:first-child {
      padding: 20px 0;
    }
  }

  .container:not(:first-child) {
    margin-top: 0;
  }
}