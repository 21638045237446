.content-spacer {
  --spacer-ratio: 1;
  --spacer-unit: calc(var(--ui-vertical-spacing, 1rem) * var(--spacer-ratio, 1));
  &.--small {
    --spacer-ratio: .75;
  }
  &.--smaller {
    --spacer-ratio: .5;
  }
  &.--smallest {
    --spacer-ratio: .25;
  }
  &.--medium {
    --spacer-ratio: 1.25;
  }
  &.--large {
    --spacer-ratio: 1.5;
  }
  &.--larger {
    --spacer-ratio: 1.75;
  }
  &.--largest {
    --spacer-ratio: 2;
  }
  &>* {
    &.--divider {
      border-bottom: 1px var(--ui-divider-color) solid;
      margin-top: calc(var(--spacer-unit, 1rem) * 1.5);

      &.--larger-space {
        margin-top: calc(var(--spacer-unit, 1rem) * 2);
      }

      &.--smaller-space {
        margin-top: calc(var(--spacer-unit, 1rem) * 1);
      }
    }

    &:not(:last-child) {
      margin-bottom: calc(var(--spacer-unit, 1rem) * 1.5);

      &.--larger-space {
        margin-bottom: calc(var(--spacer-unit, 1rem) * 2);
      }
    }
  }
}