@use '../abstracts' as *;

.dynamic-height{
  .--init & {
    height: var(--dh-height, auto);
    @include trns(var(--trns-duration, .3s), 2);
    &.--no-transition {
      transition: none;
    }
    &.--no-transition-xs {
      @include xs {
        transition: none;
        overflow: visible !important;
        clip-path: none !important;
      }
    }
    .product-detail &.--transitioning {
      @include xs {
        transition: none;
        overflow: visible !important;
        clip-path: none !important;
      }
    }
  }

  &:not(.--trigger-only).--transitioning{
    @media not (prefers-reduced-motion) {
      overflow: hidden;
      clip-path: inset(0px);
      --header-sticky: 0;
    }
  }
  &.--trigger-only.--triggered.--transitioning{
    @media not (prefers-reduced-motion) {
      overflow: hidden;
      clip-path: inset(0px);
      --header-sticky: 0;
    }
  }
  &>*{
    position: relative;
  }
  &.--offset{
    margin: -10px;
    &>*{
      padding: 10px;
    }
  }
  &.--offset-inline{
    margin: 0 -10px;
    &>*{
      padding: 0 10px;
    }
  }
  &.--offset-fields{
    margin: 0 -10px -10px;
    &>*{
      padding: 0 10px 10px;
    }
  }
}
.dynamic-height-active{
  transition: height var(--trns-duration, .3s) var(--lazy-easing);
  &.--easing{
    transition: height var(--trns-duration, .3s) var(--easing);
  }
  &.--reveal-easing{
    transition: height var(--trns-duration, .3s) var(--reveal-easing);
  }
  &.--transitioning{
    @media not (prefers-reduced-motion) {
      height: var(--dh-height, auto);
      overflow: hidden;
      clip-path: inset(0px);
      --header-sticky: 0;
    }
  }
  &>*{
    position: relative;
  }
  &.--offset{
    margin: -10px;
    &>*{
      padding: 10px;
    }
  }
}