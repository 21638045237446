@use './../abstracts/mixins/breakpoints.scss' as *;
@use './../abstracts/mixins/utility.scss' as *;

.hidden-mouse {
  @include mouse {
    display: none !important;
    visibility: hidden;
  }
}
.hidden-touch {
  @include touch {
    display: none !important;
    visibility: hidden;
  }
}

.overflow-visible {
  overflow: visible;
}

.visible {
  &-xl {
    display: none !important;
    visibility: hidden !important;

    @include xl {
      visibility: visible !important;
      display: block !important;
    }
  }

  &-lg {
    display: none !important;
    visibility: hidden !important;

    @include lg {
      visibility: visible !important;
      display: block !important;
    }
  }

  &-md {
    display: none !important;
    visibility: hidden !important;

    @include md {
      visibility: visible !important;
      display: block !important;
    }
  }

  &-sm {
    display: none !important;
    visibility: hidden !important;

    @include sm {
      visibility: visible !important;
      display: block !important;
    }
  }

  &-xs {
    display: none !important;
    visibility: hidden !important;

    @include xs {
      visibility: visible !important;
      display: block !important;
    }
  }

  &-xxs {
    display: none !important;
    visibility: hidden !important;

    @include xxs {
      visibility: visible !important;
      display: block !important;
    }
  }
}

.flex-visible {
  &-xl {
    display: none !important;
    visibility: hidden !important;

    @include xl {
      visibility: visible !important;
      display: flex !important;
    }
  }

  &-lg {
    display: none !important;
    visibility: hidden !important;

    @include lg {
      visibility: visible !important;
      display: flex !important;
    }
  }

  &-md {
    display: none !important;
    visibility: hidden !important;

    @include md {
      visibility: visible !important;
      display: flex !important;
    }
  }

  &-sm {
    display: none !important;
    visibility: hidden !important;

    @include sm {
      visibility: visible !important;
      display: flex !important;
    }
  }

  &-xs {
    display: none !important;
    visibility: hidden !important;

    @include xs {
      visibility: visible !important;
      display: flex !important;
    }
  }

  &-xxs {
    display: none !important;
    visibility: hidden !important;

    @include xxs {
      visibility: visible !important;
      display: flex !important;
    }
  }
}

.grid-visible {
  &-xl {
    display: none !important;
    visibility: hidden !important;

    @include xl {
      visibility: visible !important;
      display: grid !important;
    }
  }

  &-lg {
    display: none !important;
    visibility: hidden !important;

    @include lg {
      visibility: visible !important;
      display: grid !important;
    }
  }

  &-md {
    display: none !important;
    visibility: hidden !important;

    @include md {
      visibility: visible !important;
      display: grid !important;
    }
  }

  &-sm {
    display: none !important;
    visibility: hidden !important;

    @include sm {
      visibility: visible !important;
      display: grid !important;
    }
  }

  &-xs {
    display: none !important;
    visibility: hidden !important;

    @include xs {
      visibility: visible !important;
      display: grid !important;
    }
  }

  &-xxs {
    display: none !important;
    visibility: hidden !important;

    @include xxs {
      visibility: visible !important;
      display: grid !important;
    }
  }
}

.inline-visible {
  &-xl {
    display: none !important;
    visibility: hidden !important;

    @include xl {
      visibility: visible !important;
      display: inline !important;
    }
  }

  &-lg {
    display: none !important;
    visibility: hidden !important;

    @include lg {
      visibility: visible !important;
      display: inline !important;
    }
  }

  &-md {
    display: none !important;
    visibility: hidden !important;

    @include md {
      visibility: visible !important;
      display: inline !important;
    }
  }

  &-sm {
    display: none !important;
    visibility: hidden !important;

    @include sm {
      visibility: visible !important;
      display: inline !important;
    }
  }

  &-xs {
    display: none !important;
    visibility: hidden !important;

    @include xs {
      visibility: visible !important;
      display: inline !important;
    }
  }

  &-xxs {
    display: none !important;
    visibility: hidden !important;

    @include xxs {
      visibility: visible !important;
      display: inline !important;
    }
  }
}

.hidden {
  display: none !important;
  visibility: hidden !important;

  &-xl {
    @include xl {
      display: none !important;
      visibility: hidden !important;
    }
  }

  &-lg {
    @include lg {
      display: none !important;
      visibility: hidden !important;
    }
  }

  &-md {
    @include md {
      display: none !important;
      visibility: hidden !important;
    }
  }

  &-sm {
    @include sm {
      display: none !important;
      visibility: hidden !important;
    }
  }

  &-xs {
    @include xs {
      display: none !important;
      visibility: hidden !important;
    }
  }

  &-xxs {
    @include xxs {
      display: none !important;
      visibility: hidden !important;
    }
  }
}

.unmarg {
  margin: 0px !important;

  &-b {
    margin-bottom: 0px !important;
  }
}

.sr-only {
  width: 0;
  height: 0;
  overflow: hidden;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}

.clip-svg {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  display: block;
}