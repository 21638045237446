@use "../abstracts/mixins" as *;

section {
  --s-background: transparent;
  --s-ratio: var(--s-ratio-override, 1);
  --s-padding-top: calc(var(--spacing, 3rem) * var(--s-ratio-top-override, var(--s-ratio-top, 1)) * var(--s-ratio, 1));
  --s-padding-bot: calc(var(--spacing, 3rem) * var(--s-ratio-bot-override, var(--s-ratio-bot, 1)) * var(--s-ratio, 1));
  background: var(--s-background);
  padding-top: var(--s-padding-top);
  padding-bottom: var(--s-padding-bot);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;

  &.--full {
    &>.container {
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
  }

  &.--full-sm {
    &>.container {
      @include sm {
        width: 100%;
        max-width: 100%;
        padding: 0px;
      }
    }
  }

  &.--overflow,
  &.--visible,
  .--aside & {
    overflow: visible;
  }

  &.--reveal-text {
    --t-animation: none;

    &.--reveal-start {
      --t-animation: xFadeFromRight 0.4s var(--reveal-easing) forwards;

      &.--from-top {
        --t-animation: xFadeFromTop 0.4s var(--reveal-easing) forwards;
      }
    }

    .text-block>* {
      opacity: 0;
      animation: var(--t-animation);
      @include setStagger(10, 0.1s);
      will-change: transform, opacity;
    }

    .text-block>*>li {
      opacity: 0;
      animation: var(--t-animation);
      @include setStagger(10, 0.1s, 1, 1s, 0.2s);
      will-change: transform, opacity;
    }
  }

  &.--container-gap {
    .container:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &:first-child {
    --s-ratio-top: var(--s-ratio-top-override, 1.5);

    .--aside & {
      --s-ratio-top: var(--s-ratio-top-override, 0);
    }

    @include sm {
      --s-ratio-top: var(--s-ratio-top-override, 1.75);
    }

    @include xs {
      --s-ratio-top: var(--s-ratio-top-override, 1.55);
    }

    &.filter-bar__holder {
      &+section {
        @include to_sm {
          --s-ratio-top: var(--s-ratio-top-override, 0);
        }
      }
    }
  }


  &:last-of-type {
    --s-ratio-bot: var(--s-ratio-bot-override, 1.5);

    .--aside & {
      --s-ratio-bot: var(--s-ratio-bot-override, 0);
    }

    @include sm {
      --s-ratio-bot: var(--s-ratio-bot-override, 1.75);
    }

    @include xs {
      --s-ratio-bot: var(--s-ratio-bot-override, 1.55);
    }
  }

  &.--spacing-0 {
    --s-ratio: var(--s-ratio-override, 0);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 0);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 0);
    }
  }

  &.--spacing-025 {
    --s-ratio: var(--s-ratio-override, 0.25);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 0.25);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 0.25);
    }
  }

  &.--spacing-050 {
    --s-ratio: var(--s-ratio-override, 0.5);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 0.5);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 0.5);
    }
  }

  &.--spacing-075 {
    --s-ratio: var(--s-ratio-override, 0.75);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 0.75);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 0.75);
    }
  }

  &.--spacing-100 {
    --s-ratio: var(--s-ratio-override, 1);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 1);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 1);
    }
  }

  &.--spacing-125 {
    --s-ratio: var(--s-ratio-override, 1.25);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 1.25);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 1.25);
    }
  }

  &.--spacing-150 {
    --s-ratio: var(--s-ratio-override, 1.25);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 1.25);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 1.25);
    }
  }

  &.--spacing-200 {
    --s-ratio: var(--s-ratio-override, 2);

    &-top {
      --s-ratio-top: var(--s-ratio-top-override, 2);
    }

    &-bot {
      --s-ratio-bot: var(--s-ratio-bot-override, 2);
    }
  }

  &.--spacing-0-sm {
    @include sm {
      --s-ratio: var(--s-ratio-override, 0);

      &-top {
        --s-ratio-top: var(--s-ratio-top-override, 0);
      }

      &-bot {
        --s-ratio-bot: var(--s-ratio-bot-override, 0);
      }
    }
  }

  &.--bg-grey {
    background-color: var(--background-color-gray);
  }

  &.--add-margin-bottom {
    margin-bottom: calc(var(--spacing) * 1.667);
  }
}