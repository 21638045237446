@use "../variables" as *;
@use "./transitions" as *;

@mixin whiteBtnInteraction {
  background: var(--color-gray-8);
  box-shadow: inset 0 0 0 1px var(--color-gray-8),
  0 0px 0px #{rgba($color-gray-3, 0)},
  0 0px 0px #{rgba($color-gray-3, 0)},
  0 0px 0px #{rgba($color-gray-3, 0)};
  @include trns;

  &:hover {
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px var(--color-gray-7),
    0 14px 6px -10px #{rgba($color-gray-3, .12)},
    0 24px 10px -18px #{rgba($color-gray-3, .1)},
    0 0px 18px #{rgba($color-gray-3, .2)};
  }

  &:active {
    @include trns(.05s);
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px var(--color-gray-4),
    0 0px 0px 0px #{rgba($color-gray-3, 0)},
    0 0px 0px 0px #{rgba($color-gray-3, 0)},
    0 0px 0px #{rgba($color-gray-3, .2)};
  }
}