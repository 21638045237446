@use "./../abstracts" as *;

.membership-options {
  &__table {
    display: flex;
    justify-content: center;
    gap: var(--layout-spacing-row) var(--layout-spacing-column);
    @include xs {
      flex-direction: column;
      --layout-spacing-row: 3rem;
    }
    --detail-spacing: 20px;
  }

  &__selection {
    font-weight: 500;
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-flow: column;

    .heading {
      text-align: center;
      color: var(--color-primary-lighter);
      text-transform: uppercase;
      font-size: .7rem;
      font-weight: 500;
      margin-bottom: .5rem;
    }

    .monthly {
      margin-top: 2rem;

      @include xs {
        margin: 0;
      }
    }

    .detail {
      background: var(--background-color-gray);
      box-shadow: 0 0 2px var(--color-gray-4);
      border-radius: 4px;
      padding: var(--detail-spacing);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      @include to_xs {
        height: 100%;
      }
    }

    &.--discounted {
      @include to_xs{
        max-width: 310px;
      }

      svg {
        width: 60px;
        height: 61px;
        position: absolute;
        right: var(--detail-spacing);
        top: var(--detail-spacing);
      }
      .detail {
        position: relative;
        text-align: left;
      }
      .pricing {
        margin-right: 75px;
      }
    }
  }

  &__monthly-price {
    border-top: 1px solid var(--ui-divider-color);
    padding: 15px 0;
  }

  &__term {
    padding-bottom: 1rem;
  }
}

#membership-type-block {
  .membership-content-wrapper {
    grid-template-columns: 33% 1fr;
  }

  .media {
    width: 34px;
    margin-bottom: 1rem;
  }

  @include sm {
    .membership-content-wrapper {
      grid-template-columns: 1fr;
    }
  }

  @include xs {
    .media {
      margin: 0 auto 1rem;
    }
  }
}