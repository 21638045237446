@use "./../abstracts" as *;

.content-carousels {
    &__block-carousel {
        width: 100%;
        max-width: 300px;
        margin: auto;
    }

    &__subtext {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    &__divided.layout {
        grid-template-columns: 1fr .5rem 1fr;

        @include sm {
            --columns: 1;
            grid-template-columns: 1fr;
        }

        .--or-divider {
            background-color: var(--ui-divider-color);
            width: 1px;
            margin: 0 auto;
            position: relative;

            p {
                position: absolute;
                background: var(--color-white);
                top: calc(50% - 20px);
                left: calc(50% - 20px);
                letter-spacing: .4px;
                color: var(--color-gray-2);
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @include sm {
                width: 100%;
                height: 1px;
            }
        }
    }
}