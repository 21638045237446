@use "../../abstracts" as *;

.hint-tip{
  --tooltip-width: 200px;
  --tooltip-offset: 8px;
  --tooltip-size: var(--field-tooltip, 15px);
  position: relative;
  align-self: center;
  margin-left: 4px;
  &__icon{
    cursor: help;
    padding-bottom: 1px;
    display: inline-flex;
    font-weight: 600;
    font-size: .675rem;
    width: var(--tooltip-size);
    height: var(--tooltip-size);
    border-radius: 50%;
    color: var(--color-gray-4);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1px var(--color-gray-4);
    @include trns;
    .hint-tip:hover &, .hint-tip:focus-within &, .hint-tip.--active &{
      box-shadow: inset 0 0 0 9px var(--color-gray-2);
      color: var(--color-white);
    }
  }
  &__content{
    position: absolute;
    width: var(--tooltip-width);
    padding: .75rem;
    bottom: calc(100% + var(--tooltip-offset));
    z-index: 1;
    font-size: .75rem;
    font-weight: 500;
    left: calc(50% - var(--tooltip-width) / 2);
    border-radius: var(--ui-roundness, 0px);
    background-color: var(--color-white);
    color: var(--color-gray-1);
    box-shadow: 0 4px 28px #{rgba($color-gray-1, .2)};
    opacity: 0;
    pointer-events: none;
    transform: translateY(8px);
    &:before{
      content: '';
      width: 100%;
      height: calc(2px + var(--tooltip-offset));
      top: 100%;
      position: absolute;
      left: 0;
    }
    &:after{
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      bottom: 0px;
      left: calc(50% - 4px);
      border-radius: 2px;
      transform: scaleX(160%) translateY(3px) rotate(45deg);
      background: inherit;
    }
    @include trns;
    .hint-tip:hover &, .hint-tip:focus-within &, .hint-tip.--active &{
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }
  }
}