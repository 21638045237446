@use '../abstracts/mixins/breakpoints.scss' as *;

.content-highlight-block {
    .layout {
        .button-row,
        .app-icons {
            margin-top: calc(var(--vertical-spacing) * 2 * var(--scale));

            @include xs {
                text-align: center;
            }
        }

        &.--columns-2 {
            .layout__item {
                @include xs {
                    &:nth-child(1) {
                        order: 2;
                    }
                    &:nth-child(2) {
                        order: 1;
                    }
                }
            }
        }

    }
}