.accordion {
    .layout {
        gap: 1rem;
        align-items: start;

        .faq-item {
            border-top: 1px var(--ui-divider-color) solid;
            padding: 1rem 1rem 0;

            &:last-child {
                //border-bottom: 1px var(--ui-divider-color) solid;
                //padding-bottom: 1rem;
            }
        }
    }

    h2 {
        font-size: 1.25rem;

        &.search-results {
            padding-bottom: 2rem;
        }
    }

    &__content {
        display: none;

        h2 {
            margin-top: 1rem;
        }

        ol {
            padding: 0 0 1rem 2rem;

            li {
                list-style: decimal;
                padding-bottom: 5px;
            }
        }

        ul {
            padding: 0 0 1rem 2rem;

            li {
                list-style: disc;
                padding-bottom: 5px;
            }
        }
    }

    &__container {
        &.--active {
            .accordion__content {
                display: block;
                padding: 1rem 1.5rem;
                word-break: break-word;
                max-width: 740px;
            }

            .btn-accordion:not(.--toggle-arrows) {
                &::before {
                    transform: translateY(-3px) translateX(2px) rotate(-45deg) scale(1);
                }
            }
        }

        .btn-accordion:not(.--toggle-arrows) {
            color: var(--color-gray-1);
            height: auto;
            text-align: left;
            position: relative;
            text-align: left;
            padding: 3px 3px 3px 1.5rem;
            width: 100%;
            font-size: 0.925rem;

            &::before {
                content: "";
                pointer-events: none;
                width: 10px;
                height: 10px;
                position: absolute;
                top: calc(50% - 4px);
                left: 0;
                transform: translateY(-1px) translateX(-3px) rotate(-135deg) scale(1);
                border: 2px currentColor solid;
                opacity: .6;
                border-top: none;
                border-right: none;
                transition: all .2s var(--easing);
            }
        }
    }
}

.faq-no-results {
    h2 {
        &.search-results {
            padding-bottom: 2rem;
        }
    }
}