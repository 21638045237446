@use "../../abstracts/" as *;

.product-detail {
  --product-aspect-ratio: 16/9;
  --product-roundness: 10px;
  --product-spacing: 1rem;
  --product-spacing-large: 1.8rem;
  --product-width: var(--product-custom-width, 550px);
  --product-detail-height: unset;
  --product-detail-gap: 2.6rem;

  .--product-quickview & {
    margin: 0 calc(var(--offset-gap) * -1);

    @include xs {
      --offset-gap: 0px;
    }
  }

  & section:not(.product-detail-section) {
    --s-ratio-top-override: 0;
  }

  .product-detail-section {
    --s-ratio-bot-override: 1.55;
  }

  @include lg {
    --product-detail-gap: 2rem;
  }

  section {
    overflow: visible;

    @include xs {
      --s-ratio-top-override: 1;
    }
  }

  #applepay-password {
    max-width: 470px;

    @include sm {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.--smashup {
    --product-detail-height: 400px;
    --product-detail-height-md: 341.82px;
    --product-aspect-ratio: 550/400;
  }

  &.--creatacard:not(.--landscape-orientation) {
    --product-detail-height: clamp(1px, 535px, 90vh);
    --product-detail-height-md: clamp(1px, 502px, 90vh);

    .--product-quickview & {
      --product-detail-height: unset;
      --product-detail-height-md: unset;
    }
  }

  &.--creatacard.--landscape-orientation:not(.--no-envelope) {
    --cac-landscape-offset: 2rem;

    .product-detail__content {
      @include to_sm {
        padding-top: var(--cac-landscape-offset, 0);
      }
    }
  }

  &.--ecard {
    --product-aspect-ratio: 464/270;
    --product-detail-height: calc(clamp(0px, var(--container), var(--client-width, 100vw) - 2* var(--offset-gap)) / 2* var(--product-reverse-ratio, 0));
    --product-detail-height-md: var(--product-detail-height);

    .--product-quickview & {
      --product-detail-height: unset;
      --product-detail-height-md: unset;
    }
  }

  &.--postcard {
    --product-aspect-ratio: unset;
  }

  &.--talking-smashup,
  &.--selfie-smashup {
    #agi-expression-overlay>button {
      border-radius: 50px;

      &:not(.product-detail__animation-toggle) {
        width: 40px !important;
        background-position: center !important;
      }
    }

    #card-controls {
      position: absolute;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &.--picsnwishes {
    --product-aspect-ratio: unset;

    .product-container {
      border-radius: var(--product-roundness);
    }

    .content__pnw {
      background-color: #F1E8EA;
      border: 2px solid #6E1B25;
      border-radius: var(--product-roundness);
      max-width: 420px;
      padding: 45px;

      .--logo {
        margin-bottom: 1.5rem;
      }

      @include sm {
        margin: auto;
      }
    }
  }

  &__pdp-redirect {
    @include setInset;
    z-index: 10;
    border-radius: var(--product-roundness);
    outline-offset: 3px;
  }

  &__sticky-bar {
    @media (min-height: 500px) {
      .product-detail:not(.--selfie-smashup, .--talking-smashup) & {
        position: sticky;
        z-index: 1;

        .--product-quickview & {
          z-index: unset;
          position: static;
        }
      }
    }

    bottom: 0;
    padding: 1rem 0;
    margin: -1rem 0;
    background-color: var(--color-white);
    box-shadow: 10px 0px 0 0px var(--color-white),
    -10px 0px 0 0px var(--color-white);

    .product-detail__module {
      margin-top: 0;
    }

    .product-detail.--selfie-smashup &,
    .product-detail.--talking-smashup & {
      .product-detail__module.--cta {
        margin-top: -1rem !important;
      }
    }

    &.--inactive {
      opacity: .7;
      pointer-events: none;
    }
  }

  .form {
    & .btn.--submit {
      margin-top: 0px;
    }

  }

  .form__errors:not(.--empty) {
    margin-bottom: var(--product-spacing);
  }

  .generate-message .form__errors:not(.--empty) {
    margin-bottom: var(--layout-spacing-row);
  }


  &.--interactive {
    .form .btn.--submit {
      @include to_xs {
        margin-top: var(--product-spacing);
      }
    }
  }

  &.--interactive,
  &.--talking-smashup,
  &.--selfie-smashup {
    --product-aspect-ratio: 1/1;
    --product-detail-height: 550px;
    --product-detail-height-md: 470px;

    .--product-quickview &,
    &.--landscape-interactive {
      --product-detail-height: unset;
      --product-detail-height-md: unset;

      .product-detail__content {
        align-self: center;
      }
    }

    @include xs {
      --product-width: var(--product-custom-width, 450px);
    }
  }

  &__top-part {
    margin-bottom: var(--product-spacing);

    .--product-quickview & {
      padding-top: 1rem;
    }
  }

  @include xs {
    &.--creatacard {
      --product-width: 380px;

      .product-detail {
        &__container {
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
        }

        &__preview {
          margin-top: calc(var(--s-padding-top, var(--dialog-spacing)) * -1);
          padding-top: calc(var(--s-padding-top, var(--dialog-spacing)) * 1.7);
          overflow: hidden;
          pointer-events: none;
          padding-bottom: 4rem;
          margin-bottom: -2rem;
          z-index: 2;

          .--product-quickview & {
            overflow: visible;
          }
        }

        &__top-part,
        &__content,
        &__preview-container {
          pointer-events: all;
          width: 100%;
          max-width: var(--container-gaps);
          padding-left: var(--offset-gap);
          padding-right: var(--offset-gap);
          margin: 0 auto;
        }
      }
    }

    &.--postcard {
      --product-width: 450px;
    }
  }

  &__iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: var(--product-aspect-ratio);
    border-radius: var(--product-roundness);

    &:fullscreen {
      border-radius: 0;
    }
  }

  &__layout {
    display: grid;
    grid-template-columns: repeat(2, minmax(1px, 1fr));
    align-items: start;

    @include xs {
      grid-template-columns: minmax(1px, 1fr);
    }
  }

  .--pps-button-container {
    &:not(.--has-preview-cta) {
      .layout {
        flex-direction: column;
        width: 80%;

        @include sm {
          width: 100%;
          --ui-btn-spacing: 1rem;
          gap: 0;
        }

        .btn {
          width: 100%;
        }
      }
    }

    .--apple-pay {
      width: 80%;

      @include sm {
        width: 100%;
      }
    }

    &.--has-preview-cta {
      .preview_container {
        padding-left: 46px;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--ui-divider-color);

        .btn {
          width: 100%;
        }

        @include sm {
          padding-left: 40px;
        }

        .--product-quickview & {
          padding-left: 0 !important;
        }
      }

      .layout {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 0;

        .btn {
          flex-grow: 1;
        }

        @include lg {
          flex-direction: column;

          &>* {
            width: 100%;
          }
        }
      }

      .entry-animation {
        display: flex;
        justify-content: flex-end;

        .--apple-pay {
          width: 290px;

          @include lg {
            width: 100%;
          }
        }
      }
    }
  }

  .--pps-addons {
    &:not(.--has-preview-cta) {
      flex-direction: column;

      @include xs {
        align-items: center;
      }
    }

    &.--has-preview-cta {
      flex-direction: row-reverse;

      .member-benefits-btn {
        position: absolute;
        bottom: 110px;
        right: -2rem;

        @include lg {
          position: unset;
        }
      }

      @include lg {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .--membership-offer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    @include xs {
      justify-content: center;
    }

    @include max(350) {
      gap: 6px;
    }

    .member-benefits-btn {
      --ui-btn-height: 20px;
      --ui-btn-width: 20px;
    }
  }

  .membership-offer {
    position: relative;
    padding: 0.65rem;
    border-radius: var(--ui-roundness);
    width: 133px;
    border: 1px solid var(--color-gray-5);

    @include xs {
      text-align: left;
    }

    @include max(350) {
      width: 126px;
    }

    &__title,
    &__price-discount {
      font-weight: 500;
      line-height: 1.5em;
      font-size: 0.925rem;
      letter-spacing: 0.5px;
      margin-bottom: 0 !important;
    }

    &__title {
      font-size: 1rem;
      text-transform: capitalize;
    }

    &__price {
      text-decoration: line-through;
      font-size: 0.85rem;
      line-height: 1.5em;
      color: var(--color-gray-2);
      margin-bottom: 0 !important;
    }

    &__price-discount {
      color: var(--color-cardinal);
    }

    svg {
      position: absolute;
      right: 0.65rem;
      top: 0.65rem;
    }
  }

  &__preview {
    .product-detail.--ecard & {
      video {
        aspect-ratio: var(--product-aspect-ratio, 16/9) !important;
        width: 100%;
        height: auto;
        position: relative !important;
      }
    }

    #agi-brightcove {
      width: 100% !important;
      height: auto !important;
      display: grid;

      div.video-js div.vjs-poster {
        background-color: transparent;
        border-radius: var(--product-roundness);
        background-size: cover;
      }

      .video-js .vjs-time-control {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      @include xs {
        .video-js .vjs-control:not(.vjs-progress-control, .vjs-volume-control) {
          width: 3.6em;
          padding: 0;
        }

        .video-js .vjs-time-control {
          display: none;
        }
      }

      @include xxs {
        .video-js .vjs-control:not(.vjs-progress-control, .vjs-volume-control) {
          width: 42px;
        }
      }

      /* Override styles for AG BrightCove player */
      .vjs-big-play-button {
        background: rgba(0, 0, 0, 0.5);
        transition: color .2s, background-color .2s;

        &:focus,
        &:hover {
          transition: color 0s, background-color 0s;
          background-color: #d50032;
        }
      }

      .vjs-menu li.vjs-selected {
        color: inherit;
        /* overrides the default of #2B333F */
      }

      /* Play progress bar colors */
      .vjs-progress-holder .vjs-load-progress,
      .vjs-progress-holder .vjs-load-progress div {
        background-color: rgba(115, 133, 159, .5);
      }

      .vjs-progress-holder .vjs-play-progress,
      .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level,
      .vjs-menu li.vjs-selected {
        background-color: #d50032;
      }
    }

    #agi-brightcove.default-bc div.video-js {
      width: 100% !important;
      height: auto !important;
      border-radius: var(--product-roundness);
      overflow: hidden;
      display: grid;

      video {
        object-fit: cover;
      }

      &.vjs-fullscreen {
        display: block;
        border-radius: 0px;

        video {
          object-fit: contain;
        }
      }
    }

    #vjs-transcript,
    #ts-filler-container {
      margin-left: calc(var(--preview-overflows) * var(--preview-spacing, 0));
      margin-right: calc(var(--preview-overflows) * var(--preview-spacing, 0));
      max-width: calc(100% - var(--preview-spacing) * var(--preview-overflows) * 2);
    }

    .video-js .vjs-time-control {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @include xs {
      .video-js .vjs-control:not(.vjs-progress-control) {
        width: 3.6em;
      }
    }

    .product-detail.--smashup & {
      #agi-expression-container {
        width: 100% !important;
      }
    }

    .product-detail.--smashup.--ready &,
    .product-detail.--interactive.--ready & {
      display: grid;
    }
  }

  &__preview-watcher {
    .--product-quickview & {
      width: 100%;
      display: grid;
      grid-template-columns: minmax(1px, 1fr);
    }
  }

  &__preview-container {
    position: relative;

    &:before {
      content: "";
      @include brandingLoader(#f2f2f2, #fcfcfc);
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      border-radius: var(--product-roundness);
      transition: opacity .3s var(--reveal-easing);

      .product-detail.--waits-visible &,
      .product-detail.--ecard &,
      .product-detail.--talking-smashup &,
      .product-detail.--selfie-smashup & {
        z-index: 2;
      }

      .product-detail.--creatacard &,
      .product-detail.--postcard & {
        display: none;
      }
    }

    .product-detail:not(.--creatacard) & {
      aspect-ratio: var(--product-aspect-ratio);
      border-radius: var(--product-roundness);
      overflow: hidden;

      .--product-quickview & {
        overflow: visible;
      }
    }

    .product-detail.--ecard & {
      .product-detail__preview-watcher {
        position: absolute;
        opacity: 0;
        top: 0;
        display: grid;
        @include trns(.2s, 2);
      }
    }

    .product-detail.--ecard.--ready & {
      .product-detail__preview-watcher {
        position: static;
        opacity: 1;
      }
    }

    .product-detail.--ecard.--ready & {
      aspect-ratio: auto;
      border-radius: 0;
      overflow: visible;
    }

    .product-detail.--ready & {
      aspect-ratio: auto;

      .product-html-div,
      #agi-expression-container {
        overflow: hidden;
        border-radius: var(--product-roundness);

        &.--fullscreen {
          border-radius: 0px;
        }
      }
    }

    .product-detail.--ready &:before {
      opacity: 0;
    }

    .product-detail.--after-ready &:before {
      display: none !important;
    }

    .product-detail.--ecard & {
      &:before {
        height: auto;
        display: block;
        aspect-ratio: var(--product-aspect-ratio, 16/9);
      }
    }
  }

  &__body {
    margin-top: var(--product-spacing-large);

    .product-detail.--smashup & {
      @include xs {
        display: none;
      }
    }
  }

  &__footer {
    margin-top: var(--product-spacing);
  }

  &__inner {
    @include xs {
      text-align: center;
    }
  }

  &__container {
    .--product-quickview & {
      width: var(--container-gaps);
      max-width: 100%;
    }

    .product-detail:not(.--ecard) & {
      @include to_sm {
        --container-gaps: calc(940px + var(--offset-gap) * 2) !important;
      }

      @include to_lg {
        --container-gaps: calc(var(--product-width) * 2 + var(--offset-gap) * 2) !important;
      }
    }

    .product-detail.--non-standard-width & {
      @include to_sm {
        --container-gaps: calc(var(--product-width) * 2 + var(--offset-gap) * 2) !important;
      }

      @include to_lg {
        --container-gaps: calc(var(--product-width) * 2 + var(--offset-gap) * 2) !important;
      }
    }

    @include xs {
      @include to_xxs {
        --container-gaps: calc(var(--product-width) + var(--offset-gap) * 2) !important;
      }
    }
  }

  &__content {
    display: grid;
    align-self: flex-start;
    @include setTransition(.2s, 2, min-height);

    .--picsnwishes &,
    .--product-quickview .--creatacard & {
      align-self: center;
    }

    @include to_sm {
      min-height: var(--product-detail-height, unset);
      align-items: center;

      .product-detail.--postcard & {
        height: 100%;
        max-height: 550px;

        @include lg {
          max-height: 470px;
        }
      }

      @include lg {
        min-height: var(--product-detail-height-md, unset);
      }

    }

    .--product-quickview .product-detail.--ecard & {
      min-height: unset;
      align-self: center;
    }

    body.error-page & {
      min-height: var(--product-detail-height, unset);
      align-items: center;
      height: 100%;
      max-height: 550px;

      @include lg {
        max-height: 470px;
      }
    }

    @include to_xs {
      padding-left: var(--product-detail-gap);
    }

    @include xs {
      padding-top: 2rem;

      .product-detail.--creatacard & {
        padding-top: 0;
      }
    }
  }

  &__bar {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px var(--ui-divider-color) solid;
    margin-top: 0.7rem;
    margin-bottom: 1.6rem;

    @include xs {
      justify-content: center;
      margin-top: 0.5rem;
    }

    gap: 1rem;

    .hidden-sm:not(:only-child) {
      margin-left: auto;
    }

    .add-to-favorites {
      --icon-color: var(--color-primary-lighter);

      &__icon {
        position: relative;
        margin-right: 8px;

        svg {
          @include trns(.3s);
        }

        .add-to-favorites__icon-overlay {
          color: var(--icon-color);
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          @include trns(.3s);
        }
      }

      &.--loading:not(.--loaded) {
        .add-to-favorites__icon svg {
          opacity: 0;
        }

        .add-to-favorites__icon-overlay {
          opacity: 1;
        }
      }
    }

  }

  &__control {

    .product-detail.--selfie-smashup &,
    .product-detail.--talking-smashup & {
      @include xs {
        margin-bottom: calc(var(--product-spacing-large) + 1rem);

        .--product-quickview & {
          margin-bottom: 0;
        }
      }

      .--membership-offer {
        justify-content: flex-end;

        @include lg {
          justify-content: center;
        }
      }

      --ui-btn-spacing: 1rem;
    }

    .product-detail.--ecard &,
    .product-detail.--postcard & {
      .product-detail__module.--cta {
        margin-top: 0;

        .product-detail__module {
          margin-top: 0;
        }
      }
    }

    .product-detail.--smashup &,
    .product-detail.--creatacard &,
    .product-detail.--interactive & {
      .product-detail__module.--cta.--pps-container {
        @include xs {
          margin-top: 0;
          .product-detail__module {
            margin-top: 0;
          }
        }
      }
    }
  }

  &__module {
    margin-top: var(--product-spacing);

    &.--cta {
      &:not(.--no-border) {

        .product-detail.--selfie-smashup &,
        .product-detail.--talking-smashup & {
          margin-top: var(--product-spacing-large);
          padding-top: var(--product-spacing);
          border-top: 1px solid var(--ui-divider-color);

          .--product-quickview & {
            margin-top: var(--product-spacing);
            padding-top: 0;
            border-top: none;

            @include xs {
              margin-top: 0;
            }
          }

          .product-detail__module {
            margin-top: 0;
          }

          button[data-content-key="preview-btn"] {
            .btn__content {
              min-width: 107px;

              @media (max-width: 500px) {
                min-width: 96px;
              }
            }
          }
        }
      }

      button[data-content-key="sound-control-btn"] {
        .btn__content {
          min-width: 96px;

          @media (max-width: 500px) {
            min-width: 85px;
          }
        }
      }

      &>* {
        @include trns;
      }

      &.--inactive {
        &>* {
          opacity: .7;
          pointer-events: none;
        }
      }
    }

    &.--instructions {
      margin-top: 0;
    }

    &.--music-control {
      display: grid;
      justify-content: flex-start;

      @include xs {
        justify-content: center;
      }

      .checkbox.--switch.--filled {
        padding: 0;

        .checkbox__text {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }

    &.--start {
      --vertical-spacing: 0.5rem;

      @include xs {
        display: grid;
      }
    }

    &.--update-payment {
      @include xs {
        display: grid;
      }
    }

    &.--copyright {
      .copyright {
        display: grid;
        gap: 1.2rem;

        &__logo {
          display: grid;
        }
      }

      .accordion__container .btn-accordion {
        width: auto;
      }

      img,
      svg {
        height: 52px;
        width: auto;

        @include xs {
          margin: 0 auto;
        }
      }
    }

    &.--giftcard {
      margin-top: 0px;
      font-weight: 500;
      background-color: transparent;
      padding: 14px 0 13px;
      gap: 11px;
      display: inline-flex;
      align-items: center;
      --ui-btn-height: 20px;
      --ui-btn-width: 20px;
      text-wrap: balance;
      font-size: .875rem;
      letter-spacing: .1px;

      p {
        line-height: normal;
      }

      @include sm {
        padding: 0 0;
        margin-top: 1.5rem;

        @include xs {
          .product-detail.--smashup.--can-send & {
            margin-top: 0rem;
          }
        }
      }

      span {
        display: flex;

        &:not(:last-child) {
          margin-right: -6px;
        }

        &>svg {
          max-height: 20px;
          width: auto;
        }

        .giftcard-info {
          svg {
            height: 12px;
          }
        }
      }
    }

    &.--verse,
    &.--copyright {
      margin-top: 1.5rem;

      .text-block {
        --scale: 0.8975;
        --vertical-spacing: 0.4rem;
        --offset-spacing: 0;

        @include xs {
          text-align: center;
        }

        h2,
        .mimic-h2,
        h3 {
          margin-bottom: 0.4rem;
          font-size: 0.925rem;
          font-weight: 500;

          &:not(:first-child) {
            margin-top: 1.3rem;
            margin-top: var(--product-spacing);
          }
        }
      }

      .full-verse__container {
        display: none;
      }

      .short-verse__container {
        display: inline;
      }

      &.--active {
        .full-verse__container {
          display: inline;
        }

        .short-verse__container {
          display: none;
        }
      }
    }

    &.--control {
      .form__error>* {
        padding-left: 10px;
        display: flex;

        @include xs {
          justify-content: center;
        }

        &:before {
          content: "";
          height: 22px;
          min-width: 14px;
          transform: translateY(1px);
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 15px;
          position: static;
          display: block;

          @include xs {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__music-module {
    @include to_xxs {
      display: flex !important;
      align-items: stretch !important;

      .--verse:first-of-type {
        width: 110%;
        @include sm {
          width: 100%;
        }
      }

      .product_display_music_wrapper {
        text-align: center;
      }

      .form__options.--music-list {
        grid-template-columns: 1fr;
        height: 200px;
        overflow-y: scroll;
        scrollbar-width: none;
        padding: 5px;
        margin: 0 -5px;
        -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,0) 0,#000 10px,#000 calc(100% - 10px),rgba(0,0,0,0) 100%);
      }
    }
    @include sm {
      flex-direction: column;
    }
    .--verse{
      margin-top: 0;
    }
    margin-top: 1.5rem;
  }

  &__reviews {
    .layout.--add-border {
      @include xs {
        padding: 0;
        border: none;
        border-top: 1px solid var(--color-gray-5);
        padding-top: 2rem;
        border-radius: 0px;

        &>div {
          margin: 0 -10px;
        }
      }
    }
  }

  &__title {
    --vertical-spacing: .7rem;

    .inline__item {
      display: grid;

      .badge-inline {
        margin-top: 0;
      }
    }
  }

  &__animation-toggle {
    width: 6.18%;
    height: 6.18%;
    position: absolute;
    left: 1.46%;
    bottom: 1.45%;
    border-radius: 50px;
    background-color: #333;
    z-index: 1;
    border: none;
    outline-offset: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    .--previewing & {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      width: 70%;
      height: 70%;
      position: absolute;
      left: 15%;
      top: 15%;
      @include trns;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transform-origin: center;
      transform-style: flat;
      will-change: transform, opacity;
    }

    // pause icon
    &:before {
      opacity: 0;
      transform: scale(0.7);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M17.276 5.47c.435.16.724.575.724 1.04v10.98c0 .464-.29.88-.724 1.04a3.69 3.69 0 0 1-2.552 0A1.107 1.107 0 0 1 14 17.49V6.511c0-.465.29-.88.724-1.04a3.69 3.69 0 0 1 2.552 0Zm-8 0c.435.16.724.575.724 1.04v10.98c0 .464-.29.88-.724 1.04a3.69 3.69 0 0 1-2.552 0A1.107 1.107 0 0 1 6 17.49V6.511c0-.465.29-.88.724-1.04a3.69 3.69 0 0 1 2.552 0Z'/%3E%3C/svg%3E");
    }

    // play icon
    &:after {
      opacity: 1;
      transform: scale(1);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M19.44 13.516a1.917 1.917 0 0 0 0-3.032 35.763 35.763 0 0 0-9.916-5.416l-.653-.232c-1.248-.443-2.567.401-2.736 1.69a42.49 42.49 0 0 0 0 10.948c.17 1.29 1.488 2.133 2.736 1.69l.653-.232a35.762 35.762 0 0 0 9.917-5.416Z'/%3E%3C/svg%3E");
    }

    &.--playing {
      &:before {
        opacity: 1;
        transform: scale(1);
      }

      &:after {
        opacity: 0;
        transform: scale(0.7);
      }
    }
  }

}

body.--product-has-photo-modal {

  #filepicker_dialog_container,
  #filepicker_shade,
  #expression-image-editor-overlay {
    display: block !important;
  }
}

// Interactive Product with Photo Editor
#expression-image-editor-overlay {
  --range-thumb-height: 26px;

  #expression-image-editor-holder-desktop {
    display: grid;
    border-radius: 20px;
    grid-template-columns: 1fr auto;
    width: fit-content;
    height: auto;
    padding: 1.5rem;
    gap: 1.5rem;
    --image-editor-size: 550px;

    @include max(900) {
      --image-editor-size: 440px;
    }

    @include max(770) {
      --image-editor-size: 330px;
    }

    #expression-image-editor-image-container {
      margin: unset;
      float: none;
      border-radius: 10px;
      overflow: hidden;
      width: var(--image-editor-size);
      height: var(--image-editor-size);

      #expression-image-editor-canvas-container {
        border-radius: 10px;
        overflow: hidden;
        width: var(--image-editor-size) !important;
        height: var(--image-editor-size) !important;
      }

      #expression-image-editor-canvas,
      #expression-image-editor-canvas-overlay,
      #expression-image-editor-event-proxy,
      #expression-image-editor-canvas-background {
        width: var(--image-editor-size) !important;
        height: var(--image-editor-size) !important;
      }

      #expression-image-editor-instruction-overlay {
        left: 10% !important;
        top: 10% !important;
        height: 80%;
        width: 80%;
        background-size: contain;
        background-position: center;
      }
    }

    #expression-image-editor-controls {
      height: auto;
      float: none;
      font-size: 0.903125rem;
      display: grid;
      align-content: center;
      width: 200px;

      &>hr {
        display: block;
        width: 100%;
        margin: 1rem 0;
        border: none;
        background: var(--ui-divider-solid-color);
        height: 1px;
      }

      &>div {
        display: flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
      }
    }

    #expression-image-editor-scaling-btns,
    #expression-image-editor-rotation-btns {
      margin-top: 0;
      display: grid !important;
      justify-content: normal !important;

      label {
        text-transform: capitalize;
        display: block;
      }
    }

    #expression-image-editor-reset+div,
    #expression-image-editor-reset-version-2+div {
      display: none !important;
    }

    #expression-image-editor-remove-version-2,
    #expression-image-editor-reset-version-2,
    #expression-image-editor-done-version-2,
    #expression-image-editor-done {
      height: 40px;
      width: 100%;
      padding: 6px 1rem;
      font-weight: 500;
      letter-spacing: 0.2px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      background: transparent;
      position: relative;
      border-radius: var(--ui-roundness, 0px);
      position: relative;
      z-index: 1;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 10px;
      }

      &:after {
        pointer-events: none;
        content: "";
        @include setInset(0px);
        background-color: currentColor;
        transform: scale(0.3);
        @include trns(0.2s, 3);
        z-index: -1;
        opacity: 0.08;
        transform: scale(1);
        border-radius: var(--ui-roundness, 0px);
      }

      @include mouse {
        &:hover {
          &::after {
            @include trns(0.1s);
            opacity: 0.12;
            transform: scale(1);
          }
        }
      }

      &:focus-visible {
        &::after {
          opacity: 0.12;
          transform: scale(1);
        }
      }

      &:active {
        &::after {
          @include setTransition(0s, 1, opacity, transform);
          opacity: 0.16;
          transform: scale(1);
        }
      }
    }

    #expression-image-editor-remove-version-2 {
      &::before {
        content: "Change Photo";
        display: inline-flex;
      }
    }

    #expression-image-editor-reset-version-2 {
      &::before {
        content: "Reset Photo";
        display: inline-flex;
      }
    }

    #expression-image-editor-done-version-2,
    #expression-image-editor-done {
      &::before {
        content: "Continue";
        display: inline-flex;
      }

      color: var(--color-white);
      background-color: var(--color-gray-1);
      @include setTransition(0.2s, 1, box-shadow, transform, background, opacity);
      outline-offset: 3px;

      &:after {
        display: none;
      }

      @include mouse {
        &:hover {
          @include trns(0.1s);
          background-color: var(--color-primary-lighter);
        }
      }

      &:focus-visible {
        @include trns(0.1s);
        background-color: var(--color-primary-lighter);
      }

      &:active {
        @include setTransition(0s, 1, opacity, transform);
        background-color: var(--color-primary-darker);
      }
    }
  }
}

.rating-button {
  --rating-progress: var(--rating-percent, 0%);
  text-decoration: none;
  line-height: 1;
  z-index: 0;
  isolation: isolate;

  &__fill {
    clip-path: polygon(0 0, var(--rating-progress) 0%, var(--rating-progress) 100%, 0% 100%);
    @include trns;
  }

  &__base {
    position: absolute !important;
    z-index: -1;
    left: 0;
    top: 0;
    opacity: .38;

    path {
      fill: var(--btn-bg);
      @include trns;
    }
  }

  .btn-inline-simple__icon {
    position: relative;
  }

  &.--empty {
    .btn-inline-simple__icon {
      margin-right: 0px;
    }

    .btn-inline-simple__text {
      display: none;
    }

    .rating-button__base {
      opacity: 1;
      overflow: visible;

      path {
        stroke: var(--btn-bg);
        stroke-width: 2px;
        fill: transparent;
      }
    }

    .rating-button__fill {
      clip-path: none;
      opacity: 0;
      @include trns;
    }

    &:hover,
    &:active {
      .rating-button__fill {
        opacity: 1;
        @include trns(.1s);
      }
    }
  }
}

#bv_review_maincontainer h2 {
  display: none;
}

.creatacard {

  .product-detail:not(.--no-envelope) & {
    @include xxs {
      padding-top: 0;
      padding-bottom: 1.5rem;
    }
  }

  .product-detail.--has-toggle-music-control & {
    .dialog & {
      @include to_xs {
        padding-bottom: calc(34px + 1rem);
      }
    }
  }

  .--landscape-orientation & {
    padding-top: var(--cac-landscape-offset, 0);
  }

  &__container,
  &__envelope {
    position: relative;

    &-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 1;
      @include trns(.3s, 2);

      .--loaded & {
        transition-delay: calc(.5s + var(--animation-delay, 0s));
        opacity: 0;
        pointer-events: none;
      }
    }

    &-content {
      position: relative;
      z-index: 2;
      opacity: 0;
      pointer-events: none;
      @include trns(.3s, 2);
      --scale-value: 1.05;

      .--loaded & {
        animation: scaleIn .5s var(--reveal-easing, linear) var(--animation-delay, 0s) both;
        pointer-events: all;

        @media (prefers-reduced-motion) {
          animation: scaleIn 0s linear both;
        }
      }
    }
  }

  &__envelope {
    --animation-delay: .4s;
    position: absolute;
    width: 70%;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateZ(0px);

    @include lg {
      right: 44%;
      left: unset;
    }

    .--product-quickview & {
      left: 0;
      right: unset;
    }

    @include xxs {
      left: unset;
      right: var(--offset-gap);
      width: calc(100% - var(--offset-gap)* 2);
      top: unset;
      bottom: 0;
      transform: none;
    }



    svg {
      width: 100%;
      height: auto;
    }

    .--landscape-orientation & {
      top: 40%;
      width: 60%;

      @include xs {
        right: 55%;
        width: 52%;
      }

      @include xxs {
        top: unset;
        bottom: 0;
      }
    }
  }

  &__container {
    z-index: 2;
    position: relative;
    max-width: 70%;
    width: 360px;
    margin-left: auto;
    margin-right: 1rem;
    display: grid;

    .product-detail.--no-envelope & {
      margin-right: auto !important;
    }

    @include to_lg {
      width: 350px;
    }

    @include xxs {
      margin-right: auto;
      max-width: 76%;
    }

    .--product-quickview & {
      @include md {
        margin-right: 0rem;
      }

      @include xxs {
        margin-right: auto;
        max-width: 76%;
      }
    }


    .--landscape-orientation:not(.--no-envelope) & {
      width: 85%;
      max-width: 85%;
      margin-right: 1rem;

      @include xs {
        width: 92%;
        max-width: 92%;
        margin-right: 0rem;
      }
    }

    .--landscape-orientation.--no-envelope & {
      width: 100%;
      max-width: 100%;
      margin-right: 1rem;

      @include xs {
        width: 100%;
        max-width: 100%;
        margin-right: 0rem;
      }
    }

    .--landscape-orientation & {
      .--product-quickview & {
        @include md {
          margin-right: 0rem;
        }

        @include xxs {
          margin-right: auto;
        }
      }
    }

    &-loader {
      display: grid;
      place-content: center;
      background-color: #F5F5F5;
      box-shadow: inset 0 0 0 1px #ececec;

      img {
        width: 110px;
        height: 110px;

        @include xs {
          width: 80px;
          height: 80px;
        }
      }
    }

    &-placeholder {
      opacity: 0;

      .--loaded & {
        display: none;
      }
    }

    &-content {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;

      .--loaded & {
        position: relative;
      }
    }
  }

  #card3D-text-instructions {
    display: none;
  }

  .card3D-card div {
    display: grid;
  }

  .product-detail:not(.--has-music) & {
    #play-pause-button {
      display: none !important;
    }
  }

  #play-pause-button {
    margin: 1rem auto;
  }

  .card3D-right-shadow,
  .card3D-left-shadow {
    box-shadow: none;
    filter: blur(7px);
    pointer-events: none;
  }
}

.creatacard-control {
  padding: 0 0 1rem;

  &.--absolute {
    padding: 1rem 0 0;
    position: absolute;
    width: 100%;
    top: 100%;
  }

  .creatacard-control-btn {
    margin: 0 auto;
  }
}

.creatacard-control-btn {
  // default
  --color: var(--color-white);
  --background: var(--color-primary-lighter);
  // hovered
  --color-hover: var(--color-white);
  --background-hover: var(--color-primary);
  // active
  --color-active: var(--color-white);
  --background-active: var(--color-primary-darker);
  --on: 0;
  --off: 1;

  &.--on {
    --on: 1;
    --off: 0;
  }

  &.--inactive {
    pointer-events: none;
    background-color: var(--color-gray-5);
  }

  color: var(--color);
  position: relative;
  width: 36px;
  height: 36px;
  outline-offset: 4px;
  border-radius: 50%;
  margin: 0;
  display: flex;
  padding: 0;
  border: none;
  cursor: pointer;
  background: var(--background);
  @include trns(0.3s, 2);

  &__on,
  &__off {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    place-content: center;
    @include trns;

    svg path {
      @include trns;
    }
  }

  @include mouse {
    &:hover {
      @include trns;
      color: var(--color-hover);
      background: var(--background-hover);

      .creatacard-control-btn {

        &__on,
        &__off {
          svg path {
            @include trns;
          }
        }
      }
    }
  }

  &:focus-visible,
  &:active {
    @include trns(0s);

    .creatacard-control-btn {

      &__on,
      &__off {
        svg path {
          @include trns(0s);
        }
      }
    }
  }

  &:active {
    color: var(--color-active);
    background: var(--background-active);
  }

  &__on {
    transform: scale(calc(1 - .3 * var(--off)));
    opacity: var(--on);
  }

  &__off {
    transform: scale(calc(1 - .3 * var(--on)));
    opacity: var(--off);
  }
}