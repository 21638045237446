.content-tile {
    &__item {
        padding: 1rem;
        box-shadow: 0 0 2px var(--color-gray-4);
        background: var(--background-color-gray);
        border-radius: var(--ui-roundness, 0);
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-flow: column;

        .--img-button {
            width: 100%;
        }

        &.--bg-white {
            background: var(--color-white);
        }

        p {
            margin-top: 1rem;

            &.--align-center {
                text-align: center;
            }

            &.--align-left {
                text-align: left;
            }
        }
    }

    &__link {
        display: block;
    }

    &__heading {
        margin-top: 1rem;

        span {
            font-size: 1.2rem;
            text-align: center;
            display: block;
        }
    }
}