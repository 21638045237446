@mixin setTransitionOpt($dur: 0.2s, $type: 1, $proprieties...) {
  $easing: var(--easing);

  @if $type ==1 {
    $easing: var(--easing);
  } @else if $type ==2 {
    $easing: var(--reveal-easing);
  } @else {
    $easing: $type;
  }

  $tList: ();

  @each $prop in $proprieties {
    $tList: append($tList, #{$prop $dur $easing}, comma);
  };

  $wList: ();

  @each $prop in $proprieties {
    $wList: append($wList, #{$prop}, comma);
  };

  transition: #{$tList};
  will-change: #{$wList};
}

@mixin setTransition($dur: 0.2s, $type: 1, $proprieties... ) {
  $easing: var(--easing);
  $delay: var(--transition-delay, 0s);

  @if $type ==1 {
    $easing: var(--easing);
  }

  @else if $type ==2 {
    $easing: var(--reveal-easing);
  }

  @else if $type ==3 {
    $easing: var(--lazy-easing);
  }

  @else {
    $easing: $type;
  }

  $tList: (
  );

@each $prop in $proprieties {
  $tList: append($tList, #{$prop $dur $delay $easing}, comma);
}

transition: #{$tList};

}

@mixin trns($dur: 0.2s, $type: 1) {
  $easing: var(--easing);
  $delay: var(--transition-delay, 0s);

  @if $type ==1 {
    $easing: var(--easing);
  }

  @else if $type ==2 {
    $easing: var(--reveal-easing);
  }

  @else if $type ==3 {
    $easing: var(--lazy-easing);
  }

  @else {
    $easing: $type;
  }

  transition: all #{$dur $delay $easing};

}