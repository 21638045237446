@use "../../abstracts" as *;

.product {
  display: grid;
  position: relative;
  gap: 15px;
  --active: 0;
  --inactive: 1;
  &:focus-within {
    --active: 1;
    --inactive: 0;
  }
  @include mouse {
    &:hover {
      --active: 1;
      --inactive: 0;
      .product__image {
        box-shadow: 0 0 0 4px rgba($color-gray-1, 20%),
          0 5px 10px rgba($color-gray-1, 40%);
      }
    }
  }
  &__image {
    @include setTransition(0.2s, 1, box-shadow);
    position: relative;
    overflow: hidden;
    border-radius: var(--ui-roundness, 0px);
  }
  &__link {
    @include setInset;
    z-index: 3;
    &:focus-visible {
      outline: none;
    }
  }

  &__link:active ~ &__image,
  &__link:focus-within ~ &__image,
  &__link:focus ~ &__image {
    @include setTransition(0.1s, 1, box-shadow);
    box-shadow: 0 0 0 3px var(--color-gray-1),
      0 5px 10px rgba($color-gray-1, 20%);
  }

  &__overlay {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    padding: 10px;
    gap: 6px;
    display: grid;
  }
  &__img {
    &:before {
      z-index: -1;
      content: "";
      position: absolute;
      width: calc(100% + 20px);
      height: 60px;
      top: -10px;
      opacity: 0.2;
      @include trns;
      left: -10px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), #0000 70%);
    }
    img {
      background-color: rgba(0, 0, 0, 0.048);
    }
  }
  &__title {
    font-weight: 500;
    line-height: 1.5em;
    font-size: .9125rem;
    text-align: center;
  }
  &__actions {
    z-index: 5;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    pointer-events: none;
    opacity: var(--active);
    transform: translateY(calc(var(--inactive) * 10px));
    gap: 8px;
    @include trns;
    @include xs {
      --ui-btn-height: 38px;
    }
    @include xxs {
      --ui-btn-height: 32px;
    }
    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      left: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.247), #0000 80%);
      @include touch {
        display: none;
      }
    }
    @include touch {
      padding: 10px;
      bottom: unset;
      top: 0px;
      opacity: 1;
      transform: none;
      justify-content: flex-end;
      .dialog-trigger {
        display: none;
      }
    }
    .btn {
      pointer-events: all;
      outline: none;
      &::before {
        content: '';
        pointer-events: none;
        @include setInset(-1px);
        border-radius: calc(var(--ui-roundness, 0) - var(--inset-value));
      }
      &:focus-visible::before {
        box-shadow: 0 0 0 2px var(--color-base), 0 0 0 3px var(--color-white);
      }
      .css-icon.--loader {
        --size: 18px;
        .glow {
          display: none;
        }
      }
    }
    .add-to-favorites {
      &.--loading:not(.--loaded) {
        .btn__icon {
          opacity: 0;
        }
        .btn__overlay {
          opacity: 1;
        }
      }
    }
  }
  &__favorites {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    padding: 8px;
    .btn {
      box-shadow: var(--ui-box-shadow);
      pointer-events: all;
      outline: none;
      &::before {
        content: '';
        pointer-events: none;
        @include setInset(-1px);
        border-radius: calc(var(--ui-roundness, 0) - var(--inset-value));
      }
      &:focus-visible::before {
        box-shadow: 0 0 0 2px var(--color-base), 0 0 0 3px var(--color-white);
      }
    }
    .--loading:not(.--loaded) {
      .btn__icon {
        opacity: 0;
      }
      .btn__overlay {
        opacity: 1;
      }
    }
    @include trns;
  }
  &__badges {
    display: flex;
    flex-flow: wrap;
    gap: 8px;
    justify-content: center;
    margin-bottom: -4px;
  }
}
