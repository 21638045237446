@use "../abstracts/mixins" as *;

.data-rights{
    &__heading{
        padding-bottom: 0;
    }
    &__content{
        max-width: 750px;
        .--submit{
            margin: 1rem auto 0;
        }
        .field.--select input{
            text-overflow: ellipsis;
        }
        @include xs{
            max-width: var(--container-gaps);
            .form__wrapper .layout{
                --columns: 1;
            }
            .form__wrapper .layout__item{
                grid-column: 1/1;
            }
        }
    }
}