@use "../abstracts/" as *;

.load-more{
  display: grid;
  align-items: center;
  justify-content: center;
  max-height: 82px;
  opacity: 1;
  @include trns(.2s, 2);
  &.--hidden{
    overflow: hidden;
    max-height: 0px;
    opacity: 0;
  }
  &__trigger{
    margin-top: calc(var(--layout-spacing) * 1.25);
  }
}