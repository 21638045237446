@use '../abstracts' as *;

.text-banner{
    padding: 0;
    &__content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--spacing) 0;
      text-align: center;
      max-width: 120ch;
      margin: auto;
      &.--left-aligned{
        align-items: flex-start;
        text-align: left;
      }
      &.--right-aligned{
        align-items: flex-end;
        text-align: right;
      }
      @include xs{
        &.--left-aligned,
        &.--right-aligned{
          align-items: center;
          text-align: center;
        }
      }
    }
}