@use '../abstracts/mixins/breakpoints.scss' as *;

.inline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: calc(var(--gap) * 1.5);

    &.--small {
        gap: calc(var(--gap) * 1.25);
    }

    &.--smaller {
        gap: calc(var(--gap) * 1);
    }

    &.--smallest {
        gap: calc(var(--gap) * .75);
    }

    &.--fill {
        justify-content: stretch;

        &>* {
            flex: 1;
        }
    }

    &.--grow {
        &>* {
            flex-grow: 1;

            .btn {
                width: 100%;
            }
        }
    }

    &.--align-center {
        align-items: center;
    }

    &.--align-start {
        align-items: start;
    }

    &.--justify-left,
    &.--start {
        justify-content: flex-start;
    }

    &.--justify-center {
        justify-content: center;
    }

    &.--justify-right,
    &.--end {
        justify-content: flex-end;
    }

    h2 {
        margin-bottom: 0;
    }


    &.--wrap-xs {
        flex-flow: row;
    }

    &.--wrap {
        flex-flow: wrap;
        width: 100%;
        align-items: flex-start;
    }

    @include xs {
        &.--grow-xs {
            &>* {
                flex-grow: 1;
                width: auto;

                .btn {
                    width: 100%;
                }
            }
        }

        flex-flow: column;

        &.--remain-inline {
            flex-flow: row;
        }

        &.--wrap-xs {
            flex-flow: wrap;
            width: 100%;
            align-items: flex-start;
        }

        &.--justify-center-xs {
            justify-content: center;
        }
    }

    &__item {
        &.--equal {
            flex: 1;
        }

        &.--text-separator {
            width: 100% !important;
            position: relative;
            text-align: center;
        }

        &.--push-right {
            margin-right: auto;
        }

        &.--birthday-field {
            max-width: 35%;
        }

        @include xs {
            text-align: center;
            width: 100%;

            &.--right {
                text-align: right;
            }

            .inline.--remain-inline & {
                width: auto;
            }

            .inline.--wrap>& {
                width: auto;
            }
        }
    }

    &.--center-buttons {
        justify-content: center;

        .inline_item {
            margin: 0 1rem;

            @include xs {
                margin: 0 auto;
            }
        }
    }

}