@use "./../abstracts" as *;

.image-content{
    padding: calc(var(--spacing) * 0.5) 0;
    a {
        outline-offset: 2px;
        text-decoration: none;
    }
    a:hover{
        text-decoration: underline;
    }
    &__main p {
        padding: 15px 15px 0;
        text-align: center;
    }
}
