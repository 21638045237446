@use "./../abstracts" as *;

.sr-only,
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-holder {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  overflow: hidden;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
}

.video-playpause {
  padding: 0;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  padding-top: 1px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  transform: scale(1);
  color: var(--color-white);
  background-color: #{rgba(var(--color-gray-1), .16)};
  border: none;
  box-shadow: 0 0 0 0px #0000, 0 0 0 2px var(--color-white);
  pointer-events: all;
  opacity: 1;
  outline: none;
  z-index: 10;
  @include setBlur(5px, #0002);
  @include trns;
  &::before {
    content: '';
    @include setInset(-2px);
    border-radius: 50%;
    pointer-events: none;
  }
  &:focus-visible::before {
    box-shadow: 0 0 0 2px var(--color-base), 0 0 0 3px var(--color-white);
  }
  @include mouse {
    &:hover {
      background-color: var(--color-white);
      color: var(--color-gray-1);
    }
  }
  &:active {
    @include trns(0s);
    color: var(--color-gray-1);
    background-color: #{rgba($color-white, 0.8)};
  }

  .css-icon {
    --weight: 3px;
    --icon-offset: 3px;
  }
}