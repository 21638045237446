@use "../../abstracts" as *;

.conditional-fields {
  &__inner {
    position: relative;
  }

  &.--spacing {
    padding: 0 1rem;
    margin: 0 -1rem;
  }
}

.conditional-field {
  &__inner {
    .wizard-panel__header {
      margin-top: 0;
    }

    padding-top: var(--fieldset-spacing);

    &.--bottom-spacing {
      padding-bottom: var(--fieldset-spacing);
    }
    &.--bottom-half-spacing {
      padding-bottom: calc(0.5 * var(--fieldset-spacing));
    }
    &.--bottom-quarter-spacing {
      padding-bottom: calc(0.25 * var(--fieldset-spacing));
    }
    &.--half-spacing {
      padding-top: calc(0.5 * var(--fieldset-spacing));
    }
    &.--third-spacing {
      padding-top: calc(0.33 * var(--fieldset-spacing));
    }
    &.--quarter-spacing {
      padding-top: calc(0.25 * var(--fieldset-spacing));
    }
    &.--no-spacing {
      padding-top: 0px;
    }
    &.--layout-spacing {
      padding-top: var(--layout-spacing, 1rem);
    }
  }

  display: none;

  &.--active {
    display: block !important;
  }

  &.--deactivating {
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
  }
}

