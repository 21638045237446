@mixin setStagger(
  $els: 10,
  $delay: 0.1s,
  $col: 1,
  $col-delay: 1s,
  $initial: 0s,
  $reverse: 0
  ) {
  @if $col == 1 {
    @for $i from 1 through $els {
      @if $reverse == 0 {
        &:nth-child(#{$i}) {
          --a-scss-delay: calc(#{$delay} * #{$i} + #{$initial});
          animation-delay: var(--a-scss-delay) !important;
        }
      } @else {
        &:nth-last-child(#{$i}) {
          --a-scss-delay: calc(#{$delay} * #{$i} + #{$initial});
          animation-delay: var(--a-scss-delay) !important;
        }
      }
    }
  } @else if $col != 1 {
    $rows: $els / $col;

    $inter-delay: ceil($rows);
    @if $col-delay != 1 {
      $inter-delay: $col-delay;
    }

    @for $i from 1 through ceil($rows) {
      @for $j from 1 through $col {
        @if $reverse == 0 {
          &:nth-child(#{$j + $col * ($i - 1)}) {
            --a-scss-delay: calc(
              #{$delay} * #{$i + $inter-delay * ($j - 1)} + #{$initial}
            );
            animation-delay: var(--a-scss-delay) !important;
          }
        } @else {
          &:nth-last-child(#{$j + $col * ($i - 1)}) {
            --a-scss-delay: calc(
              #{$delay} * #{$i + $inter-delay * ($j - 1)} + #{$initial}
            );
            animation-delay: var(--a-scss-delay) !important;
          }
        }
      }
    }
  }
}

@mixin revealMe(
  $offset: 0.4s,
  $trigger: '.--reveal-start',
  $name: "xFadeFromLeft",
  $duration: 0.4s,
  $easing: "var(--reveal-easing)"
) {
  opacity: 0;
  will-change: opacity, transform;
  #{$trigger} & {
    animation: #{$name} #{$duration} #{$offset} #{$easing} forwards;
  }
}

@mixin revealChildren(
  $els: 10,
  $stagger: 0.1s,
  $offset: 0.4s,
  $trigger: '',
  $name: "fadeInBottom",
  $duration: 0.4s,
  $easing: "var(--reveal-easing)"
) {
  & > * {
    opacity: 0;
    will-change: opacity, transform;
    @include setStagger($els, $stagger, 1, 1s, $offset);
    @if $trigger == '' {
      #{$trigger} & {
        animation: #{$name} #{$duration} #{$easing} forwards;
      }
    } @else {
      animation: #{$name} #{$duration} #{$easing} forwards;
    }
  }
}