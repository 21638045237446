@use "./../abstracts" as *;

.gift-upsell {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  --ui-btn-height: 22px;
  --ui-btn-width: 22px;

  .btn,
  .btn-inline-action {
    padding: 4px !important;
  }

  @include sm {
    height: unset;
  }

  &__image {
    display: flex;

    svg {
      height: 100%;
      object-fit: cover;
      object-position: right;
      width: 100%;
      max-width: 30px;
    }
  }

  &__header {
    display: flex;
    gap: 5px;
  }

  &__title {
    font-weight: 500;
    font-size: 0.9rem;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 10px;
    --ui-btn-height: 18px;
    --ui-btn-width: 18px;
  }

  &.--border:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--ui-divider-color);

    @include xs {
      font-size: .938rem;
    }
  }
}

.gift-attached {
  height: 100%;
  border-radius: var(--ui-roundness);
  box-shadow: inset 0 0 0 1px var(--color-gray-6);
  padding: 1rem;
  align-items: center;
  position: relative;

  &__grid {
    height: 100%;
    display: flex;
    gap: 14px;
    position: relative;
    align-items: start;
    max-width: 354px;
  }

  &__image {
    display: flex;
    box-shadow: 0 4px 24px rgba(51, 51, 51, 0.1);
    border-radius: var(--ui-roundness, 0);

    &.--square {
      width: 50px;
      height: 50px;

      img.--square {
        aspect-ratio: 1/1;
      }
    }

    &-inner {
      .product-page & {
        width: 85px;
      }
      .product-page.pickup-page & {
        width: 135px;
      }
    }
  }

  &__price {
    position: absolute;
    top: -6px;
    right: -6px;
    font-size: 10px;
    font-weight: 500;
    color: var(--color-white);
    background: var(--color-gray-1);
    border-radius: 10px;
    padding: 3px 10px;
    box-shadow: 0px 2.71px 6.78px 0px rgba(0, 0, 0, 0.25);
  }

  &__amount {
    position: absolute;
    bottom: .25rem;
    right: .5rem;
    color: var(--color-white);
    font-weight: 700;
    font-size: 1.25rem;

    &.--small {
      font-size: 0.9rem;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    align-self: center;

    .gift-attached__title {
      margin-bottom: 4px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: .57825rem;
      color: var(--color-primary-lighter);
    }
  }

  &__description {
    font-size: 0.825rem;
    line-height: 1.3;
    letter-spacing: .2px;
    font-weight: 500;
  }

  &.--custom-song {
    .gift-attached__image {
      display: grid;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .gift-attached__grid {
      display: flex;
      align-items: center;
    }
  }
}

.cash-gift-send {
  &__list {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  &__name {
    font-weight: 500;
    margin-bottom: .5rem;
  }

  &__detail {
    display: flex;
    gap: 10px;
    align-items: center;
    width: fit-content;
    border-bottom: 1px var(--ui-divider-color) solid;
    padding-bottom: 10px;

    @include xs {
      flex-flow: column;
      align-items: flex-start;
    }

    .field {
      input {
        padding-left: 20px;
        width: 100px;
        font-weight: 500;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" fill="none" viewBox="0 0 9 16"><path fill="%23333" d="M8.727 10.744c0 1.546-1.19 3.063-3.33 3.3l.014 1.472H4.222l-.015-1.471C2.483 13.866 1.16 12.9.58 11.309l1.531-.803c.401 1.041 1.16 1.68 2.126 1.859V8.9c-1.056-.342-1.858-.744-2.394-1.234C1.25 7.16.951 6.477.951 5.57c0-1.68 1.383-3.048 3.256-3.286l.015-1.53h1.19l-.015 1.531c1.694.208 2.72 1.249 3.166 2.409l-1.501.817c-.328-.817-.878-1.367-1.68-1.546V7.37c1.115.341 1.962.758 2.527 1.323.52.505.818 1.16.818 2.051ZM2.81 5.467c0 .743.55 1.174 1.427 1.53V3.936C3.39 4.1 2.81 4.694 2.81 5.467Zm2.557 3.82v3.093c.877-.164 1.486-.714 1.486-1.532 0-.698-.46-1.144-1.486-1.56Z"/></svg>');
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: 8px 12px;
      }
    }
  }

  &__subtotal {
    display: flex;
    justify-content: space-between;
  }

  &__checkout {
    border-top: 1px var(--ui-divider-color) solid;
    display: flex;
    padding: .5rem 0;
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.cash-gift {
  &__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 1rem;
  }

  &__content {
    position: relative;
    max-width: 500px;
  }

  &__amount {
    position: absolute;
    right: 1rem;
    bottom: .5rem;
    color: var(--color-white);
    font-size: 4rem;
    font-weight: 700;
  }

  &__amount-options {
    display: flex;
    gap: 1rem;
    font-size: .875rem;
    justify-content: center;
    margin-top: 1rem;

    &.--multiple {
      margin-top: 0;

      .cash-amount-option {
        --ui-btn-spacing: 1.25rem;
        font-size: .875rem;
        min-height: 36px;
        max-height: 36px;
      }
    }

    .cash-amount-option {
      border-radius: 50px;
      letter-spacing: 0;
      font-size: 1rem;
      font-weight: 500;

      &.--selected {
        background-color: #333;
        color: var(--color-white);
      }
    }

    @include xs {
      gap: .5rem;
    }
  }

  &__form {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    align-items: center;
  }

  &__phone {
    max-width: 600px;
    padding-top: 2.2rem;
    margin-top: 2rem;
    border-top: 1px solid var(--ui-divider-color);
  }

  &__submit-button {
    width: 300px;
  }


}

.gift-container {
  &.cash-gift {
    height: auto;
  }
}

.layout {
  @include xs {
    &.gift-selection {
      display: flex;
      flex-direction: column;

      .--gift {
        flex: 1;

        .gift-upsell {
          display: flex;
          flex-flow: column;
          padding: .5rem;
          grid-template-columns: unset;

          &__image {
            margin: 0;
            height: auto;
            width: 80%;
            position: relative;

            .lazy-load {
              position: relative;

              &.--mobile {
                display: block;
              }

              &.--desktop {
                display: none;
              }
            }
          }

          &__content {
            align-items: center;
          }

          button {
            font-size: .938rem;
          }

          .checkbox__text {
            text-indent: -9999px;
            margin: 0;
          }
        }
      }
    }
  }
}

.gift-option {
  display: flex;
  flex-flow: column;
  border-radius: var(--ui-roundness);
  background-color: #f6f6f6;
  box-shadow: inset 0 0 0 1px var(--color-gray-6);
  align-items: center;
  gap: 1rem;
  margin: 0px auto;
  position: relative;
  padding: .5rem;
  text-align: center;
  width: 200px;

  &__content {
    .checkbox__input {
      margin: 0px auto;
    }
  }

  &__image {
    display: block;
    max-width: 150px;
    height: calc(100% + 2.4rem);
  }

  &__header {
    font-weight: 500;
  }

  &.--selected {
    box-shadow: inset 0 0 0 1px var(--color-gray-4);
  }
}

.gift-dialog {
  &__list {
    --global-inter-content-spacing: 2rem;
  }

  .form {
    width: auto;
  }

  &__send-button {
    .--without-gift {
      display: block;
    }

    .--with-gift {
      display: none;
    }

    &.--has-gift {
      .--without-gift {
        display: none;
      }

      .--with-gift {
        display: block;
      }
    }
  }
}

.cash-gift__container {
  .field {
    &__wrapper {
      display: block;
      margin: 10px auto 0px auto;
      width: 300px;
    }

    &__output {
      display: block;
      margin: 0px auto 10px auto;
      width: 300px;
    }
  }
}

.--gift-text {
  margin-top: 1rem;
}

@include xs {
  .cash-gift {
    width: 100%;
  }

  .select-cash-gift {
    --offset-gap: 0;

    &__panel {
      margin: 0 20px;
    }

    .cash-gift-columns {
      padding: 0 20px;
    }
  }
}

.gift-status-badge {
  --badge-color: rgba(0, 83, 177, 1);
  --badge-bg: rgba(1, 120, 253, 0.12);

  &.--neutral {
    --badge-color: rgba(50, 50, 50, 1);
    --badge-bg: rgba(100, 100, 100, 0.12);
  }

  &.--negative {
    --badge-color: rgba(173, 0, 41, 1);
    --badge-bg: rgba(213, 0, 50, 0.12);
  }

  &.--positive {
    --badge-color: rgba(3, 115, 17, 1);
    --badge-bg: rgba(0, 169, 22, 0.12);
  }

  font-weight: 500;
  color: var(--badge-color);
  background: var(--badge-bg);
  padding: 4px 8px 4px;
  line-height: 1.1;
  border-radius: var(--ui-roundness);
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-top: -2px;
  text-align: center;
  text-transform: capitalize;

  .table.--table-loading & {
    opacity: 0;
  }

  &:not(:last-child) {
    margin-bottom: calc(var(--vertical-spacing, 1rem) * .7 * var(--scale));
  }

  h1 &,
  h2 &,
  h3 & {
    transform: translateY(-1px);
    margin-bottom: 0 !important;
  }
}

#gift_cards, #advents, #cash_gift, #custom_song, #virgin_experience_gift {
  &.--table-loading {
    .table__list {
      .table-item {
        --table-item-spacing: 1rem;

        &__greeting {
          &:before {
            display: none;
          }

          .giftcard-greeting__wrapper {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 0%;
              height: 100%;
              width: 100%;
              border-radius: 7px;
              @include loader(#efefef, #f6f6f6);
            }
          }

          .lazy-load {
            border-radius: 7px;
          }
        }

        &__subs:before {
          width: calc(40% + 40% * var(--random-cell, 0) * var(--random-list, 0));
        }

        &__subs-value {
          opacity: 0;
        }
      }
    }
  }

  .table__list {
    .table-item {
      &__wrapper {
        @include randomListGenerator(6);
      }

      &__subs {
        font-size: 14px;
        line-height: 18px;
        gap: 0px 7px;
        align-items: center;
        overflow: visible;
      }

      &__subs-label {
        font-size: 12px;
        align-self: baseline;
      }
    }
  }

  @include xs {
    .table-item.--border {
      border-radius: 14px;

      .table-item__content {
        display: grid;
        grid-template-columns: .3fr minmax(1px, 1fr);
        gap: 1rem;

        .table-item__greeting {
          width: 100%;
          margin: 0;
          max-width: 120px;
        }

        .table-item__subs-value.--truncate {
          width: fit-content;
          &.recipient {
            font-weight: 400;
            color: var(--color-gray-2);
          }
        }
      }
    }
  }

  @include max(385) {
    .table-item.--border .table-item__content {
      grid-template-columns: minmax(1px, 1fr);
    }
  }
}
