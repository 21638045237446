@use '../../abstracts/' as *;

.drawers {
  position: fixed;
  left: 0;
  pointer-events: none;
  width: 100%;
  padding-top: var(--nav-height);
  top: calc(var(--header-mobile-offset, 0px));
  height: calc(100% - var(--header-mobile-offset, 0px));
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  overflow: hidden;

  .header-mobile.--drawer-is-active & {
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: all;
  }
}

.drawer {
  opacity: 0;
  pointer-events: none;
  display: grid;
  min-height: 100%;
  @include trns(.3s, 2);

  &.--active {
    opacity: 1;
    pointer-events: all;
  }

  &.--hidden {
    display: none;
  }

  &__wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: var(--header-mobile-width);
    display: grid;
    position: relative;
  }

  &__inner {
    width: var(--header-mobile-width);
    max-width: var(--header-mobile-menu-max-width);
    display: grid;
    align-content: flex-start;
    gap: 1rem;
    padding: 0rem var(--offset-gap) 3rem;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  &__content {
    display: grid;
    align-content: flex-start;
    gap: 1rem;
    transform: translateX(50%);
    @include trns(.3s, 2);

    .drawer.--active & {
      transform: translateX(0%);
    }
  }

  &__list {
    padding-left: 40px;
  }

  &__back-holder {
    margin-left: -12px;
    margin-right: .5rem;
    height: 30px;
    display: flex;
    align-items: center;

    // padding-right: 0.9rem;
    .css-icon {
      --scale: 1;
      --tail-scale: 1;
      --arrow-scale: .45;
    }

  }

  &__header {
    position: sticky;
    top: 0rem;
    z-index: 1;
    padding: 3rem 0 .9rem;
    line-height: 30px;

    @media (max-height: 540px) {
      padding-top: 1.2rem;
    }

    background: var(--color-white);
    box-shadow: -10px 0px 0 0 var(--color-white);
    box-shadow: 10px 0px 0 0 var(--color-white);
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .4px;

    &:after {
      content: '';
      width: calc(100% - 40px + var(--offset-gap));
      height: 1px;
      bottom: 0;
      left: 40px;
      background-color: var(--ui-divider-color);
      position: absolute;
    }
  }

  &__link {
    display: flex;
    text-decoration: none;
    line-height: 1.4em;
    background: transparent;
    border: none;
    gap: 8px;
    // justify-content: space-between;
    align-items: center;
    font-size: .875rem;
    width: calc(100% + var(--offset-gap));
    margin-left: calc(var(--offset-gap) * -1);
    letter-spacing: .4px;
    padding: 8px var(--offset-gap) 7px;
    position: relative;

    .css-icon.--arrow {
      --scale: 0.5;
      --icon-offset: 1px;
    }

    &:before {
      content: '';
      @include setInset(0px);
      pointer-events: none;
      border-radius: var(--ui-roundness, 0px);
      background-color: var(--color-primary-alpha-10);
      opacity: 0;
      @include trns;
    }

    &:active {
      @include trns(0s);
      color: var(--color-primary-lighter);

      &:before {
        @include trns(0s);
        opacity: 1;
      }
    }

    &:focus {
      color: var(--color-primary-lighter);
    }
    &.--highlighted{
      color: var(--color-primary-lighter);
    }
  }

  &__back {
    &:focus::after {
      opacity: 0;
    }

    &:hover::after {
      opacity: .08;
    }
  }

  &__backdrop {
    position: absolute;
    height: 100%;
    width: calc(100% - var(--header-mobile-menu-max-width) + 50vw - 50%);
    left: var(--header-mobile-menu-max-width);
    top: 0;
    z-index: 0;
  }
}