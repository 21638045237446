@use '../abstracts' as *;

.top-hat{
  height: var(--banner-height, auto);
  overflow: hidden;
  @include trns(.2s, 2);
}

.promo-banner{
  min-height: 100px;
  background-color: #2b4156;
  background-image: linear-gradient(3deg, #00000073, #00000014), url("https://images.contentstack.io/v3/assets/blt11858c7f9090bc6a/bltb364894288a3cc4f/634717a9b3edf9106f50382e/JL23C006_Halloween_Ecard_Homepage_Banner[1].jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  &__content{
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      font-weight: 300;
      letter-spacing: .5px;
      // opacity: .85;
    }
  }
  &__title{
    letter-spacing: .5px;
    font-size: 1.325rem;
    font-weight: 500;
  }
  .btn-group{
    align-content: flex-end;
    justify-items: flex-end;
  }
}