@use "../abstracts/mixins" as *;

.create-your-account{
    overflow: visible;
    &__heading{
        padding-bottom: 0;
    }
    &__content{
        .--lighter{
            --link-color: var(--color-jet);
            --link-hover-color: var(--color-jet);
        }
        @include xs{
            max-width: var(--container-gaps);
            #register-form .form__wrapper >.layout{
                grid-template-areas:
                "email"
                "password"
                "fname"
                "lname"
                "birthday"
                "marketing";
            }
            #register-form .form__wrapper .--flexible{
                flex-direction: column;
                gap: 2rem;
            }
        }
    }
}