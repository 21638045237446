@use "../abstracts" as *;

.aside {
  position: relative;
  outline: none !important;

  &__wrapper {
    position: relative;
    z-index: 20;
  }
}

@include sm {
  .aside {
    --aside-spacing-top-offset: 30px;
    --aside-speed: .2s;
    --aside-offset: 20px;
    --aside-scale: .9;
    --aside-spacing: 2.8rem;

    @include xxs {
      --aside-spacing: 2rem;
    }

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: grid;
    padding: 0;
    border: none;
    box-shadow: none;
    background: transparent;
    align-items: stretch;
    max-width: 100%;
    max-height: 100%;
    opacity: 0;
    z-index: 150;
    pointer-events: none;
    @include trns(var(--aside-speed), 2);

    &[open] {
      opacity: 1;
      pointer-events: all;
    }

    &__backdrop {
      pointer-events: none;
      opacity: 0;
      @include setInset;
      background: var(--ui-backdrop);
      @include trns(var(--aside-speed), 2);

      .aside[open] & {
        pointer-events: all;
        opacity: 1;
      }
    }

    &__wrapper {
      position: relative;
      overflow-y: hidden;
      overflow-x: hidden;
      display: grid;
      height: var(--client-height, 100vh);
      justify-content: flex-start;
      align-items: stretch;
      scroll-behavior: smooth;
      scroll-padding-top: 10rem;
      scroll-padding-bottom: 10rem;

      .aside[open] & {
        overflow-y: auto;
      }

      .aside.--right & {
        justify-content: flex-end;
      }

      .aside[open].--with-scrollbar & {
        overflow-y: scroll;
      }
    }

    &__header {
      padding-bottom: .7125rem;
      margin-bottom: .7125rem;
      border-bottom: 1px var(--color-gray-4) solid;
    }

    &__content {
      width: 440px;
      max-width: calc(var(--client-width, 100vw) - var(--offset-gap) * 2);

      @include max(360) {
        max-width: calc(var(--client-width, 100vw) - var(--offset-gap) * 1.25);
      }

      position: relative;
      padding: var(--aside-spacing);
      padding-top: calc(var(--aside-spacing) + var(--aside-spacing-top-offset, 0px));
      background: var(--color-white);
      will-change: transform;
      @include trns(var(--aside-speed), 2);
      transform: translateX(calc(var(--aside-offset) * -2));

      .aside[open] & {
        transform: translateX(0px);
      }

      .aside.--right & {
        transform: translateX(calc(var(--aside-offset) * 2));
      }

      .aside.--right[open] & {
        transform: translateX(0px);
      }

      &>* {
        &:not(:last-child) {
          margin-bottom: var(--ui-vertical-spacing, 1rem);
        }
      }
    }

    &__overlay-btn {
      position: absolute;
      top: 1em;
      right: 1em;

      @include xxs {
        top: .5em;
        right: .5em;
      }

      .css-icon {
        --size: 36px;
        --scale: 0.76;
      }
    }

    &.--sidelined {
      z-index: -1;
    }
  }

  //Mobile trigger button for aside
  .mobile-aside-trigger {
    padding: calc(var(--spacing) * 0.5) var(--offset-gap) 0;
    margin-bottom: calc(var(--spacing) * 0.5);

    button {
      margin: 0 auto;
    }
  }
}

.aside-trigger {
  * {
    pointer-events: none;
  }
}