section {
    &.ribbon {
        --spacing: 2rem;
        margin-bottom: calc(var(--spacing) * 1);

        &:last-of-type {
            --s-ratio-bot: var(--s-ratio-bot-override, 1);
            margin-bottom: calc(var(--spacing) * 2.5);
        }

        p.ribbon__text {
            font-size: 1.5rem;
            font-weight: 500;
        }

        &.--vertical-spacing-0 {
            padding: 0;
            margin: 0;
        }
    }
}