@use '../abstracts' as *;

.carousel {
  --slides-per-view: 4;
  --slide-gap: calc(var(--gap) * 2);
  --slider-btn-size: 34px;
  --slider-bg: var(--color-white);
  --slider-btn-bg: var(--color-gray-1);
  --slider-btn-hover-bg: var(--color-primary-lighter);
  --slider-btn-color: var(--color-white);
  --slider-btn-hover-color: var(--color-white);
  --slider-btn-active-color: var(--color-white);
  --slider-btn-active-bg: var(--color-primary);
  --arrows-offset-ratio: 0;
  --pagination-bullet-width: 6px;
  --pagination-bullet-height: 6px;
  --arrows-opacity: 1;
  --slider-roundness: var(--ui-roundness, 0px);
  display: grid;
  width: 100%;
  position: relative;

  @include sm {
    --slides-per-view: 3;
  }

  @include xs {
    --slides-per-view: 2;
  }

  @include xxs {
    --slide-width: 250px;
  }

  &.--category-highlights {
    --slides-per-view: 8;
    --arrow-offset-ratio: 1.6;
    --slider-bg: transparent;
    --slider-button-scale: 1.15;

    @include sm {
      --slides-per-view: 5;
      --arrow-offset-ratio: 1.4;
    }

    @include xs {
      --slides-per-view: 4;
      --arrow-offset-ratio: 1.2;
    }

    @include max(500) {
      --slides-per-view: 3;
      --slide-width: unset;
    }
  }

  &.--product-thumbs {
    --slides-per-view: 4;
    --arrow-offset-ratio: 1;
    --slider-bg: var(--color-white);
    --slider-button-scale: 1;
    --slide-gap: 2rem;

    &.--columns-3 {
      --slides-per-view: 3;
    }

    @include sm {
      --slide-gap: var(--offset-gap);
      --slides-per-view: 4;
      --arrow-offset-ratio: 1;

      &.--columns-3 {
        --slides-per-view: 2;
      }
    }

    @include xs {
      --slides-per-view: 2;
      --arrow-offset-ratio: 1;
    }

    @include max(500) {
      --slides-per-view: 2;
      --slide-width: unset;
    }

    &.layout {
      --layout-spacing: 1rem;
    }
  }

  &.--content {
    --slides-per-view: 4;
    --arrow-offset-ratio: 1;

    .carousel__button {
      opacity: 1;

      &.--disabled {
        opacity: 0;
      }
    }

    @include md {
      --slides-per-view: 3;
    }

    @include xs {
      --slides-per-view: 2;
      --slide-width: unset;
    }

    @include xxs {
      --slides-per-view: 1;
      --slide-width: unset;
    }
  }

  &.--single-image {
    --slides-per-view: 1;
    --arrows-opacity: 1;
    --arrow-offset-ratio: 1.2;
    --slider-roundness: 5px;
    --slide-width: 100%;

    .carousel__slider {
      overflow: hidden;
    }

    .carousel__button {
      opacity: 1;

      &.--disabled {
        opacity: 0;
      }
    }
  }

  &.--product-thumb {
    --slides-per-view: 1;
    --arrow-offset-ratio: 1;
    --slider-bg: var(--color-white);
    --slider-button-scale: 1;
    --slide-gap: 2rem;
    --slide-width: 100%;

    .carousel__slider {
      overflow: hidden;
    }
  }

  &.--reviews {
    --slides-per-view: 1;
    --arrow-offset-ratio: 1;
    --slider-bg: var(--color-white);
    --slider-btn-bg: transparent;
    --slider-btn-color: var(--color-gray-3);
    --slider-btn-hover-bg: var(--color-white);
    --slider-btn-hover-color: var(--color-gray-2);
    --slider-btn-active-bg: var(--color-gray-7);
    --slider-btn-active-color: var(--color-gray-1);
    --slider-button-scale: 1;
    --slide-gap: 2rem;

    @include sm {
      --slide-gap: var(--offset-gap);
      --slides-per-view: 1;
      --arrow-offset-ratio: 1;
    }

    @include xs {
      --slides-per-view: 1;
      --arrow-offset-ratio: 1;
    }

    @include max(500) {
      --slides-per-view: 1;
      --slide-width: unset;
    }

    &.layout {
      --layout-spacing: 1rem;
    }

    .carousel__button {
      margin: 0 1rem;
      box-shadow: 0 0 0 2px var(--slider-btn-color);

      @include mouse {
        &:hover {
          box-shadow: 0 0 0 2px var(--slider-btn-hover-color);
        }
      }

      &:active {
        box-shadow: 0 0 0 2px var(--slider-btn-active-color);
      }
    }

    .rating {
      text-align: center;
    }

    .content {
      --container-width: 500px;
      padding-top: 1rem;

      @include xs {
        padding: 2rem;
      }
    }

  }

  .--contain-width {
    --container-width: 750px;
    max-width: var(--container-width);
    margin: auto;
  }

  &.--darker {
    --arrows-opacity: 0;
  }

  &.--lighter {
    --slider-btn-bg: transparent;
    --slider-btn-hover-bg: #{rgba($color-gray-1, 0.08)};
    --slider-btn-active-bg: #{rgba($color-gray-1, 0.16)};
    --slider-btn-color: var(--color-gray-2);
    --slider-btn-hover-color: var(--color-gray-1);
    --slider-btn-active-color: var(--color-gray-1);
  }

  &.--billboard-banner {
    --slider-btn-bg: #{rgba($color-gray-1, .16)} !important;
    --slider-btn-color: var(--color-gray-2);
    --slider-btn-active-bg: var(--color-gray-7);
    --slider-btn-active-color: var(--color-gray-1);
    --slider-btn-size: 38px;
    --slides-per-view: 1;
    --arrow-offset-ratio: 1.2;
    --slider-roundness: var(--ui-roundness-lg, 0);
    margin-top: calc(var(--gap) * 1);
    margin-bottom: calc(var(--gap) * 1);
    border-radius: var(--slider-roundness, 0);
    overflow: hidden;

    @include sm {
      margin-top: 0;
      margin-bottom: 0;
      --slider-roundness: 0px;
    }


    .lazy-load-active {
      border-radius: var(--slider-roundness, 0);
    }

    .carousel__overlay {
      padding: var(--offset-gap);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include sm {
        padding: var(--offset-gap);
      }

      @include xs {
        height: var(--slide-height, calc(100% - var(--gap) * 2));
      }
    }

    .carousel__pagination {
      height: 100%;

      @include xs {
        padding: var(--offset-gap);
      }
    }

    .carousel__slider {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    @include xs {
      --arrow-offset-ratio: 0.9;
    }

    @include xxs {
      --slide-width: unset;
    }

    &.--lighter {
      --slider-btn-color: var(--color-white);
      --slider-btn-hover-color: var(--color-gray-1);
      --slider-btn-hover-bg: var(--color-white);
      --slider-btn-active-bg: #{rgba($color-white, 0.8)};
      --color-outline: var(--color-white);

      .carousel__button {
        @include setBlur(5px, #0002);
        box-shadow: inset 0 0 0 2px var(--slider-bg);
      }
    }
  }

  &__slider {
    margin: calc(var(--gap) * -1) calc(var(--offset-gap) * -1);
    padding: calc(var(--gap) * 1) calc(var(--offset-gap) * 1);
    min-width: calc(100% + var(--offset-gap) * 2);

    @include min(580) {
      -webkit-mask-image: linear-gradient(90deg, #0000 0px, #000 20px, #000 calc(100% - 20px), #0000 100%);
    }

    @include xxs {
      overflow: visible;
    }
  }

  &__item {
    width: var(--slide-width, calc(100% / var(--slides-per-view) - var(--slide-gap) * (1 / var(--slides-per-view) * (var(--slides-per-view) - 1))));

    &.--height-unset {
      height: unset;
    }

    &:not(:last-child) {
      margin-right: var(--slide-gap);
    }
  }

  &__overlay {
    position: absolute;
    width: calc(100% + var(--slider-btn-size) * var(--arrow-offset-ratio, 1));
    top: 0;
    left: calc(var(--slider-btn-size) * (-1 * var(--arrow-offset-ratio, 1) / 2));
    height: var(--slide-height, 100%);
    z-index: 2;
    pointer-events: none;
    display: grid;
    align-items: center;
    opacity: 0;
    transition: opacity .2s var(--reveal-easing);

    .carousel.--init & {
      opacity: 1;
    }

    @include xs {
      width: calc(100% + var(--slider-btn-size) * var(--arrow-offset-ratio, .5));
      left: calc(var(--slider-btn-size) * (-1 * var(--arrow-offset-ratio, .25) / 2));
    }
  }

  &__pagination {
    align-items: end;
    justify-items: center;
    height: 100%;

    .swiper-pagination {
      position: relative;
    }
  }

  &__bullet {
    width: var(--pagination-bullet-width);
    height: var(--pagination-bullet-height);
    display: inline-block;
    border-radius: 50%;
    background-color: #0002;
    margin: 1px 3px;
    @include trns;

    &.--active-bullet {
      background-color: var(--color-gray-1);
    }
  }

  &__head {
    padding-bottom: 1.4rem;
  }

  &__button {
    padding: 0;
    padding-top: 1px;
    width: var(--slider-btn-size);
    height: var(--slider-btn-size);
    border-radius: 50%;
    transform: scale(var(--slider-button-scale, 1));
    color: var(--slider-btn-color);
    background-color: var(--slider-btn-bg);
    border: none;
    box-shadow: 0 0 0 2px var(--slider-bg);
    pointer-events: all;
    opacity: var(--arrows-opacity);
    outline: none;
    cursor: pointer;
    @include trns;
    &::before {
      content: '';
      @include setInset(-1px);
      border-radius: 50%;
      pointer-events: none;
    }
    &:focus-visible::before {
      box-shadow: 0 0 0 2px var(--color-base), 0 0 0 3px var(--color-white);
    }
    &.--next {
      justify-self: flex-end;
    }

    &.--disabled {
      opacity: 0;
      pointer-events: none;
    }

    &.--autoplay {
      align-self: flex-end;
      padding-top: 3px;
    }

    @include mouse {
      &:hover {
        background-color: var(--slider-btn-hover-bg);
        color: var(--slider-btn-hover-color);
      }
    }

    &:active {
      @include trns(0s);
      color: var(--slider-btn-active-color);
      background-color: var(--slider-btn-active-bg);
    }

    .css-icon {
      --weight: 3px;
      --icon-offset: 2px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media (any-hover: hover) {
    &:hover {
      &.--darker {
        --arrows-opacity: 1;
      }
    }
  }

  @media (any-hover: none) {
    &.--darker {
      --arrows-opacity: 1;
    }
  }

  &:focus-within {
    &.--darker {
      --arrows-opacity: 1;
    }
  }
}