@use '../abstracts' as *;

.video-section {
    .layout {
        justify-items: center;
    }

    .vjs-poster {
        background-color: rgba(0, 0, 0, 0);
    }

    &__wrapper{
        height: 100%;
        width: 100%;
        position:relative;
        div {
            background-color: rgba(0, 0, 0, 0);
        }
    }
}

.vds-controls-group {
    display: flex;
}

.video {
    video {
        border-radius: var(--ui-roundness);
    }
}
