@use "./../variables" as *;

@mixin mobile {
  @media screen and (max-width: $sm-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $sm-breakpoint) {
    @content;
  }
}

@mixin xxs() {
  @media only screen and (max-width: #{$xxs-breakpoint}) {
    @content;
  }
}

@mixin xs() {
  @media only screen and (max-width: #{$xs-breakpoint}) {
    @content;
  }
}

@mixin sm() {
  @media only screen and (max-width: #{$sm-breakpoint}) {
    @content;
  }
}

@mixin md() {
  @media only screen and (max-width: #{$md-breakpoint}) {
    @content;
  }
}

@mixin lg() {
  @media only screen and (max-width: #{$lg-breakpoint}) {
    @content;
  }
}

@mixin xl() {
  @media only screen and (max-width: #{$xl-breakpoint}) {
    @content;
  }
}

@mixin to_xxs() {
  @media only screen and (min-width: #{calc($xxs-breakpoint + 1px) }) {
    @content;
  }
}

@mixin to_xs() {
  @media only screen and (min-width: #{calc($xs-breakpoint + 1px)}) {
    @content;
  }
}

@mixin to_sm() {
  @media only screen and (min-width: #{calc($sm-breakpoint + 1px)}) {
    @content;
  }
}

@mixin to_md() {
  @media only screen and (min-width: #{calc($md-breakpoint + 1px)}) {
    @content;
  }
}

@mixin to_lg() {
  @media only screen and (min-width: #{calc($lg-breakpoint + 1px)}) {
    @content;
  }
}

@mixin to_xl() {
  @media only screen and (min-width: #{calc($xl-breakpoint + 1px)}) {
    @content;
  }
}

@mixin inter($min, $max) {
  @media only screen and (min-width: #{$min}px) and (max-width: #{$max}px) {
    @content;
  }
}

@mixin max($value) {
  @media only screen and (max-width: #{$value}px) {
    @content;
  }
}

@mixin min($value) {
  @media only screen and (min-width: #{$value}px) {
    @content;
  }
}