@use '../abstracts' as *;

.send-options {
    &__item {
        .send-options-button {
            --button-gap: 8px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
            height: auto;
            position: relative;
            font-size: 0.9375rem;
            background: transparent;

            &:focus-visible {
                color: var(--color-primary);
            }

            &:hover {
                .send-options-button__text {
                    text-decoration: underline;
                }
            }

            &__text {
                color: var(--base-color);
                font-size: 1rem;
                line-height: 1.4;
            }

            &__icon {
                display: flex;
                position: relative;


                @include xs {
                    width: 100px;
                }
            }
        }
    }

    @include xs {
        display: flex;
        justify-content: center;
    }
}
