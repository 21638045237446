@use "./../abstracts" as *;

.app-banner {
    position: relative;

    .video-container,
    .banner-image-container {
        @include xs {
            background-color: var(--background-color-gray);
            padding: 2rem 0 3rem;
            display: block;
        }
    }

    .video-wrapper {
        .video-playpause {
            @include xs {
                left: 20px;
            }
            left: 40px;
        }
    }

    video {
        width: 100%;
        height: 100%;
        border-radius: var(--ui-roundness-lg, 0);
        vertical-align: bottom;

        @include xs {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 35px 0 0;
            padding: 0;
            width: 100%;
            height: 440px;
            max-width: none;
            max-height: none;
            -o-object-fit: cover;
            object-fit: cover;
            outline: none;
            border: none;
            box-shadow: none;
        }
    }

    &__overlay {
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: 34%;
        width: 32%;
        padding: 8% 1rem 3%;

        @include sm {
            width: 34%;
            left: 33%;
            padding: 6% 1rem 3%;
        }

        @include xs {
            width: 300px;
            position: relative;
            height: 475px;
            left: auto;
            margin: 2rem auto auto;
            padding: 38px 0 0;
        }
    }

    &__code {
        width: 60%;
        margin: 1.5rem auto 1.5rem;

        @include sm {
            width: 50%;
        }
    }

    &__icon {
        width: 30%;
        margin: auto;

        @include xs {
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
            left: 50%;
        }
    }
}