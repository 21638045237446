@use "../../abstracts" as *;

.checkbox {
  position: relative;
  z-index: 0;
  padding: 3px 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .field.--plain-field & {
    display: inline-flex;
  }

  &.--single,
  .field.--single & {
    padding: 0;
  }

  .form.filters & {
    padding: 0;
  }

  &.--radio {
    --field-roundness: 50%;
  }

  &.--switch {
    --field-checkbox-width: calc(var(--field-checkbox-size) + 12px);
    --field-checkbox-pad: 4px;
    --field-roundness: 30px;
  }

  &.--heading {
    --field-size: 1.1rem;
  }

  input {
    @include setInset;
    opacity: 0;
    border: none;
    margin: 0;
    outline: none;
    cursor: pointer;
    z-index: 0;
    -webkit-tap-highlight-color: transparent;
  }

  &.--single-reminder {
    input {
      cursor: default;
    }
  }

  &__label {
    -webkit-tap-highlight-color: transparent;
    --checkbox-color: transparent;
    --checkbox-background: var(--color-white);

    .checkbox.--filled.--switch & {
      --checkbox-color: var(--color-white);
      --checkbox-background: var(--color-gray-4-5);
    }

    display: flex;
    align-items: flex-start;

    input:checked~& {
      --checkbox-color: var(--color-gray-1);
      --checkbox-background: var(--color-white);

      .checkbox.--filled & {
        --checkbox-color: var(--color-white);
        --checkbox-background: var(--color-gray-1);
      }
    }

    input:focus-visible~& {
      .field.--plain-field & {
        outline: auto;
        outline-offset: 2px;
        outline-color: var(--color-gray-1);
      }
    }

    @include mouse {
      .checkbox.--filled.--switch input:not(:checked):hover~& {
        --checkbox-color: var(--color-white);
        --checkbox-background: var(--color-gray-4);
      }
    }

    .checkbox.--switch & {
      align-items: center;
    }
  }

  &__input {
    position: relative;
    z-index: -1;
    min-width: var(--field-checkbox-width, var(--field-checkbox-size));
    width: var(--field-checkbox-width, var(--field-checkbox-size));
    height: var(--field-checkbox-size);
    border-radius: var(--field-roundness);
    box-shadow: inset 0 0px 0 1px var(--field-outline),
      0 0px 0 0px var(--field-outline);
    background: var(--checkbox-background);
    color: var(--checkbox-color);
    display: flex;
    align-items: center;
    justify-content: center;
    @include trns(0.1s);

    .field.--medium & {
      margin-top: 1px;
    }

    .checkbox.--switch & {
      @include trns(0.2s);
      box-shadow: inset 0 0px 0 0px var(--field-outline),
        0 0px 0 0px var(--field-outline);
      justify-content: start;
      padding: var(--field-checkbox-pad);
    }

    .--single-reminder & {
      display: none;
    }

    &:before {
      content: "";
      @include setInset;
      @include trns(0.1s);
      border-radius: var(--field-roundness);
      box-shadow: 0 0 0 0px var(--field-outline-focused);
      opacity: 0;
      pointer-events: none;
    }

    &:after {
      content: "";
      @include setInset(1px);
      @include trns(0.1s);
      border-radius: var(--field-roundness);
      box-shadow: 0 0px 0 2px var(--field-outline-focused);
      opacity: 0;
      pointer-events: none;
    }

    .check-icon {
      width: 16px;
      height: 8px;
      border-bottom: 2px currentColor solid;
      border-left: 2px currentColor solid;
      top: -1px;
      z-index: 1;
      transform: scale(0.8) rotate(-45deg);
      position: relative;
      color: currentColor;
      @include trns(0.1s);

      .checkbox.--switch & {
        @include trns(0.2s);
        top: 0;
        transform: translateX(0);
        border: none;
        border-radius: 50%;
        background-color: currentColor;
        width: calc(var(--field-checkbox-size) - var(--field-checkbox-pad) * 2);
        height: calc(var(--field-checkbox-size) - var(--field-checkbox-pad) * 2);
      }

      .checkbox.--switch input:checked~.checkbox__label & {
        transform: translateX(calc(var(--field-checkbox-width) - var(--field-checkbox-size)));
      }
    }

    .radio-icon {
      width: 12px;
      height: 12px;
      border-radius: var(--field-roundness);
      position: relative;
      background-color: currentColor;
      @include trns(0.1s);
    }

    @include mouse {
      input:hover~.checkbox__label & {
        &:before {
          box-shadow: 0 0 0 3px var(--field-outline-focused);
          opacity: 0.2;
        }
      }

      .checkbox.--switch input:not(:checked):hover~.checkbox__label & {
        &:before {
          opacity: 0;
        }
      }
    }

    input:active:not(:checked)~.checkbox__label & {
      &:before {
        @include trns(0.1s);
        box-shadow: 0 0 0 1px var(--field-outline-focused);
        opacity: 0.2;
      }
    }

    input:checked~.checkbox__label & {
      box-shadow: inset 0 0px 0 1px var(--field-outline),
        0 0px 0 0px var(--field-outline-focused);

      .checkbox.--filled & {
        box-shadow: inset 0 0px 0 1px var(--color-gray-1),
          0 0px 0 0px var(--field-outline-focused);
      }

      .checkbox.--filled.--switch & {
        box-shadow: inset 0 0px 0 0px var(--color-gray-1),
          0 0px 0 0px var(--field-outline-focused);
      }
    }

    input:active~.checkbox__label & {
      box-shadow: inset 0 0px 0 1px var(--field-outline-focused),
        0 0px 0 1px var(--field-outline-focused);
    }

    input:focus~.checkbox__label & {
      @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        outline: 2px solid var(--color-outline);
        border-radius: var(--field-roundness);
      }
    }

    input:focus-visible~.checkbox__label & {
      box-shadow: inset 0 0px 0 1px var(--field-outline-focused),
        0 0px 0 1px var(--field-outline-focused);
      @include trns(0s);

      .check-icon,
      .radio-icon {
        @include trns(0s);
      }

      &:after {
        // @include setInset(-2px);
        // opacity: 1;
      }
    }

    input:focus-visible:checked~.checkbox__label & {
      box-shadow: inset 0 0px 0 1px var(--field-outline-focused),
        0 0px 0 1px var(--field-outline-focused);

      .checkbox.--filled & {
        outline: 2px var(--color-gray-1) solid;
        outline-offset: 2px;
      }
    }
  }

  &.--switch.--text-options {
    input:checked~.checkbox__label {
      --checkbox-background: #037311;
    }

    .checkbox__wrapper {
      display: flex;
      align-items: center;
    }

    .check-text {
      visibility: hidden;
      font-size: 0.8rem;
      letter-spacing: 0.385px;
      flex-grow: 1;
      text-align: center;
    }

    .checkbox__options {
      display: flex;
      position: absolute;
      left: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 var(--field-checkbox-pad);
    }

    input:checked~.checkbox__label .checkbox__input {
      .check-text.--checked {
        visibility: visible;
      }

      .check-text.--unchecked {
        visibility: hidden;
      }
    }

    .checkbox__input {
      .check-text.--unchecked {
        visibility: visible;
      }

      .check-text.--checked {
        visibility: hidden;
      }
    }
  }

  &__text {
    margin-left: 10px;
    align-self: center;
    letter-spacing: .3px;
    text-align: left;
    user-select: none;
    font-size: var(--field-size);

    .--single-reminder & {
      margin-left: 0;
      pointer-events: none;
    }

    &.--smaller {
      font-size: calc(var(--field-size) * .925);
    }

    .checkbox.--heading & {
      font-weight: 500;
      margin-bottom: -1px;
      letter-spacing: 0.2px;
    }

    a {
      position: relative;
      z-index: 1;
    }
  }
}