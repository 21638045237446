@use '../../abstracts/' as *;

.product-preview {
  --preview-overflows: 1;
  --product-aspect-ratio: 16/9;
  --product-roundness: 10px;
  --preview-roundness: var(--dialog-roundness, 0);
  --preview-spacing: calc(var(--dialog-spacing, 0) * .5);
  --product-width: 770px;

  @include md {
    body:not(.product-webview) & {
      --product-width: 550px;
    }
  }

  display: grid;
  grid-template-columns: minmax(1px, 1fr);

  &.--interactive,
  &.--talking-smashup,
  &.--selfie-smashup,
  &.--postcard {
    --product-aspect-ratio: 1/1;
    --product-width: 550px;

    @include lg {
      body:not(.product-webview) & {
        --product-width: 450px;
      }
    }
  }

  &.--postcard {
    --product-aspect-ratio: auto;
  }

  &__content {
    width: var(--product-width);
    max-width: calc(100% + var(--preview-spacing, 0) * var(--preview-overflows) * 2);
    margin: 0 calc(var(--preview-spacing, 0) * var(--preview-overflows) * -1) 0;
    position: relative;

    &:before {
      content: '';
      @include brandingLoader(#f2f2f2, #fcfcfc);
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      border-radius: var(--product-roundness);
      transition: opacity .3s var(--reveal-easing);

      .product-preview.--waits-visible &,
      .product-preview.--ecard &,
      .product-preview.--talking-smashup &,
      .product-preview.--selfie-smashup & {
        z-index: 2;
      }

      .product-preview.--creatacard &,
      .product-preview.--postcard & {
        display: none;
      }
    }

    #toggleAnimations {
      display: none;
    }

    // Ecard preview
    #agi-brightcove.default-bc {
      display: grid;

      div.video-js {
        width: 100% !important;
        height: auto !important;
        border-radius: var(--product-roundness);
        overflow: hidden;
        display: grid;

        video {
          object-fit: cover;
          outline: none !important;
          border-radius: var(--product-roundness);
        }

        &.vjs-fullscreen {
          --product-roundness: 0;
          display: block;

          video {
            object-fit: contain;
          }
        }

        .vjs-poster {
          border-radius: var(--product-roundness);
          background-size: cover;
        }

        .vjs-control-bar {
          border-radius: 0 0 var(--product-roundness) var(--product-roundness);
        }
      }
    }
    /* Override styles for AG BrightCove player */
    #agi-brightcove {
      .vjs-big-play-button {
        background: rgba(0, 0, 0, 0.5);
        transition: color .2s, background-color .2s;
        &:focus,
        &:hover {
          transition: color 0s, background-color 0s;
          background-color: #d50032;
        }
      }
      .vjs-menu li.vjs-selected {
        color: inherit; /* overrides the default of #2B333F */
      }
      /* Play progress bar colors */
      .vjs-progress-holder .vjs-load-progress,
      .vjs-progress-holder .vjs-load-progress div {
        background-color: rgba(115, 133, 159, .5);
      }

      .vjs-progress-holder .vjs-play-progress,
      .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level,
      .vjs-menu li.vjs-selected {
        background-color: #d50032;
      }
    }

    #vjs-transcript,
    #ts-filler-container {
      margin-left: calc(var(--preview-overflows) * var(--preview-spacing, 0));
      margin-right: calc(var(--preview-overflows) * var(--preview-spacing, 0));
      max-width: calc(100% - var(--preview-spacing) * var(--preview-overflows) * 2);
    }

    .video-js .vjs-time-control {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @include xs {
      .video-js .vjs-control:not(.vjs-progress-control, .vjs-volume-control) {
        width: 3.6em;
        padding: 0;
      }

      .video-js .vjs-time-control {
        display: none;
      }
    }

    @include xxs {
      .video-js .vjs-control:not(.vjs-progress-control, .vjs-volume-control) {
        width: 42px;
      }
    }

    // Smashup preview
    #jwPlayerWrapper {
      border-radius: var(--product-roundness);
      // overflow: hidden;
      background-color: transparent;

      .jw-preview {
        background-color: transparent;
      }

      video {
        border-radius: var(--product-roundness);
      }

      &.jw-flag-fullscreen {
        --product-roundness: 0px;
      }

      .jw-preview,
      .jw-controls-backdrop,
      .jw-overlays,
      .jw-controls {
        border-radius: var(--product-roundness);
      }
    }


    .product-preview:not(.--ecard, .--smashup, .--creatacard, .--fallback) & {
      aspect-ratio: var(--product-aspect-ratio);
      border-radius: var(--product-roundness);
      overflow: hidden;
    }

    .product-preview:not(.--ecard, .--smashup, .--creatacard, .--fallback) & {
      aspect-ratio: var(--product-aspect-ratio);
      border-radius: var(--product-roundness);
      overflow: hidden;
    }

    .product-preview.--ready &:before {
      opacity: 0;
    }

    .product-preview.--after-ready &:before {
      display: none !important;
    }

    .product-preview.--ecard &,
    .product-preview.--smashup & {
      aspect-ratio: var(--product-aspect-ratio, 16/9);

      &:before {
        height: auto;
        display: block;
        aspect-ratio: var(--product-aspect-ratio, 16/9);
      }
    }

    .product-preview.--creatacard & {
      @include xxs {
        margin: auto 1.5rem;
      }
    }

    .product-preview.--smashup & {

      #product,
      #agi-expression-container,
      #jwPlayerWrapper {
        width: 100% !important;
        height: auto !important;
        display: grid;
      }

      .jw-wrapper {
        position: static !important;
        background-color: transparent;
      }

      #jwPlayerContainer {
        aspect-ratio: var(--product-aspect-ratio, 16/9) !important;
      }

      .jwplayer .jw-media video {
        position: relative !important;
        outline: none !important;
      }

      .jw-media.jw-reset {
        position: relative !important;
        display: grid;
      }
    }

    .product-preview.--ready & {
      aspect-ratio: auto;

      #agi-expression-container {
        overflow: hidden;
        border-radius: var(--product-roundness);

        &.--fullscreen {
          border-radius: 0px;
        }
      }
    }

    .unmute-message {
      display: none;
    }
  }

  &.--ecard,
  &.--smashup {
    video {
      aspect-ratio: var(--product-aspect-ratio, 16/9) !important;
      max-height: 100vh;
      width: 100%;
      height: auto;
    }

    &:not(.--after-ready) {
      .product-preview__content {
        border-radius: var(--product-roundness);
        overflow: hidden;
      }
    }
  }
}