@use './../abstracts' as *;

.modal#ub-video-player-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102;
  display: none;
  overflow: hidden;
  padding-right: 0 !important;
  outline: 0;
  background-color: var(--ui-backdrop);

  .modal-open & {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1100px;
      pointer-events: auto;
      background-color: #f0f0f0;
      background-clip: padding-box;
      border: none;
      border-radius: 4px;
      box-shadow: 0 .25rem .5rem rgba(#000000, .5);
      outline: 0;

      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: end;
        height: 0;
        margin: 0;
        padding: 0;
        border-bottom: 0 solid #333;
        border-radius: 10px 10px 0 0;

        .close {
          border: 2px transparent solid;

          &:focus-visible {
            outline-offset: 0;
          }

          @include mouse {
            &:hover {
              cursor: pointer;
              opacity: 0.9;

            }
          }
        }
      }

      .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 0;
        display: flex;
      }

      .modal-footer {
        display: none;
      }
    }
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 762px;
    }
  }
}

@include to_sm {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}