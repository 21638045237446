@use '../abstracts' as *;

.content-slide {
    a {
        text-decoration: none;
        display: grid;
        outline-offset: 3px;
    }
    &__title {
        p, h3 {
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: .4px;
            padding: 1rem 0 0;
            text-align: center;
        }
    }

    &__content {
        margin-top: 8px;
        p {
            letter-spacing: .4px;
            text-align: center;
            padding: 0 0rem 4px;
            line-height: 1.655em;
            font-size: .875rem;
            @include xs {
                padding: 0px .5rem 4px;
                padding-bottom: 0.4rem;
            }
        }
    }
}