@use "../../abstracts" as *;

@mixin rangeThumb {
  outline-offset: 2px;
  background-color: var(--color-gray-1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23888888' d='M5.819 2.128a.8.8 0 0 0-.8.8v10.967a.8.8 0 1 0 1.6 0V2.928a.8.8 0 0 0-.8-.8Zm4.248 0a.8.8 0 0 0-.8.8v10.967a.8.8 0 1 0 1.6 0V2.928a.8.8 0 0 0-.8-.8Z'/%3E%3C/svg%3E");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  cursor: e-resize;
  height: var(--range-thumb-height, 36px);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: var(--range-thumb-width, 15px);
  border-radius: var(--ui-roundness);
  appearance: none;
}

input[type="range"] {
  background-color: transparent;
  width: 100%;
  appearance: none;
  outline-offset: 3px;
  margin: 0px;

  &:focus {
    &::-webkit-slider-runnable-track {
      background: var(--color-gray-3);
    }

    &::-ms-fill-lower,
    &::-ms-fill-upper {
      background: var(--color-gray-3);
    }
  }

  &::-webkit-slider-runnable-track {
    background: var(--color-gray-3);
    border: 0;
    cursor: pointer;
    height: 1px;
    width: 100%;
  }

  &::-moz-range-track {
    outline-offset: 2px;
    background: var(--color-gray-3);
    border: 0;
    cursor: pointer;
    height: 1px;
    width: 100%;
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 11.5px 0;
    color: transparent;
    cursor: pointer;
    height: 1px;
    width: 100%;
  }

  &::-ms-fill-lower,
  &::-ms-fill-upper {
    background: var(--color-gray-3);
    border: 0;
  }

  &::-webkit-slider-thumb {
    @include rangeThumb;
  }

  &::-moz-range-thumb {
    @include rangeThumb;
    transform: translateY(0px);
  }

  &::-ms-thumb {
    @include rangeThumb;
  }

  &:focus-visible {

    &::-ms-thumb,
    &::-moz-range-thumb,
    &::-webkit-slider-thumb {
      outline: auto;
      outline-color: var(--color-gray-1);
    }
  }
}