@use "../abstracts/mixins" as *;

.checkout-page,
.cart-confirm-page {
  background-color: #fbfbfb;
}

#checkout-form {
  .layout.--two-thirds-reversed-sm {
    gap: 4rem;

    @include lg {
      gap: 2rem;
    }
  }

  .member-benefits-btn {
    --ui-btn-width: 20px;
    --ui-btn-height: 20px;
  }
}

.order-summary {
  &:not(.--no-border) {
    padding: 2rem;
    border: 1px solid var(--ui-divider-color);
    border-radius: var(--ui-roundness);
    background-color: var(--color-white);

    @include lg {
      padding: 1.5rem;
    }
  }

  --summary-spacing: 0.75rem;

  &.--spacing-small {
    --summary-spacing: 0.5rem;
  }

  &.--sticky {
    position: sticky;
    align-self: flex-start;
    top: calc(var(--main-bar-height)* var(--header-sticky) + 3rem);
  }

  .product-thumb {
    grid-template-columns: 0.8fr 2fr;
    gap: calc(2 * var(--summary-spacing));
    margin: 0;
  }

  p {
    margin: 0 !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }

  &__item {
    display: grid;
    grid-template-columns: 0.8fr 2fr;
    gap: calc(2 * var(--summary-spacing));

    &:not(.--no-border) {
      padding-top: var(--summary-spacing);
      border-top: 1px solid var(--ui-divider-color);
      margin-top: var(--summary-spacing);
    }

    .order-summary__value,
    .order-summary__value strong {
      overflow: hidden;
      overflow-wrap: normal;
      text-overflow: ellipsis;
    }
  }

  &.--sendconfirm {
    .flow:not(.--has-order-summary) & {
      display: none;
    }

    .order-summary__item {
      display: flex;
      justify-content: space-between;
    }

    @include to_xs {
      margin: auto;
      width: 420px;
    }
  }

  &.--cartconfirm,
  &.--advent {
    .order-summary__item {
      display: flex;
      justify-content: space-between;
    }

    .order-summary__value,
    .order-summary__value strong {
      text-wrap: nowrap;
      overflow-wrap: normal;
      overflow: visible;
    }

    .product-thumb {
      grid-template-columns: 1.2fr 2fr;
    }
  }

  &.--cartconfirm {
    @include to_xs {
      margin: auto;
      width: 445px;
    }
  }

  &.--subscription {
    .flow:not(.--has-order-summary) & {
      display: none;
    }

    .order-summary__item {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.flow.--has-order-summary {

  .wizard-panel__inner>*,
  .wizard-panel,
  .wizard-panel>* {
    background-color: #fbfbfb;
  }
}

.custom-song__content {
  display: flex;
  gap: 1rem;
  --vertical-spacing: 0;
  align-items: center;
}