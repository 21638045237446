@use '../abstracts' as *;

.wizard {
  display: grid;
  --wizard-panels-offset: 25%;
  --wizard-roundness: 10px;
  position: relative;

  &.--flat {
    --wizard-panels-offset: 100px;
    --wizard-roundness: 4px;
    justify-content: unset;

    @include xs {
      --wizard-panels-offset: 50px;
    }
  }

  @include xs {
    place-content: unset;
  }

  .text-block:not(.--remain-left) {
    padding-left: 0;
    padding-right: 0;
  }

  &.--staging.--flat {
    overflow: hidden;
    clip-path: inset(0px round var(--wizard-roundness));
  }

  &__wrapper {
    border-radius: var(--wizard-roundness);
    background-color: var(--color-white);
    box-shadow: 0 15px 45px 0 #8b1f2a14, 0 0 0 1px #c329390d;

    .wizard.--flat & {
      box-shadow: none;
      border-radius: unset;
    }

    .wizard.--transitioning & {
      @include trns(var(--wizard-speed, .4s), 2);
      width: var(--wizard-width, auto);
      height: var(--wizard-height, auto);
    }
  }

  &.--stripped {
    .wizard-panel {
      padding: var(--offset-gap);
    }

    .wizard__wrapper {
      box-shadow: none;
    }
  }

  &__recovery {
    background-color: var(--color-white);
    z-index: 25;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: grid !important;
    place-content: top;
    @include trns(.3s, 2);

    &-box {
      height: 100%;
      max-height: 80vh;
      display: grid;
      place-content: center;
      @include trns();
    }

    &-spinner {
      margin: 0 auto 1rem;

      .css-icon {
        --size: 32px;
        --weight: 3px;
      }
    }
  }

  &.--recovering {
    .wizard__recovery {
      opacity: 1;
      pointer-events: all;
    }

    .wizard__wrapper {
      pointer-events: none;
    }
  }

  &.--recovered,
  &.--recovering {
    .wizard__recovery .css-icon.--loader::before {
      animation: rotate .65s linear infinite;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;

    .wizard.--transitioning & {
      @media not (prefers-reduced-motion) {
        overflow: hidden;
        clip-path: inset(0px round var(--wizard-roundness));
      }
    }
  }

  .conditional-fields[data-conditional-field="forgot-password"][data-name="first"] {
    margin-top: calc(-.5 * var(--fieldset-spacing));

    .conditional-field {
      padding-top: calc(.5 * var(--fieldset-spacing));

      .conditional-field__inner.--half-spacing {
        padding-top: 0;
      }
    }
  }

  @include sm {
    .layout__item.--center {
      justify-self: unset;
    }

    .form {
      --fieldset-spacing: 1.75rem;
    }
  }
}

.wizard-panel {
  --transform-offset: var(--wizard-panels-offset);
  padding: 3rem;
  position: relative;
  border-radius: var(--wizard-roundness);
  width: 100%;
  display: none;

  section {
    width: 100%;
    justify-content: unset;
    background-color: var(--color-white);
    .layout.--add-border {
      background-color: var(--color-white);
    }
  }

  .wizard.--flat & {
    width: 100%;
    padding: 0;
    min-width: calc(var(--client-width, 100vw) - 20px);
  }

  &.--staging {
    --staging: 1;
  }

  @include sm {
    padding: 2.5rem 2.25rem;
  }

  @include xs {
    padding: 2.5rem 2rem;
  }

  &.--previous {
    --transform-offset: calc(var(--wizard-panels-offset) * -1);
  }

  &:focus-visible {
    overflow: hidden;
    outline-width: 0px;
  }

  &.--activating {
    animation: wizardPanelEnter var(--wizard-speed) var(--reveal-easing) both;
    display: grid !important;
    .wizard-panel__inner {
      --trns-duration: 0s;
    }
  }

  &.--active {
    display: grid !important;
  }

  &.--deactivating {
    position: absolute;
    animation: wizardPanelLeave var(--wizard-speed) var(--reveal-easing) both;
    .wizard-panel__inner {
      --trns-duration: 0s;
    }
  }

  &__next,
  &__back {
    .css-icon {
      --scale: 1;
    }
  }

  &:not(.--welcome) {
    .wizard-panel__header {
      .text-block {
        .wizard:not(.--flat) {
          --heading-scale: .9;
        }
      }
    }
  }

  &__back-holder {
    margin-left: -12px;
    margin-right: 1.35rem;
    height: 30px;
    display: flex;
    align-items: center;
    padding-right: 0.9rem;

    .wizard.--flat & {
      margin-left: -59px;

      @include inter(990, 1080) {
        margin-left: -12px;
      }

      @include inter(770, 875) {
        margin-left: -12px;
      }

      @include xs {
        margin-left: -12px;
        margin-right: 1.35rem;
        position: absolute;
        top: 0px;
        height: 100%;
        align-items: flex-start;
        margin-top: -4px;
      }

      @include xxs {
        margin-top: -6px;
      }
    }

    .wizard.--flat .container.--sm-full & {
      @include sm {
        margin-left: -12px;
      }
    }

    .--underline &,
    .--no-divider & {
      margin-right: 0rem;
    }

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      top: 0;
      left: 45px;
      background-color: var(--ui-divider-color);
      position: absolute;

      .--underline &,
      .--no-divider & {
        display: none;
      }

      .--flat & {
        @include xs {
          display: block;
          height: calc(100% - 2.2rem);
          left: unset;
          right: 0px;
        }

        @include xxs {
          display: none;
        }
      }
    }

    .wizard:not(.--flat) & {
      &.--hide {
        display: none;
      }
    }
  }

  &__header {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;

    &.--underline {
      padding-bottom: 2.2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid var(--ui-divider-color);

      @include xs {
        margin-bottom: 0;
        border: none;
      }
      &.--smaller {
        padding-bottom: 1.15rem;
        margin-bottom: 1.2rem;
      }
    }

    &.--smaller {
      h2 {
        margin-left: -4px;
        --heading-scale: .9;
      }

      h3 {
        margin-left: -4px;
      }
    }

    &.--spacing-after {
      padding-bottom: 2.2rem;
      &.--smaller {
        padding-bottom: 1.15rem;
      }
    }

    &.--no-back {
      grid-template-columns: 1fr auto;
    }

    .text-block {
      align-self: auto;

      p {
        text-wrap: balance;
      }
    }

    &.--divider-bot {
      border-bottom: 1px solid var(--ui-divider-color);
    }

    &.--vertical-spacing {
      margin: 1rem 0 2rem;

      &.--no-divider {
        margin-bottom: 1rem;
      }
    }

    .--flat & {
      @include xs {
        grid-template-columns: 1fr;

        &:not(.--no-back) {
          padding-left: 4.6rem;
        }

        .text-block {
          text-align: left;
        }
      }

      &:not(.--no-back) {
        @include xxs {
          padding-left: 2.4rem;
        }
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 1;

    .wizard.--flat & {
      display: grid;
      align-content: center;
    }
  }
}

@keyframes wizardPanelEnter {
  0% {
    opacity: 0;
    transform: translateX(var(--transform-offset))
  }
  100% {
    opacity: 1;
    transform: translateX(0px)
  }
}

@keyframes wizardPanelLeave {
  0% {
    opacity: 1;
    transform: translateX(0px)
  }
  100% {
    opacity: 0;
    transform: translateX(var(--transform-offset))
  }
}