@use '../abstracts' as *;

.filters {
  &.--wait-filters-init:not(.--filters-init) {
    pointer-events: none;
  }

  &__holder {
    z-index: 20;
  }

  &__block {
    height: var(--block-height, auto);
    overflow: hidden;
    margin: -4px;

    &.--transition {
      @include trns(.3s, 2);
    }

    &.--active-filters {
      .filters.--no-active-filters & {
        height: 0px;
      }

      .filters__heading {
        margin-bottom: 7px;
      }
    }
  }

  &__wrapper {
    padding: 4px;
  }

  &__reset {
    &.hidden-sm {
      margin-top: 0.725rem;
      display: block;
    }

    font-size: .875rem;
    letter-spacing: .3px;
    font-weight: 400;
  }

  &__show-results-holder {
    @include to_sm {
      display: none;
    }

    padding: 1rem 1rem 2rem;
    margin: 1.625rem -1rem calc(-1 * (var(--aside-spacing) + 1rem));
    background-color: var(--color-white);
    @media (min-height: 500px) {
      position: sticky;
    }
    bottom: 0px;
    @include trns();
    height: 90px;

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 2rem);
      left: 1rem;
      top: 0;
      height: 1px;
      background-color: var(--ui-divider-color);
      @include trns();
    }

    &.--hidden {
      height: 0px;
      overflow: hidden;
      padding: 0rem 1rem 0rem;
      margin: 0rem -1rem 0rem;

      &::after {
        opacity: 0;
      }
    }
  }

  &__show-results {}

  &__block-holder {
    position: relative;
    display: grid;

    &:not(:last-child) {
      @include trns(.3s, 2);
      padding-bottom: 1.625rem;

    }

    &.--active-filters {
      padding-bottom: 2.8rem;

      @include sm {
        padding-bottom: 2.45rem;
        @media (min-height: 500px) {
          position: sticky;
        }
        padding-top: 2rem;
        margin-top: -2rem;
        top: 0px;
        z-index: 1;

        &>fieldset {
          position: relative;
          z-index: 1;
        }

        &::before {
          content: '';
          position: absolute;
          z-index: 0;
          width: calc(100% + 2rem);
          left: -1rem;
          height: calc(100% - 1.4rem);
          background-color: var(--color-white);
        }
      }

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        bottom: 1.4rem;
        position: absolute;
        left: 0;
        background-color: var(--ui-divider-color);
        @include trns;
      }
    }

    &.--hidden {
      padding-bottom: 0px;

      &:not(.--active-filters) {
        display: none;
      }

      &:after {
        opacity: 0;
      }
    }


    &.--active-filters {


      .filters:not(.--filters-overload) & {
        @media (min-height: 700px) {
          // position: sticky;
          // z-index: 2;
          // background-color: var(--color-white);
          // box-shadow: 0 -10px 0 10px var(--color-white), 0 -3rem 0 10px var(--color-white);
          // top: calc(var(--main-bar-height) + var(--spacing) * 1);
        }
      }

      .filters.--no-active-filters & {
        z-index: 0;
        // box-shadow: 0 0px 0 0px var(--color-white), 0 0 0 0 var(--color-white);
      }
    }
  }

  &__heading {
    font-size: .925rem;
    margin-bottom: 7px;
    letter-spacing: .5px;
    font-weight: 500;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__list {
    // display: grid;
    // gap: 0px;
  }

  &__show-more {
    margin-left: 32px;
    margin-top: .4375rem;
    font-size: .875rem;
    padding-top: 3px;
    letter-spacing: .3px;
    font-weight: 400;
    height: calc(.875rem * 1.2 + 3px);
  }

  &__actives {
    display: flex;
    flex-flow: wrap;
    gap: 5px;
  }

}

.filter {
  .checkbox__label {
    min-height: 28px;
    letter-spacing: .6px;
  }

  &.--may-be-hidden {
    &.--hidden {
      display: none;
    }

    .filters__block.--absolute-hidden & {
      position: absolute;
    }

    .filters__block.--show-hidden & {
      display: block;
    }
  }

  .field {
    justify-content: flex-start;
    --field-checkbox-size: 20px;

    .check-icon {
      width: 14px;
      height: 7px;
    }
  }

  .checkbox__text {
    font-size: .875rem;
    padding-top: 4px;
    padding-bottom: 2px;
    letter-spacing: .6px;
    margin-left: 12px;
    line-height: 1.3em;
    // align-items: baseline;
    // gap: 7px;
    // display: grid;
    // grid-template-columns: auto auto;
  }

  .checkbox__input {
    margin: 4px 0;
  }

  // .checkbox__inner-text {
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 2;
  //   -webkit-box-orient: vertical;
  //   max-width: 100%;
  // }

  &__count {
    color: var(--color-gray-2);
    margin-left: 7px;
  }

  &__list-wrapper {
    margin: -4px;
    height: var(--list-height, auto);

    .filters__block:not(.--transition) & {
      overflow: hidden;
      @include trns(.3s, 2);
    }
  }

  &__list-inner {
    padding: 4px;

    ul {
      position: relative;
    }
  }
}

.filter-remove {
  background: #f0f0f0;
  color: var(--color-gray-1);
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: pointer;
  border: none;
  outline-offset: 0px;
  outline-color: var(--color-gray-1);
  font-weight: 400;
  text-transform: none;
  min-width: unset;
  align-items: center;
  font-size: .825rem;
  gap: 2px;
  letter-spacing: .2px;
  height: unset;
  min-height: unset;
  padding: 5px .5rem 5px .9rem;
  border-radius: var(--ui-roundness, 0px);

  * {
    pointer-events: none;
  }

  .css-icon {
    --scale: .65;
    --size: 28px;
    top: 1px;
    color: var(--color-gray-2);
  }

  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }



  @include mouse {

    &:focus,
    &:hover {
      background-color: #e6e6e6;
      color: var(--color-gray-1);
    }
  }

  &:active {
    background-color: #dadada;
    color: var(--color-gray-1);
  }

  &:focus-visible {
    background: #f0f0f0;
    outline-offset: 2px !important;
  }

}