@use "../abstracts" as *;

.btn {
  // default
  --color: var(--color-white);
  --background: var(--color-gray-1);
  --outline: none;
  // hovered
  --color-hover: var(--color-white);
  --background-hover: var(--color-primary-lighter);
  --outline-hover: none;
  // active
  --color-active: var(--color-white);
  --background-active: var(--color-primary-darker);
  --outline-active: none;

  &.--white {
    // default
    --color: var(--color-white);
    --background: #{rgba($color-gray-1, 0)};
    --outline: inset 0 0 0 2px var(--color-white),
    0 0 0 2px #{rgba($color-white, 0)};
    // hovered
    --color-hover: var(--color-white);
    --background-hover: #{rgba($color-gray-8, 0)};
    --outline-hover: inset 0 0 0 2px var(--color-white),
    0 0 0 4px #{rgba($color-white, 0.3)};
    // active
    --color-active: var(--color-white);
    --background-active: #{rgba($color-gray-8, 0.2)};
    --outline-active: inset 0 0 0 2px var(--color-white),
    0 0 0 0px #{rgba($color-white, 1)};
  }

  &.--red {
    // default
    --color: var(--color-white);
    --background: var(--color-primary-lighter);
    // hovered
    --color-hover: var(--color-white);
    --background-hover: var(--color-primary);
    // active
    --color-active: var(--color-white);
    --background-active: var(--color-primary-darker);
  }

  &.--outline {
    // default
    --color: var(--color-gray-1);
    --background: transparent;
    --outline: inset 0 0 0 1px var(--color-gray-1);
    // hovered
    --color-hover: var(--color-gray-1);
    --background-hover: transparent;
    --outline-hover: inset 0 0 0 1px var(--color-gray-1);
    // active
    --color-active: var(--color-gray-1);
    --background-active: transparent;
    --outline-active: inset 0 0 0 1px var(--color-gray-1);

    &.--inactive {
      --outline: inset 0 0 0 1px var(--color-gray-3);
      --outline-hover: inset 0 0 0 1px var(--color-gray-3);
      --outline-active: inset 0 0 0 1px var(--color-gray-3);
      cursor: auto;
      opacity: .7;
    }
  }

  &.--filled-white {
    // default
    --color: var(--color-gray-1);
    --background: #{rgba($color-white, 1)};
    --outline: inset 0 0 0 0px var(--color-primary);
    // hovered
    --color-hover: var(--color-white);
    --background-hover: #{rgba($color-primary, 1)};
    --outline-hover: inset 0 0 0 0px var(--color-primary);
    // active
    --color-active: var(--color-white);
    --background-active: var(--color-primary-darker);
    --outline-active: inset 0 0 0 0px var(--color-white);
  }

  &.--light-transparent {
    // default
    --color: var(--color-white);
    --background: transparent;
    --outline: inset 0 0 0 2px var(--color-white),
    0 0 0 2px #{rgba($color-white, 0)};
    // hovered
    --color-hover: var(--color-gray-1);
    --background-hover: #{rgba($color-white, 1)};
    --outline-hover: none;
    // active
    --color-active: var(--color-gray-1);
    --background-active: #{rgba($color-white, 0.9)};
    --outline-active: inset 0 0 0 2px var(--color-white),
    0 0 0 2px #{rgba($color-white, 0)};
  }

  &.--dark-transparent {
    // default
    --color: var(--color-jet);
    --background: transparent;
    --outline: inset 0 0 0 2px var(--color-jet),
    0 0 0 2px #{rgba($color-jet, 0)};
    // hovered
    --color-hover: var(--color-white);
    --background-hover: #{rgba($color-jet, 0.8)};
    --outline-hover: none;
    // active
    --color-active: var(--color-white);
    --background-active: #{rgba($color-jet, 1)};
    --outline-active: none;
  }

  &.--secondary,
  &.secondary-button {
    // default
    --color: var(--color-base);
    --background: var(--color-gray-6);
    --outline: none;
    // hovered
    --color-hover: var(--color-base);
    --background-hover: var(--color-gray-5);
    --outline-hover: none;
    // active
    --color-active: var(--color-base);
    --background-active: var(--color-gray-4-5);
    --outline-active: none;

    &.--red {
      // default
      --color: var(--color-primary-lighter);
      --background: var(--color-primary-alpha-15);
      --outline: none;
      // hovered
      --color-hover: var(--color-primary-lighter);
      --background-hover: var(--color-primary-alpha-20);
      --outline-hover: none;
      // active
      --color-active: var(--color-primary-lighter);
      --background-active: var(--color-primary-alpha-25);
      --outline-active: none;
    }
  }

  &.--apple-pay {
    // default
    --color: var(--color-gray-1);
    --background: var(--color-white);
    // hovered
    --color-hover: var(--color-gray-1);
    --background-hover: var(--color-white);
    // active
    --color-active: var(--color-gray-1);
    --background-active: var(--color-white);
    border: 1px solid var(--color-gray-1);
    margin: 0;

    svg {
      height: 16px;
      width: auto;
      margin-left: -2px;

      @include to_xs {
        margin-bottom: -2px;
      }
    }

    .css-icon.--loader .glow {
      display: none;
    }
  }

  position: relative;
  border: none;
  text-decoration: none;
  // outline-color: var(--background-hover);
  outline-offset: 4px !important;
  color: var(--color);
  text-align: center;
  box-shadow: var(--outline);
  background: var(--background);
  min-height: var(--ui-btn-height, 42px);
  padding: 6px var(--ui-btn-spacing, 1.75rem);
  border-radius: var(--ui-roundness, 0px);
  font-size: var(--ui-btn-size, 0.81875rem);
  font-weight: 700;
  gap: 8px;
  cursor: pointer;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  letter-spacing: 2px;
  line-height: 1.3em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include setTransition(0.3s, 2, box-shadow, transform, background);

  &.--narrow {
    padding: 6px var(--ui-btn-spacing, 1.25rem);
  }

  &.--simple {
    padding: 6px var(--ui-btn-spacing, 1.25rem) 4px;
    text-transform: none;
    letter-spacing: .2px;
    font-size: 0.875rem;
    font-weight: 500;

    @include max(500) {
      padding: 6px var(--ui-btn-spacing, 1.25rem);
    }
  }

  @include max(500) {
    &.--wider {
      min-width: 150px;
    }

    font-size: var(--ui-btn-size, .725rem);
    padding: 6px var(--ui-btn-spacing, 1.25rem) 4px;
    min-height: var(--ui-btn-height, 40px);
    letter-spacing: 1.5px;
  }

  @include max(360) {
    padding: 6px var(--ui-btn-spacing, 1.15rem) 4px;
  }

  &.--secondary {
    min-width: unset;
  }

  &.--wider {
    min-width: 200px;

    @include sm {
      width: 100%;
    }
  }

  &.--wider-xs {
    min-width: 220px;

    @include xs {
      width: 100%;
    }
  }

  &.--red {}

  &.--round {
    border-radius: 50%;
  }

  &.--mini {
    font-size: var(--ui-btn-size, 0.7125rem);
    min-height: var(--ui-btn-height, 34px);
    letter-spacing: 1.3px;
    padding: 2px calc(var(--ui-btn-spacing, 2rem) * 0.6) 0px;
    min-width: unset;
    @include setTransition(0.2s, 1, box-shadow, transform, background, opacity);

    .product & {
      opacity: 0.95;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &.--icon-only {
      width: calc(var(--ui-btn-width, 34px));
      height: calc(var(--ui-btn-height, 34px));
      max-width: calc(var(--ui-btn-width, 34px));
      min-width: calc(var(--ui-btn-width, 34px));
      padding: 2px 0 0px;
    }

    &.--text-only {
      padding: 0px calc(var(--ui-btn-spacing, 2rem) * 0.6);
    }
  }

  &.--small {
    min-height: 36px;
    padding: 7px 1rem 6px;
    outline-offset: 3px !important;
    font-weight: 500;
    text-transform: none;
    letter-spacing: .2px;
    font-size: .903125rem;
    gap: 7px;

    @include xxs {
      gap: 7px;
      padding: 7px .75rem 6px;
    }

    .btn__icon {
      margin-left: -2px;

      svg {
        @include xxs {
          width: 16px;
          height: 16px;
        }
      }
    }
    &.--smaller-label {
      font-size: .795rem;
      .btn__text {
        padding-top: 2px;
      }
    }
  }

  &.--icon-only {
    width: var(--ui-btn-height, 42px);
    padding: 3px 0 0px;
    max-width: var(--ui-btn-height, 42px);
    min-width: var(--ui-btn-height, 42px);
  }

  // &.--outline{
  //   --color: var(--background);
  //   background: transparent;
  //   box-shadow: inset 0 0 0 2px var(--outline);
  // }
  &__icon {
    // margin-top: -1px;
    display: flex;
    @include trns;

  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &.--full-width {
    width: 100%;
  }
  &.--checkout-btn {
    --ui-btn-spacing: 3rem;
  }
  @include xs {
    &.--full-width-xs {
      width: 100%;
    }
  }

  & * {
    pointer-events: none;
  }

  @include mouse {
    &:hover {
      @include trns;
      box-shadow: var(--outline-hover);
      color: var(--color-hover);
      background: var(--background-hover);
    }
  }

  &:focus-visible {
    @include trns(0s);
  }

  &:active {
    @include trns(0s);
    color: var(--color-active);
    box-shadow: var(--outline-active);
    background: var(--background-active);
    outline-offset: 3px;
  }

  &__overlay {
    overflow: hidden;
    display: grid;
    place-content: center;
    opacity: 0;
    @include setInset;
    @include setTransition(0.3s, 2, opacity);
    --animation-iteration: 1;
  }

  .css-icon.--loader {
    --size: 18px;
  }

  &.--inner-loader {
    .btn {
      &__overlay {
        overflow: hidden;
        display: grid;
        place-content: center;
        opacity: 0;
        @include setInset;
      }

      &__text,
      &__content,
      &__icon {
        opacity: 1;
        white-space: nowrap;
        @include setTransition(0.3s, 2, opacity);
      }
    }
  }

  &.--inner-loader.--loading {
    .btn {
      &__overlay {
        --animation-iteration: infinite;
        opacity: 1;
      }

      &__text,
      &__content,
      &__icon {
        opacity: 0;
      }

    }
  }

  &.--inner-loader.--loaded {
    .btn {
      &__overlay {
        opacity: 0;
      }

      &__text,
      &__content,
      &__icon {
        opacity: 1;
      }

    }
  }

  &.--has-loader,
  &.--submit,
  &.load-more__trigger {
    .btn {
      &__overlay {
        overflow: hidden;
        display: grid;
        place-content: center;
        opacity: 0;
        @include setInset;

        .--loading & {
          --animation-iteration: infinite;
          opacity: 1;
        }
      }

      &__text,
      &__content,
      &__icon {
        opacity: 1;
        white-space: nowrap;
        @include setTransition(0.3s, 2, opacity);

        .--loading & {
          opacity: 0;
        }
      }

    }
  }
}

.btn-group {
  display: grid;
  gap: 8px;
}

.btn-copy {
  font-size: 0.75rem;
  letter-spacing: 0.35px;
}

.btn-container {
  @include xs {
    text-align: center;
  }
}

.btn-simple {
  border: none;
  padding: 0;
  background-color: transparent;
  color: var(--color-primary-lighter);
  outline-offset: 2px;
  line-height: 1.2em;
  text-decoration: none;
  height: calc(1rem * 1.2);
  cursor: pointer;
  font-weight: 500;

  &.--secondary {
    font-weight: inherit;
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: var(--color-gray-1);
    }
  }

  @include mouse {
    &:hover {
      text-decoration: underline;
      color: var(--link-hover-color);
    }
  }

  .form & {

    &.--link-display {
      font-weight: 500;

      &:hover {
        text-decoration: none;
        color: var(--color-primary-darker);
      }
    }
  }

  * {
    pointer-events: none;
  }

  &.--hidden {
    display: none;
    height: 0px !important;
    overflow: hidden;
    padding-top: 0 !important;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    // @include trns;
  }

  &__count {
    .btn-simple.--open & {
      display: none;
    }
  }

  &.--cancel-btn {
    --link-hover-color: var(--color-primary-darker);
    font-weight: 500;
    font-size: 1.0625rem;
  }

  &.--account-home-btn {
    display: block;
    font-size: 1.0625rem;
    margin: var(--offset-gap) var(--offset-gap) 0;
    text-decoration: underline;
  }

  &.--right-arrow {
    &.--arrow-tail {
      --translate-x: 2px;
    }
  }
}

.add-to-favorites {
  --icon-color: var(--color-gray-1);
  --is-favorite: 0;
  --is-not-favorite: 1;
  &:not(.btn-inline-simple){
    --color-outline: var(--color-white);
  }

  &.--is-favorite {
    --icon-color: var(--color-primary);
    --is-favorite: 1;
    --is-not-favorite: 0;

    .add-to-favorites__fill {
      opacity: 1;
    }

    .add-to-favorites__outline {
      opacity: 0;
    }
  }

  &.--filled-white {
    .add-to-favorites__outline {
      fill: var(--icon-color);
    }

    .add-to-favorites__fill {
      fill: var(--icon-color);
    }
  }

  @include mouse {
    &:hover {
      --icon-color: var(--color-white);
    }
  }

  &:active {
    --icon-color: var(--color-white);
  }

  &__icon {
    width: 20px;
    height: 20px;
    position: relative;

    .add-to-favorites.--icon-only & {
      width: 16px;
      height: 16px;
    }

    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;

      .add-to-favorites.--icon-only & {
        width: 16px;
        height: 16px;
      }

      path {
        @include trns(.3s);
      }
    }
  }

  &__fill {
    opacity: 0;
  }

  @include mouse {
    &:not(.--filled-white):hover {
      .add-to-favorites__fill {
        opacity: 1;
      }
    }
  }

  &:not(.--filled-white):active {
    .add-to-favorites__fill {
      opacity: 1;
    }
  }

  &__selected {
    fill: var(--icon-color);
    opacity: var(--is-favorite);
  }

  &__unselected {
    stroke: var(--icon-color);
    stroke-width: 1.4;
    opacity: var(--is-not-favorite);
  }
}

.btn-action {
  --btn-base-scale: .3;
  position: relative;
  z-index: 1;
  min-width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &.--mini {
    min-width: var(--ui-btn-width, 38px);
    height: var(--ui-btn-height, 38px);
    .table__row-actions & {
      min-width: 36px;
      height: 36px;
    }
  }

  &:not(.--icon-clean) {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.--square {
    min-width: 36px;
    height: 36px;
  }

  &__text {
    display: flex;
    padding-top: 3px;
  }

  &.--with-label {
    padding: 6px 1rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-size: .795rem;
    line-height: 1;

    svg:first-child {
      margin-right: 6px;
      margin-left: -2px;
    }

    svg:last-child {
      margin-left: 6px;
      margin-right: -2px;
    }
  }

  &.--red {
    color: var(--color-primary-lighter);
  }

  &>* {
    pointer-events: none;
  }

  &:after {
    pointer-events: none;
    content: "";
    @include setInset(0px);
    border-radius: 50%;
    background-color: currentColor;
    opacity: 0;
    transform: scale(var(--btn-base-scale, 1));
    @include trns(0.2s, 3);
    z-index: -1;
  }

  &__overlay {
    overflow: hidden;
    display: grid;
    place-content: center;
    opacity: 0;
    @include setInset;
    @include setTransition(0.2s, 1, opacity);

    .btn-action.--loading & {
      opacity: 1;
    }

    .field.--loading & {
      opacity: 1;
    }

    .css-icon {
      --size: 18px;
      .btn-action.--mini & {
        --size: 16px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    opacity: 1;
    @include trns;

    .btn-action.--loading & {
      opacity: 0;
    }

    .field.--loading & {
      opacity: 0;
    }
  }

  @include mouse {
    &:hover {
      &::after {
        @include trns(0.1s);
        opacity: 0.08;
        transform: scale(1);
      }
    }
  }

  &:focus-visible {
    &::after {
      opacity: 0.08;
      transform: scale(1);
    }
  }

  &:active {
    &::after {
      @include setTransition(0s, 1, opacity, transform);
      opacity: 0.16;
      transform: scale(1);
    }
  }

  &.--square {
    border-radius: var(--ui-roundness, 0px);

    &::after {
      opacity: 0.08;
      transform: scale(1);
      border-radius: var(--ui-roundness, 0px);
    }

    @include mouse {
      &:hover {
        &::after {
          @include trns(0.1s);
          opacity: 0.12;
          transform: scale(1);
        }
      }
    }

    &:focus-visible {
      &::after {
        opacity: 0.12;
        transform: scale(1);
      }
    }

    &:active {
      &::after {
        @include setTransition(0s, 1, opacity, transform);
        opacity: 0.16;
        transform: scale(1);
      }
    }
  }

  &.--primary {
    color: var(--color-white);
    background-color: var(--color-gray-1);
    @include setTransition(0.2s, 1, box-shadow, transform, background, opacity);
    outline-offset: 3px;

    &:after {
      display: none;
    }

    @include mouse {
      &:hover {
        @include trns(0.1s);
        background-color: var(--color-primary-lighter);
      }
    }

    &:focus-visible {
      @include trns(0.1s);
      background-color: var(--color-primary-lighter);
    }

    &:active {
      @include setTransition(0s, 1, opacity, transform);
      background-color: var(--color-primary-darker);
    }
  }

  &.--info-cta  {
    color: var(--color-white);
    outline-offset: 3px;
    background-color: darken($color-form-info, 5%);
    &:after {
      display: none;
    }
    @include mouse {
      &:hover {
        background-color: darken($color-form-info, 8%);
      }
    }
    &:focus-visible {
      background-color: darken($color-form-info, 8%);
    }

    &:active {
      background-color: darken($color-form-info, 10%);
    }
  }

  &.--error-cta  {
    color: var(--color-white);
    outline-offset: 3px;
    display: inline-flex;
    background-color: darken($color-form-error, 0%);
    &:after {
      display: none;
    }
    @include mouse {
      &:hover {
        background-color: darken($color-form-error, 5%);
      }
    }
    &:focus-visible {
      background-color: darken($color-form-error, 5%);
    }

    &:active {
      background-color: darken($color-form-error, 8%);
    }
  }
}

.btn-inline-action {
  cursor: pointer;
  background-color: var(--color-gray-1);
  color: var(--color-white);
  border: none;
  display: flex;
  align-items: center;
  border-radius: var(--ui-roundness, 0px);
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 0.795rem;
  margin: 0px;
  min-height: 36px;
  line-height: 1.25;
  text-decoration: none;
  padding: 8px .875rem 7px;
  outline-offset: 3px;
  @include setTransition(0.2s, 3, background);

  @include mouse {
    &:hover {
      @include trns(0.2s);
      background-color: var(--color-primary-lighter);
    }
  }

  * {
    pointer-events: none;
  }

  svg {
    &:not(:first-child) {
      margin-left: 7px;
    }

    &:not(:last-child) {
      margin-right: 7px;
    }
  }

  .css-icon {
    margin-top: 1px;
    margin-left: -1px;
    --size: 8px;

    &:nth-child(1) {
      margin-right: 6px;
    }
  }

  &:focus-visible {
    @include trns(0s);
  }

  &:active {
    @include trns(0s);
    background-color: var(--color-primary);
  }

  &.--light {
    color: var(--color-gray-1);
    // padding: 1px .9rem 0px;
    background-color: var(--color-gray-6);

    @include mouse {
      &:hover {
        background-color: var(--color-gray-5);
      }
    }

    &:active {
      background-color: var(--color-gray-4-5);
    }
  }

  &.--light-transparent {
    color: var(--color-gray-1);
    background-color: transparent;

    @include mouse {
      &:hover {
        background-color: var(--color-gray-5);
      }
    }

    &:active {
      background-color: var(--color-gray-4-5);
    }
  }

  &.--mini {
    min-height: var(--ui-btn-height, 34px);
    min-width: var(--ui-btn-width, 34px);
  }

  @include to_xxs {
    &.--secondary {
      color: var(--color-gray-1);
      // padding: 1px .9rem 0px;
      background-color: transparent;

      @include mouse {
        &:hover {
          background-color: var(--color-gray-5);
        }
      }

      &:active {
        @include trns(0s);
        background-color: var(--color-gray-4-5);
      }
    }
  }

  @include xxs {
    .form & {
      min-height: 40px;
    }

    .form .table__mobile-actions & {
      min-height: 36px;
    }
  }
}

.tox-button,
.tox-button--secondary {
  @include trns(0.2s, 3);

  @include mouse {
    &:hover {
      @include trns(0.2s);
      background-color: var(--color-primary-lighter);
    }
  }

  &:focus-visible {
    @include trns(0s);
  }

  &:active {
    @include trns(0s);
  }
}


.inline-link {
  background-color: transparent;
  border: none;
  color: var(--color-primary-lighter);
  font-size: 1rem;
  display: inline-flex;
  padding: 0px;
  outline-offset: 2px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
  &.--secondary {
    text-decoration: underline;
    color: var(--color-gray-1);
  }
  &.--error {
    color: var(--color-primary-darker);
    font-size: .938rem;
  }

  &.--right-arrow {
    gap: .5rem;
    align-items: center;
    text-decoration: none;
    @include mouse {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.area-btn {
  --icon-size: 30px;
  position: relative;
  border: none;
  outline-offset: 3px;
  padding: 0;
  cursor: pointer;
  border-radius: var(--ui-roundness, 0px);

  * {
    pointer-events: none;
  }

  &::after {
    content: '';
    background-color: var(--color-black);
    opacity: 0;
    border-radius: var(--ui-roundness, 0px);
    @include trns;
    @include setInset;
  }

  &__content {
    color: var(--color-white);
    transform: scale(1.2);
    position: absolute;
    top: calc(50% - var(--icon-size) * .5);
    left: calc(50% - var(--icon-size) * .5);
    width: var(--icon-size);
    height: var(--icon-size);
    opacity: 0;
    z-index: 1;
    @include trns;

    svg {
      width: var(--icon-size);
      height: var(--icon-size);
    }
  }

  &__overlay {
    color: var(--color-white);
    position: absolute;
    top: calc(50% - var(--icon-size) * .5);
    left: calc(50% - var(--icon-size) * .5);
    width: var(--icon-size);
    height: var(--icon-size);
    opacity: 0;
    z-index: 1;
    @include trns;

    .css-icon {
      --size: 30px;
      --weight: 3px;

      .glow {
        display: none;
      }
    }
  }

  @include mouse {
    &:hover {
      &:after {
        opacity: .3;
      }

      .area-btn__content {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &:focus {
    &:after {
      opacity: .3;
    }

    .area-btn__content {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:not(.lazy-load).--loading,
  &.lazy-load.--loaded.--loading {
    &:after {
      opacity: .4;
    }

    .area-btn__content {
      transform: scale(1);
      opacity: 0;
    }

    .area-btn__overlay {
      opacity: 1;
    }
  }

  &:active {
    &:after {
      @include trns(0s);
      opacity: .35;
    }

    .area-btn__content {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.--toggle-arrows {
  position: relative;
  padding-right: 2rem;

  &::before {
    content: "";
    pointer-events: none;
    width: 13px;
    height: 13px;
    position: absolute;
    top: calc(50% - 7px);
    right: 13px;
    transform: translateY(0px) rotate(-45deg) scale(.5);
    border: 3px currentColor solid;
    opacity: .6;
    border-top: none;
    border-right: none;
    transition: all .2s var(--transition-delay, 0s) var(--easing);
  }

  &[aria-expanded="true"] {
    &::before {
      transform: translateY(3px) rotate(135deg) scale(.5);
      transition: all .2s var(--transition-delay, 0s) var(--easing);
    }
  }
}

.btn-inline-simple {
  * {
    pointer-events: none;
  }

  --btn-bg: var(--color-primary-lighter);

  &.add-to-favorites {
    --btn-bg: var(--color-favorites);
  }

  &.rating-button {
    --btn-bg: var(--color-rating);
  }

  border: none;
  outline-offset: 2px;
  background-color: transparent;
  padding: 13px 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--btn-bg);
    opacity: 0;
    position: absolute;
    left: 0;
    border-radius: 2px 2px 0 0;
    bottom: -1px;
    @include trns;
  }

  @include mouse {
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &:active {
    &:before {
      opacity: 1;
    }
  }

  svg {
    position: static;
  }

  &__text {
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: .1px;
  }

  &__icon {
    margin-right: 7px;
  }
}
