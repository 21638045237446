.tooltip {
    color: #333;
    font-family: gordita;
    display: inline-flex;
    position: relative;
    &:hover .tooltip__content {
        display: block;
    }

    &__price-wrapper {
        display: flex;
        flex-flow: wrap;
        max-width: 100%;
        gap: 0px 3px;
    }

    &__trigger {
        border: 0;
        background-color: transparent;
        padding: 0px;
        height: 16px;
        width: 16px;
        cursor: pointer;
        &:focus-visible + .tooltip__content {
            display: block;
        }
    }

    &__content {
        padding: 14px;
        position: absolute;
        width: auto;
        white-space: nowrap;
        word-wrap: no-wrap;
        box-shadow: 1px 1px 20px #0000001f;
        border-radius: 5px;
        background-color: #fff;
        top: 28px;
        left: 6px;
        transform: translate(-50%);
        transform-style: preserve-3d;
        z-index: 200;
        font-size: 0.9em;
        display: none;
        h2 {
            font-size: 14px;
            padding-top: 1px;
            &:before {
                content: "";
                width: 100%;
                height: 14px;
                position: absolute;
                bottom: 100%;
                left: 0;
            }
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 12px;
            height: 12px;
            transform-origin: 50% 50%;
            transform: rotate(45deg);
            background-color: #fff;
            left: calc(50% - 6px);
            top: -5px;
            border-radius: 2px;
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            transform-origin: 50% 50%;
            transform: rotate(45deg) translateZ(-1px);
            background-color: #fff;
            left: calc(50% - 6px);
            top: -5px;
            z-index: -1;
            border-radius: 2px;
            box-shadow: -1px -1px 20px #aaa;
        }
    }

    &__line-item {
        width: 220px;
        font-size: 12px;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 7px;
        margin: 6px 0;
        &.--gift-total {
            border-top: 1px solid #CCC;
            margin-top: 5px;
            padding-top: 5px;
            margin-bottom: 0;
        }
    }

    &__line-item-name {
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__line-item-data {
        justify-self: flex-end;
    }
}
