@use '../../abstracts' as *;

.option {
  position: relative;
  --option-spacing: 1rem;
  background: white;

  input {
    @include setInset;
    opacity: 0;
    border: none;
    margin: 0;
    outline: none;
    cursor: pointer;
    z-index: 2;
    -webkit-tap-highlight-color: transparent;
  }

  &__input {
    position: relative;
    z-index: -1;
    min-width: var(--field-option-size);
    width: var(--field-option-size);
    height: var(--field-option-size);
    margin-top: var(--option-spacing);
    margin-bottom: var(--option-spacing);
    margin-left: var(--option-spacing);
    border-radius: var(--field-roundness);

    input[type="radio"]~.option__label & {
      border-radius: 50%;
    }

    background: var(--option-background);
    color: var(--option-color);
    display: flex;
    align-items: center;
    justify-content: center;
    @include trns(.1s);
    box-shadow: inset 0px 0 0 1px var(--field-outline);
    opacity: 0;
    margin-right: -33px;
    transform: scale(.5);

    input:checked~.option__label &,
    .option.--selected & {
      transform: scale(1);
      opacity: 1;
      margin-right: 0px;
      box-shadow: inset 0px 0 0 0 var(--field-outline);
    }

    .check-icon {
      width: 16px;
      height: 8px;
      border-bottom: 2px currentColor solid;
      border-left: 2px currentColor solid;
      top: -1px;
      transform: scale(.8) rotate(-45deg);
      position: relative;
      color: currentColor;
      @include trns(.1s);

      input[type="radio"]~.option__label & {
        width: 14px;
        height: 7px;
      }
    }
  }

  &__text {
    margin-left: 10px;
    align-self: center;
    user-select: none;
    font-size: var(--field-size);

    a {
      position: relative;
      z-index: 1;
    }
  }

  &__label {
    --option-color: transparent;
    --option-background: transparent;
    z-index: 1;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: max-content;
    border-radius: var(--field-roundness);
    box-shadow: inset 0 0px 0 1px var(--field-outline), 0 0px 0 0px var(--field-outline);
    position: relative;
    gap: 12px;

    &:before {
      content: '';
      @include setInset;
      @include trns(.1s);
      border-radius: inherit;
      box-shadow: 0 0 0 0px var(--field-outline-focused);
      opacity: 0;
      pointer-events: none;
    }

    &.--no-checkbox {
      grid-template-columns: 1fr;
      align-content: center;
      height: 100%;

      .option__content {
        padding-left: var(--option-spacing);
      }
    }

    &:after {
      content: '';
      @include setInset(1px);
      @include trns(.1s);
      border-radius: inherit;
      box-shadow: 0 0px 0 2px var(--field-outline-focused);
      opacity: 0;
      pointer-events: none;
    }

    input:hover~& {
      &:before {
        box-shadow: 0 0 0 3px var(--field-outline-focused);
        opacity: .2;
      }
    }

    input[type="radio"]:checked:hover~& {
      &:before {
        box-shadow: 0 0 0 0px var(--field-outline-focused);
        opacity: .2;
      }
    }

    input:active:not(:checked)~& {
      &:before {
        @include trns(.1s);
        box-shadow: 0 0 0 0px var(--field-outline-focused);
        opacity: .2;
      }
    }

    input:checked~& {
      --option-color: var(--color-gray-1);
      --option-background: var(--color-white);
      box-shadow: inset 0 0px 0 2px var(--field-outline), 0 0px 0 0px var(--field-outline-focused);

      .option.--filled & {
        --option-color: var(--color-white);
        --option-background: var(--color-gray-1);
        box-shadow: inset 0 0px 0 2px var(--color-gray-1), 0 0px 0 0px var(--field-outline-focused);
      }
    }

    .option.--selected.--filled & {
      --option-color: var(--color-white);
      --option-background: var(--color-gray-1);
      box-shadow: inset 0 0px 0 2px var(--color-gray-1), 0 0px 0 0px var(--field-outline-focused);
    }

    input:active~& {
      box-shadow: inset 0 0px 0 2px var(--field-outline-focused), 0 0px 0 0px var(--field-outline-focused);
    }

    input:focus-visible~& {
      box-shadow: inset 0 0px 0 2px var(--field-outline-focused), 0 0px 0 0px var(--field-outline-focused);
      @include trns(0s);

      .check-icon {
        @include trns(0s);
      }

    }

    input:focus-visible:checked~& {
      box-shadow: inset 0 0px 0 2px var(--field-outline-focused), 0 0px 0 0px var(--field-outline-focused);

      .option.--filled & {
        outline: 2px var(--color-gray-1) solid;
        outline-offset: 2px;
      }
    }

    .option.--selected.--filled input:focus-visible~& {
      outline: 2px var(--color-gray-1) solid;
      outline-offset: 2px;
    }
  }

  &__content {
    padding: var(--option-spacing);
    padding-left: 0;
    padding-bottom: calc(var(--option-spacing) - 2px);
    position: relative;
    align-self: center;
    &.--overflow {
      overflow: hidden;
      .payment-option__title {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

}

.membership-option {
  letter-spacing: .2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  &__title {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  }

  &__price {
    line-height: 1;
    font-size: 0.9375rem;
    white-space: nowrap;
  }

  &__off {
    text-transform: capitalize;
    // position: absolute;
    letter-spacing: .5px;
    top: -9px;
    left: 0;
    font-size: .575rem;
    font-weight: 500;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 2px 6px 0;
    border-radius: var(--ui-roundness, 0px);
  }

  &__desc {
    font-size: .75rem;
    color: var(--color-gray-2);
    &.--full-price {
      text-decoration: line-through;
    }
  }
}

.payment-option {
  display: flex;
  gap: 10px;
  align-items: center;
  &.--pof {
    gap: 8px;
  }
  &__icon {
    display: flex;

    svg {
      height: 22px;
      width: auto;
    }

    &.--nudge-top {
      margin-top: -2px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 0.9375rem;
  }
}

.music-option {
  --control-opacity: 0;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: var(--gap);

  .option:hover &,
  .field.--option input:focus-visible~.option__label &,
  .option.--selected & {
    --control-opacity: 1;
  }
  &__text {
    text-align: left;
  }

  &__title {
    font-weight: 500;
  }

  &__description {
    color: var(--color-gray-2);
  }

  &__control {
    @include setInset;
    background-color: #0008;
    display: grid;
    place-content: center;
    color: var(--color-white);
    @include trns;
    opacity: var(--control-opacity);
  }
}