@use "../abstracts/mixins" as *;

.contact-us{
    &__content{
        gap: 5rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        button {
            &.--submit{
                margin: 1rem 0 0;
            }
        }
        h2{
            margin-bottom: 1rem !important;
        }
        @include xs{
            grid-template-columns: 1fr;
        }
    }
    &__feedback {
        margin: 4rem auto;
        p{
            font-size: 1.125rem;;
        }
    }
}