@use '../../abstracts' as *;

.products-loop {
  display: grid;
  gap: 2rem;

  &__filter {
    @media (min-height: 500px) {
      position: sticky;
      top: calc(var(--nav-height) * var(--header-sticky));
    }
    z-index: 7;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 6;
    flex-flow: wrap;
    gap: 12px;
  }

  &__count {
    letter-spacing: .3px;
    color: var(--color-gray-3);
    font-size: .925rem;
    strong {
      color: var(--color-gray-2);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: calc(var(--gap) * 3) calc(var(--gap) * 2);
    align-items: flex-start;
    position: relative;

    @include xs {
      grid-template-columns: 1fr 1fr;
    }

    @include xxs {
      gap: calc(var(--gap) * 3) calc(var(--gap) * 1);
    }

    @include max(385) {
      grid-template-columns: 1fr;
    }

    &::after {
      content: '';
      @include setInset();
      opacity: 0;
      pointer-events: none;
      z-index: 5;
      @include trns(.2s, 2);

      .products-loop.--loading & {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__break {
    grid-column-start: 1;
    grid-column-end: 4;
    @include xs {
      grid-column-end: 3;
    }
    @include max(320) {
      grid-column-end: 2;
    }
  }
}

.filter-bar {
  background-color: var(--color-white);
  padding: 10px calc(var(--offset-gap));
  margin: 0 calc(var(--offset-gap) * -1);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__holder{
    position: sticky;
    top: calc(var(--nav-height) * var(--header-sticky));
    z-index: 7;
  }
  &:before {
    content: '';
    width: calc(100% - var(--offset-gap) * 2);
    left: var(--offset-gap);
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: var(--ui-divider-color);
  }
}

.filter-bar-btn {
  * {
    pointer-events: none;
  }

  --filter-bar-btn-color: var(--color-base);
  background: none;
  border: none;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: .2px;
  outline-offset: 2px;
  color: var(--filter-bar-btn-color);
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  @include trns;

  &__badge {
    margin-right: 2px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 2px 0px 2px 0px;
    @include trns;

    .--has-filters & {
      margin-right: 8px;
      background: var(--filter-bar-btn-color);
      padding: 2px 11px 2px 7px;
    }

    svg {
      margin-right: 2px;
      width: 24px;
      height: 24px;
    }
  }

  &__count {
    color: var(--color-white);
    font-size: 0.8em;
    letter-spacing: .3px;
    font-weight: 500;
  }

  &__on {
    opacity: 0;
    @include trns;

    .--has-filters & {
      opacity: 1;
    }
  }

  &__off {
    opacity: 1;
    fill: var(--filter-bar-btn-color);
    @include trns;

    .--has-filters & {
      opacity: 0;
    }
  }

  &.--clear {
    display: none;
    .--has-filters & {
      display: flex;
    }

    font-weight: 400;
    color: var(--color-primary-lighter);

    &:hover {
      color: var(--color-primary);
    }
  }

  &:active {
    --filter-bar-btn-color: var(--color-primary);
    @include trns(0s);

    .filter-bar-btn {

      &__badge,
      &__off {
        @include trns(0s);
      }
    }
  }
}