@use "../abstracts/" as *;

.entry-animation {
  --animation-name: entryFadeIn;
  --animation-speed: .3s;
  --animation-easing: var(--reveal-easing);
  --transform-offset: 20px;
  &.--slide-left {
    --animation-speed: .4s;
    --animation-name: entrySlideLeft;
  }
  @media (prefers-reduced-motion) {
    --animation-speed: .0s;
  }
  animation: var(--animation-name) var(--animation-speed) var(--animation-easing, linear) forwards;
}

@keyframes entryFadeIn {
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes entrySlideLeft {
  from {
    opacity: 0;
    transform: translateX(calc(var(--transform-offset) * 1));
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
}