/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at https://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2024 Fontspring
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Type Atelier
 * License URL: https://www.fontspring.com/licenses/type-atelier/webfont
 *
 */


// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-thin-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-thin-webfont.woff') format('woff');
//     font-weight: 200;
//     font-display: swap;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-light-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-light-webfont.woff') format('woff');
//     font-weight: 300;
//     font-display: swap;
//     font-style: normal;
// }

@font-face {
    font-family: "Gordita";
    src: url('../../fonts/Gordita/gordita-regular-webfont.woff2') format('woff2'),
        url('../../fonts/Gordita/gordita-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Gordita";
    src: url('../../fonts/Gordita/gordita-medium-webfont.woff2') format('woff2'),
        url('../../fonts/Gordita/gordita-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Gordita";
    src: url('../../fonts/Gordita/gordita-bold-webfont.woff2') format('woff2'),
        url('../../fonts/Gordita/gordita-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-black-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-black-webfont.woff') format('woff');
//     font-weight: 800;
//     font-display: swap;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-ultra-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-ultra-webfont.woff') format('woff');
//     font-weight: 900;
//     font-display: swap;
//     font-style: normal;
// }


// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-thinitalic-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-thinitalic-webfont.woff') format('woff');
//     font-weight: 200;
//     font-display: swap;
//     font-style: italic;
// }

// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-lightitalic-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-lightitalic-webfont.woff') format('woff');
//     font-weight: 300;
//     font-display: swap;
//     font-style: italic;
// }

@font-face {
    font-family: "Gordita";
    src: url('../../fonts/Gordita/gordita-regularitalic-webfont.woff2') format('woff2'),
        url('../../fonts/Gordita/gordita-regularitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Gordita";
    src: url('../../fonts/Gordita/gordita-mediumitalic-webfont.woff2') format('woff2'),
        url('../../fonts/Gordita/gordita-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Gordita";
    src: url('../../fonts/Gordita/gordita-bolditalic-webfont.woff2') format('woff2'),
        url('../../fonts/Gordita/gordita-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}

// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-blackitalic-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-blackitalic-webfont.woff') format('woff');
//     font-weight: 800;
//     font-display: swap;
//     font-style: italic;
// }

// @font-face {
//     font-family: "Gordita";
//     src: url('../../fonts/Gordita/gordita-ultraitalic-webfont.woff2') format('woff2'),
//         url('../../fonts/Gordita/gordita-ultraitalic-webfont.woff') format('woff');
//     font-weight: 900;
//     font-display: swap;
//     font-style: italic;
// }

:root {
    // Fonts
    --font: "Gordita", sans-serif;
    --font-ui: "Gordita", sans-serif;
}