@use "../abstracts/mixins/breakpoints.scss" as *;

.layout {
  --columns: 1;
  --box-spacing: 0rem;
  --layout-scale: 1;
  --layout-scale-row: var(--layout-scale);
  --layout-scale-column: calc(var(--layout-scale) * 1.5);
  --layout-spacing: var(--global-inter-content-spacing, 2rem);
  --layout-spacing-row: calc(var(--layout-spacing) * var(--layout-scale-row));
  --layout-spacing-column: calc(var(--layout-spacing) * var(--layout-scale-column));
  display: grid;
  gap: var(--layout-spacing-row) var(--layout-spacing-column);
  padding: var(--box-spacing);
  margin-left: calc(var(--box-spacing) * -1);
  margin-right: calc(var(--box-spacing) * -1);

  &.--equal {
    grid-template-columns: var(--grid-template-columns, minmax(1px, 1fr));
  }

  &.--no-gap {
    gap: 0;
  }

  &.--large-gap {
    gap: 4rem;

    @include sm {
      gap: 2rem;
    }
  }

  @include to_xs {
    grid-template-columns: var(--grid-template-columns,
        repeat(var(--columns, 1), 1fr));
    align-items: center;

    .form & {
      align-items: flex-start;
    }

    &.--equal {
      grid-template-columns: var(--grid-template-columns, repeat(var(--columns, 1), minmax(1px, 1fr)));
    }
  }

  &.--share-gift {
    --layout-scale: 0.375;
  }

  &.--smallest {
    --layout-scale: .25;
  }

  &.--smaller {
    --layout-scale: .5;
  }

  &.--small {
    --layout-scale: .75;
  }

  &.--medium {
    --layout-scale: 1.25;
  }

  &.--large {
    --layout-scale: 1.5;
  }

  &.--larger {
    --layout-scale: 1.75;
  }

  &.--largest {
    --layout-scale: 2;
  }

  &.--large-to-small {
    --layout-scale: 1.5;

    @include xs {
      --layout-scale: .75;
    }
  }

  @include lg {
    --layout-scale-column: calc(var(--layout-scale) * 1.25);
  }

  @include sm {
    --layout-scale-column: calc(var(--layout-scale) * 1);
  }

  &.--columns-2 {
    --columns: 2;
  }

  &.--columns-2-divider {
    @include lg {
      --layout-scale-column: 1.75;
    }

    @include md {
      --layout-scale-column: 1;
    }

    @include xs {
      --layout-scale-row: .75;
      grid-template-columns: none;
    }

    &.--small {
      --layout-scale-column: .5;

      @include xs {
        --layout-scale-row: .5;
      }
    }

    grid-template-columns: 2fr .1fr 2fr;

    &>.--divider {
      height: 100%;

      .divider-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--color-gray-2);
        letter-spacing: .4px;
        gap: 10px;
        text-transform: lowercase;

        @include xs {
          flex-direction: row;
        }

        &::after,
        &::before {
          content: '';
          width: 1px;
          background-color: var(--ui-divider-color);

          @include xs {
            height: 1px;
            flex-grow: 1;
          }
        }

        &::after {
          flex-grow: 1;

          @include xs {
            margin-top: unset;
            margin-left: 1rem;
          }
        }

        &::before {
          height: 98px;

          @include xs {
            height: 1px;
            margin-bottom: unset;
            margin-right: 1rem;
          }
        }

        &.--middle {
          &::before {
            height: calc(50% - 2rem);

            @include xs {
              height: 1px;
            }
          }
        }
      }
    }
  }

  &.--columns-3 {
    --columns: 3;
  }

  &.--columns-4 {
    --columns: 4;
  }

  &.--columns-6 {
    --columns: 6;
  }

  &.--columns-8 {
    --columns: 8;
  }

  &.--two-thirds {
    @include to_md {
      grid-template-columns: 1fr 3fr;
    }

    @include md {
      grid-template-columns: 1.5fr 2.5fr;
    }

    @include xs {
      --layout-scale-column: .5;
      grid-template-columns: unset;
    }
  }

  &.--two-thirds-reversed {
    --layout-scale-column: 1.25;

    &:not(.--even-spacing) {
      @include xs {
        --layout-scale: .25;
      }
    }

    @include to_xs {
      grid-template-columns: 2.5fr 1.5fr;
    }
  }

  &.--two-thirds-reversed-sm {
    @include sm {
      --layout-scale: 2.25;
    }

    @include to_sm {
      grid-template-columns: 2.5fr 1.4fr;
    }
  }

  &.--one-fifth {
    @include to_md {
      grid-template-columns: 1fr 4fr;
    }

    @include md {
      grid-template-columns: 1fr 3fr;
    }

    @include xs {
      --layout-scale-column: .5;
      grid-template-columns: unset;
    }
  }

  &.--divider {
    --layout-scale-column: 5;

    @include lg {
      --layout-scale-column: 3;
    }

    @include md {
      --layout-scale-column: 1.75;
    }

    @include xs {
      --layout-scale-row: 1.25;
    }

    &>.layout__item {
      position: relative;
      height: 100%;

      @include xs {
        height: auto;
        padding: unset;
      }
    }

    &>.layout__item:not(:last-child) {
      &:after {
        content: "";
        width: 1px;
        background: var(--ui-divider-color);
        position: absolute;
        height: 100%;
        right: calc(var(--layout-spacing-column) / -2);
        top: 0;
        bottom: 0;

        @include xs {
          width: 100%;
          height: 1px;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: unset;
          bottom: calc(var(--layout-spacing-row) / -2);
        }
      }
    }
  }

  &.--align-bottom,
  &.--end,
  &.--bottom {
    align-items: flex-end;
  }

  &.--align-top,
  &.--start,
  &.--top {
    align-items: flex-start;
  }

  &.--align-center {
    align-items: center;
    text-align: center;
  }

  &.--center {
    align-items: center;
  }

  &.--stretch {
    align-items: stretch;
  }

  &.--card-layout {
    --local-gap: 1.5rem 2rem;
    gap: var(--local-gap);
    grid-template-columns: repeat(var(--columns, 1), 1fr);

    @include sm {
      --local-gap: 2rem 1rem;
    }

    @include xs {
      gap: calc(var(--gap) * 2.25) var(--offset-gap);
      --columns: 2 !important;
    }

    @include xxs {
      gap: calc(var(--gap) * 2.25) var(--gap);
      --columns: 2 !important;
    }

    @include max(385) {
      --columns: 1 !important;
    }
  }

  &.--content-layout {
    grid-template-columns: repeat(var(--columns, 1), 1fr);
    @include xxs {
      --columns: 1 !important;
    }
  }

  &.--add-border {
    border: 1px solid var(--color-gray-5);
    padding: 2rem;
    border-radius: 10px;
  }

  &.--columns-2 {
    &.--switch {
      .layout__item {
        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(2) {
          order: 1;
        }
      }
    }
  }

  &.--flex-vertical-stretch {
    display: flex;
    height: 100%;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }

  &.--flex-horizontal-stretch {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &.--top-divider {
    margin-top: var(--layout-spacing);
    padding-top: calc(.75 * var(--layout-spacing));
    border-top: 1px var(--ui-divider-color) solid;
  }

  &__item {
    &.--full {
      grid-column: 1 / 3;
      .layout.--columns-2>& {
        @include xs {
          grid-column: 1 / 2;
        }
      }
    }

    &.--offset {
      margin: calc(var(--offset-gap) * -1);
    }

    &.--text-divider {
      display: flex;
      align-items: center;

      p {
        margin: 2px 10px;
        color: var(--color-gray-2);
        letter-spacing: .4px;
        text-transform: lowercase;
      }

      &:before,
      &:after {
        content: "";
        display: flex;
        flex: 1;
        height: 1px;
        background: var(--ui-divider-color);
      }
    }

    &.--spacer {
      min-height: calc(var(--ui-vertical-spacing, 1rem) * 1.75 + var(--global-inter-content-spacing, 0.75rem) * -2);
    }

    &.--start {
      justify-self: start;
    }

    &.--end {
      justify-self: end;
    }

    &.--center {
      justify-self: center;

      &.--full {
        width: 100%;
        text-align: center;
      }
    }

    &.--boxed {
      background-color: var(--background-color-gray);
      border-radius: var(--ui-roundness-lg, 0);
      padding: 2rem;

      .carousel {
        --slide-gap: 2rem;
        --offset-gap: 2rem;
      }
    }

    &.--inline-button {
      padding-top: 13px;

      @include xs {
        padding-top: 0;
      }
    }
  }

  &__divider {
    height: 1px;
    background: var(--ui-divider-color);
    margin: 10px 0;

    &.--smaller {
      margin: 5px 0;
    }

    &.--zero {
      margin: 0;
    }
  }

  &__spacer {
    height: 1rem;

    @include xxs {
      height: 0.5rem;
    }

    &.--smaller {
      height: .5rem;
    }

    &.--zero {
      height: 0rem;
    }
  }

  &.--flexible {
    display: flex;
    align-items: flex-start;

    &>* {
      flex-grow: 1;
    }
  }

  &.--credit-card {
    display: flex;
    flex-flow: wrap;

    .--card-number {
      flex: 50%;
    }

    .--card-name {
      flex: 40%;
    }

    .--card-month {
      flex: 25%;
      max-width: 25%;
    }

    .--card-year {
      flex: 25%;
      max-width: 25%;
    }

    .--card-code {
      flex: 25%;
      max-width: 25%;
    }

    @include xs {

      .--card-month {
        flex: 50%;
        max-width: unset;
      }

      .--card-year {
        flex: 40%;
        max-width: unset;
      }

      .--card-code {
        flex: 35%;
        max-width: 35%;
      }
    }

    @include xxs {
      .--card-number {
        flex: 100%;
      }

      .--card-name {
        flex: 100%;
      }

      .--card-month {
        flex: 50%;
        max-width: unset;
      }

      .--card-year {
        flex: 40%;
        max-width: unset;
      }

      .--card-code {
        flex: 50%;
        max-width: 160px;
      }
    }

    @include max(340) {
      .--card-month,
      .--card-year {
        flex: 100%;
        max-width: unset;
      }
    }
  }

  &.--mailbox-filters {
    --layout-spacing: .75rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid var(--ui-divider-color);
    margin-bottom: 1.5rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 2fr;

    @include xs {
      display: flex;
      flex-wrap: wrap;
      border-bottom: none;

      .--sent,
      .--month {
        flex-grow: 1;
        min-width: 45%;
      }

      .--email {
        flex-grow: 1;
        max-width: 100%;
        width: 100%;
      }
    }

    @include xxs {

      .--sent,
      .--month,
      .--email {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &.--account-form {
    grid-template-areas: "email password" "fname password" "lname birthday" "marketing marketing";

    @include sm {
      grid-template-areas: "email email" "password password" "fname fname" "lname lname" "birthday birthday" "marketing marketing";
    }

    fieldset.layout {
      grid-template-columns: 2fr 1fr;
    }

    .layout__item {
      &.--email {
        grid-area: email;
      }

      &.--password {
        grid-area: password;
      }

      &.--fname {
        grid-area: fname;
      }

      &.--lname {
        grid-area: lname;
      }

      &.--birthday {
        grid-area: birthday;
        .layout {
          grid-template-columns: 2fr 1fr;
        }
      }

      &.--marketing {
        grid-area: marketing;
        margin-top: 10px;
        padding-top: calc(var(--layout-spacing) + 7px);
        border-top: 1px solid var(--ui-divider-color);
      }

      &.--payment-marketing {
        grid-area: marketing;
        margin-top: 10px;
      }

      &.--submit {
        grid-area: submit;
      }

      &.--required-text {
        grid-area: required;
        margin: calc(var(--layout-spacing) * -1) 0;
      }
    }
  }

  &.--numbered {
    counter-reset: layout-step;
    --layout-number-size: 30px;
    --layout-number-gap: 1rem;
    --layout-number-offset: calc(var(--layout-number-size) + var(--layout-number-gap));
    --layout-number-text-size: 0.875rem;
    padding-left: var(--layout-number-offset);

    @include sm {
      @include to_xs {
        --layout-number-size: 28px;
        --layout-number-gap: .75rem;
      }
    }

    @include xxs {
      --layout-number-gap: .75rem;
      --layout-number-size: 28px;
    }

    &>.layout__item {
      &.--inactive {
        opacity: .7;
        pointer-events: none;

        button,
        a,
        input,
        textarea {
          pointer-events: none;
        }
      }

      counter-increment: layout-step;
      position: relative;

      &::before {
        align-items: center;
        background-color: var(--background-color-dark-gray);
        border-radius: 50%;
        color: var(--color-white);
        font-weight: 600;
        content: counter(layout-step);
        display: inline-flex;
        height: var(--layout-number-size);
        width: var(--layout-number-size);
        font-size: var(--layout-number-text-size);
        justify-content: center;
        position: absolute;
        left: calc(var(--layout-number-offset) * -1);
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}