@use '../../abstracts/' as *;

.password-meter {
  --pm-color-weak: #D83434;
  --pm-color-acceptable: #FD8F17;
  --pm-color-strong: #00B417;
  border-radius: var(--field-roundness);
  box-shadow: inset 0 0 0 1px var(--color-gray-6);
  background: rgba($color-gray-8, .5);
  overflow: hidden;
  margin-top: .1rem;

  &.--weak {
    --pm-strength-color: var(--pm-color-weak);
    --pm-strength: 25;
  }

  &.--acceptable {
    --pm-strength-color: var(--pm-color-acceptable);
    --pm-strength: 75;
  }

  &.--strong {
    --pm-strength-color: var(--pm-color-strong);
    --pm-strength: 100;
  }

  &__bar {
    transform: scaleX(1.025);
    height: 3px;
  }

  &__progress {
    display: block;
    height: 3px;
    transform-origin: left;
    transform: scaleX(calc(1 * (var(--pm-strength, 0) / 100)));
    @include trns;
    background-color: var(--pm-strength-color, #0000);
  }

  &__hints {
    display: flex;
    padding: 11px 12px 13px;
    gap: 5px 10px;
    flex-flow: wrap;

    @include max(400) {
      grid-auto-flow: row;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
    }
  }
}

.password-hint {
  display: grid;
  grid-template-columns: 21px 1fr;
  align-items: center;

  &__icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: relative;

    &::after {
      content: "";
      opacity: 1;
      position: absolute;
      top: 8px;
      height: 0px;
      border-left: 2px var(--color-gray-2) solid;
      border-bottom: 1px var(--color-gray-2) solid;
      left: 15%;
      transform: translateY(0px) rotate(0deg) scale(1) scaleX(.8) scaleY(.8);
      width: 70%;
      transition: all .2s var(--transition-delay, 0s) var(--easing), border-width 0s ease;

      .password-hint.--passed & {
        transform: translateY(-5px) rotate(315deg) scale(1) scaleX(.8) scaleY(.8);
        border-bottom: 2px var(--color-form-valid) solid;
        border-color: var(--color-form-valid);
        width: 85%;
        height: 7px;
      }
    }
  }

  &__label {
    padding-top: 1px;
    letter-spacing: .2px;
    font-size: 0.825rem;
    color: var(--color-gray-1);
    @include trns;
  }
}