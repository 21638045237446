@use '../abstracts' as *;

.dialog.--spinner {
  --dialog-spacing: 2rem;
  .dialog__overlay-btn {
    top: .75em;
    right: .75em;
  }
}

.spinner {
  width: 250px;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 1rem;
  letter-spacing: 0.4px;
  --loader-progress: 0%;
  --loader-weight: 8px;
  --loader-color: var(--color-form-info);

  &.--completed {
    --loader-color: var(--color-form-valid);
  }

  &.--error {
    --loader-color: var(--color-form-error);
  }

  &__heading {
    font-size: 1.35rem;
    font-weight: 500;
    letter-spacing: .2px;
  }
  &__container {
    width: 100%;
    display: grid;
    gap: 6px;
  }

  &__image {
    position: relative;
    display: grid;
    place-content: center;
    width: 100%;
    .lazy-load {
      width: 128px;
    }
    svg.spinner__image-icon {
      width: 82px;
    }
    &-icon {
      height: auto;
      @include trns(.3s, 2);
      .spinner.--error & {
        opacity: .3;
      }
    }
  }

  &__error-icon {
    position: absolute;
    top: calc(50% - 21px);
    left: calc(50% - 21px);
    width: 42px;
    height: 42px;
    display: none;
    --scale-value: 1.35;
    animation: scaleIn .5s linear forwards;
    @media (prefers-reduced-motion) {
      animation: scaleIn 0s linear forwards;
    }
    .spinner.--error & {
      display: block;
    }
  }

  &__title {
    font-weight: 500;
    &.--red {
      @include trns;
      color: var(--color-form-error);
    }
  }

  &__progress {
    width: 100%;
    height: var(--loader-weight);
    position: relative;
    border-radius: 4px;

    &:after {
      content: '';
      z-index: 1;
      border-radius: 4px;
      @include setInset;
      @include loader(#00000000, var(--loader-color));
      opacity: .2;
    }

    &:before {
      content: '';
      z-index: 1;
      border-radius: 4px;
      @include setInset;
      background-color: var(--loader-color);
      opacity: .1;
    }

    &-bar {
      position: relative;
      z-index: 2;
      border-radius: 4px;
      height: var(--loader-weight);
      width: var(--loader-progress, 0);
      background-color: var(--loader-color);
      @include trns(.2s, 1);

      .spinner.--completed & {
        @include trns(.1s, 1);
      }
    }
  }
}