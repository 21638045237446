@use "./../abstracts" as *;

:root {
    // Layout
    --container: 1180px;
    --gap: 10px;
    --offset-gap: 20px;
    --container-gaps: calc(var(--container) + var(--offset-gap) * 2);
    --spacing: 3rem;
    --page-offset: calc(var(--header-height, 0px) + var(--top-banner-height, 0px));
    --ui-roundness: 4px;
    --ui-roundness-lg: 10px;
    --ui-backdrop: #{rgba($color-gray-1, 0.2)};
    --ui-box-shadow: 0 4px 24px #{rgba($color-gray-1, 0.2)};
    --ui-divider-color: #{rgba($color-gray-1, 0.15)};
    --ui-divider-solid-color: var(--color-platinum);
    --ui-box-primary-shadow: 0 4px 24px #{rgba($color-primary-lighter, 0.2)};
    --nav-height: 60px;
    --nav-offset: 80px;
    --masthead-size: 90px;
    --header-sticky: 1;

    @include lg {
        --container: 1090px;
    }

    @include md {
        --spacing: 2.5rem;
        --container: 940px;
    }

    @include sm {
        --nav-offset: 60px;
        --container: 730px;
        --spacing: 2.4rem;
    }

    @include xs {
        --container: 540px;
        --spacing: 2rem;
    }

    @include xxs {
        --container: 100%;
        --container-gaps: var(--container);
    }

    @include max(350) {
        --offset-gap: 16px;
        --gap: 8px;
    }

    @media (max-height: 500px) {
        --header-sticky: 0;
    }
}

html {
    --easing: none;
    --reveal-easing: none;
    --lazy-easing: none;
    -webkit-text-size-adjust: none;

    &.--init {
        --easing: cubic-bezier(0, 0.275, 0.125, 1);
        --reveal-easing: cubic-bezier(0.09, 0.7, 0.59, 1);
        --lazy-easing: cubic-bezier(.53, -0.01, .87, .71);

        @media (prefers-reduced-motion) {
            --easing: none;
            --reveal-easing: none;
            --lazy-easing: none;
        }
    }
}

body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-white);
    color: var(--color-base);
    font-family: var(--font) !important;
    font-size: 16px;
    line-height: 1.4;

    &.--scroll-freeze {
        padding-right: var(--scrollbar-gap, 0px) !important;
        overflow: hidden !important;
    }

    &:not(.--scroll-freeze, .modal-open):not(.pdp-page) {
        overflow: visible !important;
    }

    &:not(.favorites-page) .product__favorites {
        display: none;
    }
}