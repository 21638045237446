@use "../../abstracts/variables/colors.scss" as *;

:root {
  // Color Palette
  --color-cardinal: #c42939; //primary
  --color-crimson: #d50032; //lighter
  --color-upsdell-red: #ae1e2d; //darker
  --color-crimson-ua: #961d29; //darkest
  --color-emerald: #23ce6b;
  --color-middle-green: #4f7255;
  --color-independence: #374a67;
  --color-forest-green-granola: #63a375;
  --color-green-pigment: #40a55c;
  --color-green-bold: #33844a;
  --color-tigers-eye: #c06800;
  --color-orange-brown: #b36200;
  --color-red-violet: #d8008f;
  --color-english-violet: #4d2d52;
  --color-purpureous: #9742a5;
  --color-true-blue: #2c65bb;
  --color-jet: #333333;
  --color-dim-gray: #6e6e6e;
  --color-spanish-gray: #949494;
  --color-x-11-gray: #b7b7b7;
  --color-platinum: #e8e8e8;
  --color-cultured: #f0f0f0;
  --color-snow: #f6f4f4;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-lightest-gray: #e3e3e3;
  --color-timberwolf: #d2d2d2;
  --color-anti-flash-white: #ececec;
  --color-school-bus-yellow: #ffdf00;

  // Theme Palette
  --color-base: var(--color-jet);

  --color-gray-1: var(--color-jet); // darker
  --color-gray-2: var(--color-dim-gray);
  --color-gray-3: var(--color-spanish-gray);
  --color-gray-4: var(--color-x-11-gray);
  --color-gray-4-5: var(--color-timberwolf);
  --color-gray-5: var(--color-lightest-gray);
  --color-gray-6: var(--color-anti-flash-white);
  --color-gray-7: var(--color-cultured);
  --color-gray-8: var(--color-snow); // lighter
  --background-color-gray: var(--color-snow);
  --background-color-dark-gray: var(--color-jet);

  --color-primary: var(--color-cardinal);
  --color-primary-lighter: var(--color-crimson);
  --color-primary-darker: var(--color-upsdell-red);
  --color-primary-darkest: var(--color-crimson-ua);
  --color-primary-alpha-90: #{rgba($color-primary, 90%)};
  --color-primary-alpha-80: #{rgba($color-primary, 80%)};
  --color-primary-alpha-70: #{rgba($color-primary, 70%)};
  --color-primary-alpha-60: #{rgba($color-primary, 60%)};
  --color-primary-alpha-50: #{rgba($color-primary, 50%)};
  --color-primary-alpha-40: #{rgba($color-primary, 40%)};
  --color-primary-alpha-30: #{rgba($color-primary, 30%)};
  --color-primary-alpha-25: #{rgba($color-primary, 25%)};
  --color-primary-alpha-20: #{rgba($color-primary, 20%)};
  --color-primary-alpha-15: #{rgba($color-primary, 15%)};
  --color-primary-alpha-10: #{rgba($color-primary, 10%)};
  --color-primary-alpha-07: #{rgba($color-primary, 7%)};
  --color-primary-alpha-05: #{rgba($color-primary, 5%)};
  --color-primary-alpha-0: #{rgba($color-primary, 0%)};

  --color-new: var(--color-green-bold);
  --color-free: var(--color-true-blue);
  --color-animated: var(--color-primary);
  --color-music: var(--color-purpureous);
  --color-foil: var(--color-orange-brown);
  --color-glitter: var(--color-red-violet);
  --color-warning: var(--color-school-bus-yellow);
  --color-warning-faded: #{lighten($color-school-bus-yellow, 22%)};
  --color-info-bg: #ecf5ff;
  --color-info-text: #1f4472;
  --color-info-link: var(--color-info-text);
  --color-info-link-hover: var(--color-info-text);
  --color-success-text: #206A2A;
  --color-success-link: #185520;
  --color-success-link-hover: #185520;
  --color-rating: #EFB51F;
  --color-favorites: var(--color-crimson);
  --color-outline: var(--color-base);
  // Form
  --color-form-info: #246DBE;
  --color-form-valid: #008512;
  --color-form-error: #ae1010;
}