@use '../abstracts/' as *;

.bidirectional-sticky{
  position: sticky;
  top: calc(var(--main-bar-height) * var(--header-sticky) + var(--spacing) * 1);
  @include sm {
    top: calc(var(--nav-height) * var(--header-sticky) + var(--spacing) * 1);
  }
  &.--sticky-bottom{
    position: sticky;
    top: calc(var(--client-height, 100vh) - var(--el-height, 100%) - var(--spacing));
  }
}
