@use '../../abstracts' as *;

.header-mobile {
  --header-mobile-width: clamp(0px, var(--container-gaps), calc(100vw - 10px));
  --header-mobile-menu-max-width: 360px;

  @include max(420) {
    --header-mobile-width: clamp(0px, var(--container-gaps), 100%);
    --header-mobile-menu-max-width: 90%;
  }

  &__bar {
    position: relative;
    z-index: 0;
    background: var(--color-white);
    box-shadow: 0 15px 45px 0 #e0e0e038, 0 1px 0 0 #dfdfdf5e;
  }

  &__wrapper {
    height: var(--nav-height);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    max-width: var(--header-mobile-width);
    width: 100%;
    margin: 0 auto;
  }

  &__main,
  &__member {
    display: flex;
    align-items: center;
    gap: 10px;

    @include max(420) {
      gap: 2px;
    }

    @include max(350) {
      gap: 0px;
    }
  }

  &__main {
    justify-content: flex-start;

    .mobile-menu-button {
      &:first-child {
        padding-left: var(--offset-gap);
        padding-right: 10px;

        &:before {
          left: unset;
          right: 0;
          width: calc(100% - var(--offset-gap) + 10px);
        }
      }
    }
  }

  &__member {
    justify-content: flex-end;

    .mobile-menu-button {
      &:last-child {
        padding-right: var(--offset-gap);
        padding-left: 10px;

        &:before {
          width: calc(100% - var(--offset-gap) + 10px);
        }
      }
    }
  }

  &__logo {
    opacity: 1;
    position: relative;
    align-content: center;
    height: 100%;
    min-width: 36px;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;

    a {
      outline-offset: 0px;
      padding: 0px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: var(--color-primary-lighter);
      height: var(--nav-height);
      @include setTransition(0.1s, 2, height);

      svg {
        color: var(--color-primary-lighter);
        height: 43px;
        max-height: 100%;
        width: auto;
        position: relative;
        top: 1px;
        @include trns;
      }

      &:hover {
        text-decoration: none;

        svg {
          color: var(--color-primary-lighter);
        }
      }
    }
  }
}

.mobile-menu {
  z-index: -1;
  display: grid !important;
  position: fixed;
  left: 0;
  width: 100%;
  top: calc(var(--header-mobile-offset, 0px));
  height: calc(100% - var(--header-mobile-offset, 0px));
  opacity: 0;
  padding-top: var(--nav-height);
  pointer-events: none;
  will-change: opacity;
  background: var(--ui-backdrop);
  @include setBlur(21px, var(--ui-backdrop));
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  &.--with-scrollbar {
    overflow-y: scroll;
  }

  @include trns;

  .header-mobile.--open & {
    opacity: 1;
    pointer-events: all;
  }

  &__backdrop {
    position: absolute;
    height: 100%;
    width: calc(100% - var(--header-mobile-menu-max-width) + (50vw - 50%));
    left: var(--header-mobile-menu-max-width);
    top: 0;
    z-index: -1;
  }

  &__holder {
    position: relative;

    .header-mobile.--drawer-is-active & {
      overflow: hidden;
    }
  }

  &__wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: var(--header-mobile-width);
    display: grid;
    gap: 2rem;
    align-content: stretch;
    min-height: 100%;
    position: relative;
    will-change: transform;
    transform: translateX(-20px);
    @include trns;

    .header-mobile.--open & {
      transform: translateX(0px);
    }
  }

  &__inner {
    width: var(--header-mobile-width);
    max-width: var(--header-mobile-menu-max-width);
    display: grid;
    align-content: flex-start;
    gap: 2rem;
    padding: 3rem var(--offset-gap);

    @media (max-height: 540px) {
      padding: 1rem var(--offset-gap) 2rem;
    }

    background: var(--color-white);
    box-shadow: -50vw 0 0 50vw var(--color-white);
  }

  &__content {
    display: grid;
    align-content: flex-start;
    gap: 2rem;
    @include trns(.3s, 2);
    opacity: 1;

    .header-mobile.--drawer-is-active & {
      transform: translateX(-50%);
      opacity: 0;
    }
  }
}


.mobile-menu-button {
  --button-gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 4px 4px;
  height: var(--nav-height);
  min-width: 40px;
  border: none;
  outline-color: var(--color-gray-1);
  cursor: pointer;
  position: relative;
  font-size: 0.9375rem;
  font-weight: 500;
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--color-primary-alpha-0);
  @include trns(.1s);

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: var(--color-primary-lighter);
    opacity: 0;
    @include trns(.3s);
  }

  &:focus-visible {
    color: var(--color-primary-lighter);
  }

  &:active {
    color: var(--color-primary-lighter);

    &:before {
      opacity: .1;
      @include trns(0s);
    }
  }

  &__text {
    &:not(:first-child) {
      margin-left: var(--button-gap);
    }

    &:not(:last-child) {
      margin-right: var(--button-gap);
    }
  }

  &__icon {
    display: flex;
    position: relative;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &.--account {
    --button-gap: 10px;
    padding-left: 10px;

    .mobile-menu-button__icon {
      margin-right: -4px;
      margin-left: -4px;
    }
  }
}

.morphing-trigger {
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    height: 24px;
    width: 100%;
    --move-unit: 7px;
    --rotate: 0deg;
    --bars-rotate: 0deg;
    --rotate-delay: 0s;
    --move-delay: 0.2s;
    --button-size: 35px;
    --not-opened: 1;

    .header-mobile.--open & {
      --rotate-delay: 0.2s;
      --move-delay: 0s;
      --move-unit: 0px;
      --rotate: 180deg;
      --bars-rotate: 45deg;
      --not-opened: 0;
    }

    color: var(--color-gray-1);
    position: relative;
    transform: rotate(var(--rotate));
    transition: .2s var(--easing) var(--rotate-delay);
    color: var(--color-gray-1);

    .mobile-menu-button:active & {
      transition: transform .2s var(--easing) var(--rotate-delay), color 0s var(--easing);
      color: var(--color-primary-lighter);
    }

  }

  span {
    position: absolute;
    width: 24px;
    height: 2px;
    background: currentColor;
    left: calc(50% - 24px / 2);
    will-change: transform, opacity, width, bottom, top;
    transition: transform .2s var(--easing) var(--rotate-delay),
      top .2s var(--easing) var(--move-delay),
      opacity .2s var(--easing) var(--move-delay),
      width .2s var(--easing) var(--move-delay),
      bottom .2s var(--easing) var(--move-delay);

    &:nth-child(1) {
      transform: translateY(-50%) rotate(var(--bars-rotate));
      top: calc(50% - var(--move-unit));
    }

    &:nth-child(2) {
      transform: translateY(-50%);
      top: 50%;

      .header-mobile.--open & {
        opacity: 0;
      }
    }

    &:nth-child(3) {
      transform: translateY(50%) rotate(calc(0deg - var(--bars-rotate)));
      bottom: calc(50% - var(--move-unit));
      width: calc(24px - 6px * var(--not-opened));
    }
  }
}

.mobile-menu-item {
  margin: 0 calc(var(--offset-gap) * -1);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    width: calc(100% - var(--offset-gap));
    right: 0px;
    bottom: 0;
    height: 1px;
    z-index: 2;
    background: var(--ui-divider-color);
    @include trns;
  }

  &__arrow {
    position: absolute;
    right: calc(var(--offset-gap) + 4px);
    top: calc(50% - 7px);
    height: 12px;
    width: 12px;
    pointer-events: none;
    border-bottom: 2px var(--arrow-color) solid;
    border-right: 2px var(--arrow-color) solid;
    @include trns;
    transform: translateY(calc(var(--active, 0) * 4px)) rotate(calc(45deg + var(--active, 0) * 180deg)) scale(.625);
  }

  &__link {
    --arrow-color: var(--color-gray-1);
    background-color: transparent;
    border: 0px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 15px var(--offset-gap) 13px;
    font-size: 1rem;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: .4px;
    text-decoration: none;
    position: relative;
    z-index: 0;
    @include trns;

    &:before {
      content: '';
      @include setInset(0px);
      pointer-events: none;

      @include min(421) {
        border-top-left-radius: calc(var(--ui-roundness, 0px) + 2px);
        border-bottom-left-radius: calc(var(--ui-roundness, 0px) + 2px);
      }

      background-color: var(--color-primary-alpha-10);
      opacity: 0;
      @include trns;
    }

    &:active {
      @include trns(0s);
      color: var(--color-primary-lighter);

      &:before {
        @include trns(0s);
        opacity: 1;
      }
    }

    &:active,
    .mobile-menu-item.--active & {
      --active: 1;
      --arrow-color: var(--color-primary-lighter);
      color: var(--color-primary-lighter);
      @include trns(0s);

      &::after {
        transition: background 0s ease, transform .2s var(--easing), opacity .2s var(--easing);
      }
    }

    &:focus-visible {
      z-index: 4 !important;
    }
  }

  &__dropdown {
    height: 0px;
    overflow: hidden;
    opacity: .25;
    @include trns(.3s, 2);

    .mobile-menu-item.--active & {
      height: var(--dropdown-height, auto);
      opacity: 1;
    }
  }

  &:not(.--has-dropdown) {
    .mobile-menu-item {
      &__link {
        &:focus {
          color: var(--color-primary-lighter);
        }
      }
    }
  }

}

.mobile-dropdown {
  &__list {
    padding: 1px var(--offset-gap) 1rem;
  }

  &__grid {
    padding: 6px var(--offset-gap) 1.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(var(--gap) * 1.5);

    .mobile-dropdown__item {
      grid-column: 1 / span 2;
    }
  }

  &__link,
  &__media-link {
    &:before {
      content: '';
      @include setInset(0px);
      pointer-events: none;
      border-radius: var(--ui-roundness, 0px);
      background-color: var(--color-primary-alpha-10);
      opacity: 0;
      @include trns;
    }

    &:active {
      @include trns(0s);
      color: var(--color-primary-lighter);

      &:before {
        @include trns(0s);
        opacity: 1;
      }
    }

    &:focus {
      color: var(--color-primary-lighter);
    }
  }

  &__link {
    display: flex;
    text-decoration: none;
    line-height: 1.4em;
    background: transparent;
    border: none;
    cursor: pointer;
    align-items: center;
    font-size: .875rem;
    width: 100%;
    letter-spacing: .4px;
    padding: 8px var(--offset-gap) 7px;
    position: relative;

    .css-icon.--arrow {
      margin-left: 8px;
      --scale: 0.5;
      --icon-offset: 1px;
    }
  }

  &__media-link {
    display: grid;
    gap: 7px;
    text-align: center;
    text-decoration: none;
    line-height: 1.4em;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.4px;
    position: relative;
    @include trns;

    &:before {
      @include setInset(-3px);
      border-radius: calc(var(--ui-roundness, 0px) + 3px);
    }
  }
}

.account-favorites {
  &.--has-items {
    svg {
      // clip-path: polygon(0 0,100% 0,100% 100%,0 100%,0 110%,55% 73%,37% 36%,0 36%);
    }
  }

  &__counter {
    opacity: 0;
    transform: scale(.75);
    pointer-events: none;
    color: var(--color-white);
    background-color: var(--color-primary-lighter);
    border-radius: 5px;
    position: absolute;
    top: 12px;
    right: 18px;
    min-width: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 2px 3px 1px;
    line-height: 1em;
    font-size: .5425rem;
    letter-spacing: 1px;

    @include trns;

    .account-favorites.--light & {
      background-color: var(--color-white);
      color: var(--color-primary-lighter);
    }

    .account-favorites.--has-items & {
      opacity: 1;
      transform: scale(1);
    }
  }
}