@use "../../abstracts" as *;


:root {
  --top-banner-height: 0px;
  --top-bar-height: 36px;
  --main-bar-height: 78px;
  --main-bar-height-scrolled: 64px;
  --link-size: 1rem;
  --link-padding: 13px;
  --link-spacing: .5px;
  --header-height: calc(var(--top-bar-height) + var(--main-bar-height));
  --scrolled-ratio: 0;
  --scrolled-ratio-inversed: clamp(0, calc(1 - var(--scrolled-ratio, 0)), 1);

  @include lg {
    --top-bar-height: 34px;
    --main-bar-height: 78px;
    --link-padding: 12px;
    --link-spacing: .5px;
  }

  @include md {
    --top-bar-height: 32px;
    --main-bar-height: 74px;
    --main-bar-height-scrolled: 64px;
    --link-padding: 10px;
    --link-spacing: .5px;
  }
}

.skip-links {
  a {
    left: 0px;
    position: absolute;
    top: -40px;
    z-index: 1000;
    color: var(--color-white);
    transition: none;
    border-radius: var(--ui-roundness);
    background-color: var(--color-gray-1);
    --background-hover: var(--color-gray-1);
    --background-active: var(--color-gray-1);
    outline: none;
    &::before {
      content: '';
      pointer-events: none;
      @include setInset(1px);
      border-radius: calc(var(--ui-roundness, 0) + var(--inset-value));
    }
    &:focus-visible {
      position: fixed;
      top: 0px;
      &::before {
        box-shadow: 0 0 0 1px var(--color-white) , 0 0 0 2px var(--color-base);
      }
    }
  }
}

.page-wrapper>.header-wrapper {
  position: sticky;
  top: calc(var(--top-bar-height) * -1);
  z-index: 100;
  height: calc(var(--top-bar-height) + var(--main-bar-height));

  @include sm {
    height: var(--nav-height);
    top: 0px;
  }

  @media (max-height: 500px) {
    position: relative;
    top: 0px;
  }

  body.--static-nav & {
    top: 0px;
    position: relative;
  }

  &.slim {
    position: relative;
  }
}

.masthead {
  width: var(--masthead-size);
  position: absolute;
  top: 0;
  height: 100%;
  left: calc(50% - var(--masthead-size) / 2);
  // background-color: var(--color-primary-lighter);
  z-index: -1;
}

.slim-header {
  display: grid;
  place-content: center;

  &__grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr var(--masthead-size) 1fr;

    &>* {
      padding: 2.3rem 0rem 2rem;
      display: grid;
      align-content: center;
    }
  }

  &__center {
    display: grid;
    // padding: 1.2rem 0rem 1rem;
    place-content: center;

    a {
      outline-offset: 4px;
      display: grid;
      color: var(--color-primary-lighter);
    }
  }
}

.header {
  width: 100%;
  left: 0px;
  top: 0px;
  position: relative;
  height: calc(var(--top-bar-height) * var(--scrolled-ratio-inversed) + var(--main-bar-height));

  &__bar {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap);
    z-index: 1;
    position: relative;

    .--left {
      margin-left: -0.65rem;
      margin-right: auto;
    }

    .--right {
      margin-right: -0.65rem;
      margin-left: auto;
    }

    .--white {
      color: var(--color-white);

      @supports ((font: -apple-system-body) and (-webkit-appearance: none)) or (-moz-appearance:none) {
        a,
        button {
          &:focus-visible {
            outline-color: var(--color-white);
            outline-offset: -2px;
          }
        }
      }
    }
  }

  &__menu {
    margin-right: auto;
  }

  &__top {
    top: 0px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    left: 0;
    width: 100%;
    opacity: 1;
    z-index: 10;
    background: var(--color-gray-1);

    nav {
      display: flex;
      align-items: center;
    }

    &.--right {
      justify-content: flex-end;
    }

    a,
    button {
      &:focus-visible {
        outline-color: var(--color-gray-1);
        outline-offset: -1px;
      }
    }
  }

  &__main {
    --variable-header-height: calc(var(--main-bar-height) - (var(--main-bar-height) - var(--main-bar-height-scrolled)) * var(--main-bar-ratio, 0));
    transform: translateY(calc((var(--top-bar-height) + var(--top-banner-height, 0px)) * var(--scrolled-ratio-inversed)));
    z-index: 999;
    width: 100%;
    position: relative;
    background: var(--color-white);
    height: var(--variable-header-height);

    a,
    button {
      &:focus-visible {
        outline-color: var(--color-gray-1);
      }
    }

    &:after {
      content: "";
      z-index: 0;
      background: var(--color-white);
      box-shadow: 0 15px 45px 0 #e0e0e038, 0 1px 0 0 #dfdfdf5e;
      @include setInset;
    }

    &:before {
      content: "";
      width: 100%;
      height: var(--submenu-height, 350px);
      pointer-events: none;
      opacity: 0;
      z-index: -1;
      position: absolute;
      top: 100%;
      transform: translateY(-10px);
      transition: all 0.2s var(--reveal-easing);

      @include md {
        height: var(--submenu-height, 300px);
      }

      html.--init & {
        background: var(--color-white);
        box-shadow: 0 15px 45px 0 #c3c3c338, 0 1px 0 0 #dfdfdf5e,
          0 50vh 0 50vh var(--ui-backdrop, #0002);
      }
    }

    &.--menu-entered {
      &:before {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }

  &__logo {
    opacity: 1;
    position: relative;
    align-content: center;
    height: 100%;
    min-width: 46px;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;

    a {
      outline-offset: 0px;
      margin-left: -10px;
      padding: 11px 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--color-primary-lighter);
      height: var(--variable-header-height);
      @include setTransition(0.1s, 2, height);

      svg {
        color: var(--color-primary-lighter);
        height: 52px;
        max-height: 100%;
        width: auto;
        position: relative;
        top: 1px;
        @include trns;

        @include md {
          height: 48px;
        }
      }

      &:hover {
        text-decoration: none;
      }

      @include md {
        padding: calc(var(--gap) * 0.85) 10px;
      }
    }
  }

  &__secondary {
    display: flex;
    align-items: center;
    margin-right: -6px;
  }

  @supports ((font: -apple-system-body) and (-webkit-appearance: none)) or (-moz-appearance:none) {
    &__logo a,
    &__menu a.menu-item__overlay {
      &:focus-visible {
        outline-offset: -3px;
        border-radius: var(--ui-roundness);
      }
    }
  }

  &__main {
    &.header-slim {
      transform: none;

      &:after {
        background: var(--background-color-dark-gray);
      }

      .header__bar {
        justify-content: start;
      }

      .header-slim-copy {
        &.text-block {
          padding-left: calc(var(--offset-gap) * .5);

          &.--smaller {
            @include max(990) {
              --scale: .82;
            }

            @include max(796) {
              --scale: .78;
            }
          }

          p {
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          @include max(990) {
            padding-left: 0;
          }
        }
      }

      .header-slim-contact {
        &.text-block {
          margin-left: auto;

          p {
            letter-spacing: .5px;
            margin: 0;
          }
        }
      }

      .header__logo {
        @include xs {
          margin: 0 auto;
        }
      }
    }
  }
}

.page-wrapper>.header-wrapper {
  &.slim {
    top: 0;
    height: var(--main-bar-height);
  }
}

.menu {
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.--white {
    .menu-item {
      color: var(--color-white);
    }
  }

  &.--has-separators {
    .menu-item {
      position: relative;

      &:not(:last-child) {
        &:before {
          content: "";
          position: absolute;
          right: 0;
          opacity: .3;
          width: 1px;
          height: 0.9rem;
          background: var(--color-white);
          pointer-events: none;
          top: calc(50% - 0.45rem);
        }
      }
    }
  }
}

.menu-item {
  @include trns(0.1s);

  &__title {
    display: flex;
    align-items: center;
    font-size: 1rem;
    letter-spacing: .2px;
    pointer-events: none;

    @include md {
      font-size: .875rem;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  &__submenu {
    --max-rows: 9;
    display: block !important;
    position: fixed;
    z-index: -1;
    width: 100%;
    left: 0;
    top: var(--variable-header-height);
    opacity: 0;
    pointer-events: none;

    .header__main:not(.--menu-entered) & {
      transform: translateY(-10px);
    }

    @include trns(0.2s, 2);
    will-change: transform,
    opacity;
  }

  &__link {
    font-size: 12px;
    padding: 2px 0.85rem 0;
    height: var(--top-bar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .5px;
    background: transparent;
    border: none;
    text-decoration: none;
    cursor: pointer;
    line-height: 1.6em;
    @include trns(0.1s);

    @include lg {
      padding: 2px 0.85rem 0;
    }

    span {
      position: relative;
      -webkit-font-smoothing: antialiased;

      &:before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: currentColor;
        transform: translateY(3px) scale(0.8);
        opacity: 0;
        @include trns(0.1s);
      }
    }

    &:hover,
    &:focus-within {
      span {
        &:before {
          transform: translateY(0px) scale(1);
          opacity: 1;
        }
      }
    }

    &:active {
      background: #0005;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    height: var(--variable-header-height);
    padding: 3px var(--link-padding) 0;
    font-size: var(--link-size);
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    color: var(--color-gray-1);
    letter-spacing: 0.25px;
    text-transform: none;

    .menu-item.--has-submenu & {
      @media not all and (any-hover: hover) {
        &:active {
          @include trns(0.05s);
          background-color: var(--color-primary-alpha-10);
        }
      }
    }
  }

  &__overlay {
    @include setInset;
    @include trns;

    .menu-item.--has-submenu & {
      pointer-events: none;

      @media (any-hover: hover) {
        pointer-events: all;
      }
    }

    &:active {
      @include trns(0.05s);
      background-color: var(--color-primary-alpha-10);
    }
  }

  &__trigger {
    display: inline-flex;
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: -4px;
    top: 0;
    margin-left: 1px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    @include trns;

    @include md {
      top: -1px;
    }

    .menu-item.--has-submenu & {
      pointer-events: none;

      @media (any-hover: hover) {
        pointer-events: all;
      }
    }

    &:before {
      content: "";
      pointer-events: none;
      width: 10px;
      height: 10px;
      position: absolute;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      transform: translateY(-1px) rotate(-45deg) scale(0.62);
      border: 2px currentColor solid;
      opacity: 0.6;
      border-top: none;
      border-right: none;
      @include trns;
    }

    &:after {
      pointer-events: none;
      content: "";
      @include setInset(0px);
      border-radius: 50%;
      background-color: currentColor;
      opacity: 0;
      transform: scale(0.3);
      @include setTransition(0.1s, 1, opacity, transform);
      z-index: -1;
    }

    &:hover {
      &::after {
        opacity: 0.08;
        transform: scale(1);
      }
    }

    &:focus-visible {
      translate: 3px;

      &::after {
        opacity: 0.08;
        transform: scale(1);
      }
    }

    &:active {
      &::after {
        @include setTransition(0s, 1, opacity, transform);
        opacity: 0.16;
      }
    }
  }

  &:hover {
    .menu-item {
      &__inner {
        color: var(--color-primary-lighter);
      }

      &__trigger {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &:focus-visible {
    .menu-item {
      &__inner {
        color: var(--color-primary-lighter);
      }

      &__trigger {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &.--active {
    .menu-item {
      &__inner {
        color: var(--color-primary-lighter);
      }

      &__submenu {
        opacity: 1;
        transform: translate(0px);
        pointer-events: all;
      }

      &__trigger {
        transform: rotate(180deg);

        &:before {
          transform: translateY(-2px) rotate(-45deg) scale(0.62);
          opacity: 1;
        }
      }
    }
  }
}

.menu-button {
  --button-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  min-width: 32px;
  border: none;
  outline-color: var(--color-gray-1);
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-size: 0.91rem;
  font-weight: 500;
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--color-primary-alpha-0);
  @include trns;

  &__text {
    &:not(:first-child) {
      margin-left: var(--button-gap);
    }

    &:not(:last-child) {
      margin-right: var(--button-gap);
    }
  }

  &:focus-visible {
    color: var(--color-primary-lighter);
  }

  &:hover,
  &:focus {
    color: var(--color-primary-lighter);
  }

  &__icon {
    display: flex;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &.--search {
    border-radius: 50%;
    width: 40px;
    height: 40px;

    svg {
      width: 30px;
      height: 30px;
    }

    &:before {
      content: "";
      @include setInset;
      border-radius: 50%;
      transform: scale(0.8);
      background: transparent;
      @include trns;
    }

    &:focus-visible {
      &:before {
        transform: scale(1);
        background-color: var(--color-primary-alpha-15);
      }
    }

    &:hover,
    &:focus {
      &:before {
        transform: scale(1);
        background-color: var(--color-primary-alpha-15);
      }
    }

    &:active {
      &:before {
        @include trns(0);
        transform: scale(1);
        background-color: var(--color-primary-alpha-25);
      }
    }
  }

  &.--account {
    padding-left: calc(var(--gap) * 0.75);
  }
}

.submenu {
  --divider-color: var(--ui-divider-color);
  display: flex;
  padding: 2rem 0;
  gap: 2.4rem;

  #apps-submenu &,
  #digital-gifts-submenu & {
    gap: 2rem;
    --divider-color: transparent;
  }

  @include sm {
    gap: 0rem;
    grid-template-columns: 1fr;
  }

  &__title {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0.65rem;
    font-weight: 500;
    font-size: .825rem;
  }

  &__column {
    position: relative;
    flex: 1;

    &.--column-divider-right {
      &:before {
        content: "";
        width: 1px;
        background: var(--divider-color);
        height: 100%;
        top: 0;
        position: absolute;
        pointer-events: none;
        right: -1.2rem;
      }
    }

    &.--has-columns {
      .submenu {
        &__menu {
          display: grid;
          grid-auto-columns: max-content;
          grid-auto-flow: column;
          grid-gap: 3px 2.2rem;
          grid-template-rows: repeat(var(--max-rows, 9), 1fr) !important;

          @include lg {
            padding-right: 0;
            grid-gap: 0px 1.2rem;
          }
        }
      }
    }

    &.--image {
      min-width: 250px;
      max-width: calc((100% - 2rem * 3) / 4);

      @include lg {
        min-width: 200px;
      }

      @include md {
        min-width: 160px;
      }

      background: transparent;
    }
  }

  &__menu {
    display: grid;
    grid-gap: 3px 1.8rem;

    @include lg {
      padding-right: 0;
      grid-gap: 2px 1.2rem;
    }

    li {
      display: block;
      margin: 0px;

      @include lg {
        max-width: 190px;
      }

      a {
        color: var(--color-base);
        display: block;
        letter-spacing: 0.4px;
        padding: 4px 10px;
        margin-left: -10px;
        text-decoration: none;
        outline-offset: 2px;
        letter-spacing: .2px;
        font-size: .835rem;

        @include md {
          letter-spacing: .1px;
        }

        &.--highlighted {
          color: var(--color-primary-lighter);
          text-decoration: underline;

          span:before {
            display: none;
          }

          &:hover {
            color: var(--color-primary-darkest);
          }
        }

        &>span {
          position: relative;

          &:before {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            transform: translateY(8px);
            opacity: 0;
            background-color: var(--color-base);
            pointer-events: none;
            left: 0px;
            bottom: -2px;
            @include setTransition(0.2s, 1, opacity, transform);
          }
        }

        &:hover,
        &:focus {
          span:before {
            transform: translateY(0px);
            opacity: 1;
          }
        }

        &:focus {
          span:before {
            @include setTransition(0s, 1, opacity, transform);
          }
        }

        &.highlighted {
          font-weight: 600;
          color: var(--color-secondary);

          span:before {
            background-color: var(--color-secondary);
            height: 1px;
          }
        }
      }
    }
  }
}

.submenu-media {
  border-radius: var(--ui-roundness, 0px);
  outline-offset: 6px;
  text-decoration: none;
  display: grid;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &__image {
    border-radius: var(--ui-roundness, 0px);
    overflow: hidden;
  }

  &__caption {
    font-size: 1rem;
    font-weight: 500;

    &:not(:empty) {
      margin-top: 0.75rem;
      padding-bottom: 1px;
    }
  }
}