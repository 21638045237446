@use "./../abstracts" as *;

.newsletter {
    --field-background: white;
    --s-ratio-bot-override: 0.75;
    --s-ratio-top-override: 0.75;
    --color-outline: var(--color-gray-1);
    --ui-input-outline: #8C8C8C;
    color: var(--color-gray-1);
    background-color: var(--background-color-gray);
    position: static;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.25rem;
      letter-spacing: 0.7px;

      @include xs {
        text-align: left;
      }
    }

    p {
      text-align: center;

      @include xs {
        text-align: left;
      }
    }

    .required-text {
      margin-top: 0.25rem;
      margin-bottom: 0.75rem;
    }

    .terms-text {
      margin-top: 0.75rem;
    }

    .form__message {
      display: flex;
      justify-content: center;
    }

    button {
      width: 100%;

      @include xs {
        display: grid;
        justify-items: start;
      }
    }

    &__content {
      .form__wrapper .--newsletter-fields-layout {
        grid-template-columns: 1.1fr 1.1fr 2fr .8fr;

        @include xs {
          grid-template-columns: 1fr;
        }
      }

      .layout__item.--submit {
        @include to_xs {
          padding-top: 13px;
        }
      }
    }
  }