@use '../abstracts' as *;

.billboard-slide {

    &.--desktop {
        @include max(700) {
            display: none;
        }
    }

    &.--mobile {
        @include min(700) {
            display: none;
        }

        display: flex;
        flex-direction: column;
        height: 100%;

        .lazy-load,
        .lazy-load-active {
            overflow: visible;
        }
    }

    &__link {
        text-decoration: none;
        outline: none !important;
        border-radius: var(--slider-roundness, 0px);

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 1;
            border-radius: var(--slider-roundness, 0);
            box-shadow: inset 0 0 0 2px var(--color-gray-1), inset 0 0 0 4px var(--color-white);
            opacity: 0;
        }

        &:focus-visible {
            &:after {
                opacity: 1;
            }
        }
    }

    &__overlay {
        width: 40%;
        min-width: 420px;
        height: fit-content;
        @include setInset("0px", 40%, "fit-content");
        margin: auto;

        &.--billboard-centered {
            align-items: center;
        }

        &.--billboard-left {
            align-items: flex-start;
            margin-left: 5.5rem;
        }

        &.--billboard-right {
            align-items: flex-start;
            margin-right: 5.5rem;
        }

        &.--billboard-style-light {
            --headings-color: var(--color-white);
            --base-color: var(--color-white);
        }

        &.--billboard-style-dark {
            --headings-color: var(--color-base);
            --base-color: var(--color-base);
        }
    }

    &__backdrop {
        margin: auto;
        padding: 3rem;
        border-radius: 5px;
        width: 100%;

        &.--billboard-text-center {
            text-align: center;
        }

        &.--billboard-text-left {
            text-align: left;
        }

        &.--billboard-text-right {
            text-align: right;
        }

        &.--billboard-style-light {
            background: #3339;
        }

        &.--billboard-style-dark {
            background: #ffffffcd;
        }
    }

    &__content {
        background-color: var(--background-color-gray);
        padding: 1.5rem 3rem calc(2.5rem + var(--offset-gap)) !important;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;

        &.--billboard-style-light {

            p,
            h1,
            h2 {
                color: #ffffff;
            }
        }

        &.--billboard-style-dark {

            p,
            h1,
            h2 {
                color: #333333;
            }
        }
    }
}