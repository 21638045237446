@use "../abstracts" as *;

.image {
  display: inline-grid;
  max-width: var(--image-width, auto);
  width: 100%;
  svg, img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  &.--rounded {
    border-radius: 50%;
  }
}

.image-block {
  display: grid;
  svg, img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.load-on-view,
.lazy-load-img {
  background: var(--ag-icon-gray),
    linear-gradient(90deg, #f5f5f5 0%, #fdfdfd 15%, #f5f5f5 30%);
  background-size: clamp(28px, 24%, 50px) clamp(30px, 46%, 50px), 320%;
  background-repeat: no-repeat, repeat-x;
  scale: 1.05;
  animation: shine-loading-image 1.25s infinite ease-out;

  &.--loading,
  &.--loaded {
    transition: all 0.2s ease;

    @media (prefers-reduced-motion) {
      transition: none;
    }

    scale: 1;
  }

  [aria-hidden="true"] & {
    display: none;
  }
}

.lazy-load,
.lazy-load-iframe,
.lazy-load-active {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: var(--ui-roundness, 0px);
  --loaded: 0;
  --loading-speed: .4s;
  --not-loaded: 1;
  --img-scale: .05;

  &.--rounded-lg {
    border-radius: var(--ui-roundness-lg, 0px);
  }
  &.--loaded {
    --loaded: 1;
    --not-loaded: 0;
  }

  &:not(.--loaded) {

    .lazy-load,
    .lazy-load-active {
      &__img {
        @include setAspect;
      }
    }
  }

  &.--no-animation {
    --loading-speed: .2s;
    --img-scale: 0;
  }

  &__img {
    object-fit: cover;
    opacity: var(--loaded);
    transform: scale(calc(1 + var(--img-scale) * var(--not-loaded)));
    transition: all var(--loading-speed) var(--easing), padding 0s ease, height 0s ease;
    &.--contain {
      object-fit: contain;
    }
    @supports (aspect-ratio: 16/9) {
      aspect-ratio: var(--img-width)/var(--img-height);
    }
  }

  &__iframe {
    opacity: var(--loaded);
    transition: all var(--loading-speed) var(--easing), padding 0s ease, height 0s ease;
  }

  &__loader {
    @include setInset;
    z-index: -1;
    border-radius: inherit;
    background: var(--ag-icon-gray),
      linear-gradient(90deg, #f5f5f5 0%, #fdfdfd 15%, #f5f5f5 30%);
    background-size: clamp(28px, 24%, 50px) clamp(30px, 46%, 50px), 320%;
    background-repeat: no-repeat, repeat-x;
    transform: scale(1.05);
    animation: shine-loading-image 1.25s infinite ease-out;
    @include trns(var(--loading-speed));
    opacity: var(--not-loaded);

    .swiper-initialized .swiper-slide[aria-hidden="true"]:not(.swiper-slide-next, .swiper-slide-prev) & {
      display: none;
    }

    [aria-hidden="true"]:not(.swiper-slide, .page-wrapper) & {
      display: none;
    }

    .--offscreen & {
      display: none;
    }
  }
}