@use "../abstracts/" as *;

.breadcrumbs{
  margin-bottom: 1.75rem;
  @include sm{
    margin-bottom: 1.25rem;
  }
  @include xs{
    margin-bottom: 1rem;
  }
  ol{
    display: flex;
    a{
      font-size: .85rem;
      line-height: 1em;
      display: block;
      text-decoration: none;
      letter-spacing: .3px;
      color: var(--color-gray-1);
      &[aria-current="page"]{
        pointer-events: none;
        color: var(--color-gray-3);
      }
      &:hover{
        text-decoration: underline;
      }
    }
    li:not(:last-of-type){
      position: relative;
      padding-right: 10px;
      margin-right: 10px;
      &:after{
        content: '';
        width: 1px;
        height: 100%;
        right: 0;
        top: 0;
        background-color: var(--color-gray-4);
        transform: rotate(15deg);
        position: absolute;
      }
    }
  }
}