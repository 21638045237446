@mixin setInset($val: "0px", $width: null, $height: null) {
  --inset-value: #{$val};
  position: absolute;
  @supports (inset: var(--inset-value)){
    inset: var(--inset-value);
  }
  @supports not (inset: var(--inset-value)){
    left: calc(0px + var(--inset-value));
    top: calc(0px + var(--inset-value));
    right: calc(0px + var(--inset-value));
    bottom: calc(0px + var(--inset-value));
    @if $width and $height {
      width: $width;
      height: $height;
    } @else  {
      width: calc(100% - var(--inset-value) * 2);
      height: calc(100% - var(--inset-value) * 2);
    }
  }
}

@mixin setCenterEl($val: "10px") {
  --size-value: #{$val};
  position: absolute;
  content: "";
  left: calc(50% - var(--size-value) / 2);
  top: calc(50% - var(--size-value) / 2);
  width: var(--size-value);
  height: var(--size-value);
}

@mixin setBlur($val: "10px", $bg: "var(--gray1)") {
  @supports (backdrop-filter: blur(#{$val})) {
    backdrop-filter: blur(#{$val});
  }
  @supports not (backdrop-filter: blur(#{$val})) {
    background: #{$bg};
  }
}

@mixin gridRelation($key: "") {
  display: flex;
  flex-flow: wrap;
  margin: 0 calc(0px - var(--gap)) calc(0px - var(--gap) * 2);
  @if $key == "" {
    & > * {
      width: calc(100% / var(--cols) - var(--gap) * 2);
      margin: 0 var(--gap) calc(var(--gap) * 2);
    }
  } @else {
    #{$key} {
      width: calc(100% / var(--cols) - var(--gap) * 2);
      margin: 0 var(--gap) calc(var(--gap) * 2);
    }
  }
}

@mixin setAspect() {
  @supports not (aspect-ratio: 16/9) {
    padding-top: calc(var(--img-height, 0) / var(--img-width, 0) * 100%);
    height: 0;
  }
}


@mixin randomCellGenerator($rows, $cells) {
  @for $i from 1 through $rows {
    @for $j from 1 through $cells{
      $randomNumber: calc(random(50) / 50);
      &>:nth-child(#{$i}n)>:nth-child(#{$j}){
        --random-cell: #{$randomNumber};
      }
    }
  }
}

@mixin randomListGenerator($rows) {
  @for $i from 1 through $rows {
    $randomNumber: calc(random(50) / 50);
    &>:nth-child(#{$i}n){
      --random-list: #{$randomNumber};
    }
  }
}

@mixin loader($low, $high, $speed:1.25s) {
  background-image: linear-gradient(90deg, #{$low} 0%, #{$high} 15%, #{$low} 30%);
  background-size: 320%;
  background-repeat: repeat-x;
  animation: shine-loading #{$speed} infinite ease-out;
  [aria-hidden="true"] & {
    display: none;
  }
}

@mixin brandingLoader($low, $high, $speed:1.25s) {
  background-image: var(--ag-icon-gray), linear-gradient(90deg, #{$low} 0%, #{$high} 15%, #{$low} 30%);
  background-size: clamp(28px, 24%, 50px) clamp(30px, 46%, 50px), 320%;
  background-repeat: no-repeat, repeat-x;
  animation: shine-loading-image #{$speed} infinite ease-out;
  .product-detail.--after-ready & {
    display: none;
  }
}

@mixin mouse() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin touch() {
  @media (hover: none) or (pointer: coarse) {
    @content;
  }
}

@mixin retina() {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}