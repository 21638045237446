@use "../../abstracts" as *;

.listbox {
  --listbox-highlight-color: var(--color-gray-1);
  --listbox-highlight-bg: #{rgba($color-gray-1, 15%)};
  --listbox-item-padding: 8px;
  --listbox-item-offset: 4px;
  --listbox-item-height: 32px;
  --listbox-max-results: 7;
  --listbox-max-shown-height: 30px;
  display: grid !important;
  position: absolute;
  top: calc(100% + 2px);
  width: 100%;
  min-width: var(--listbox-min-width, unset);
  z-index: 3;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  background-color: var(--color-white);
  border-radius: var(--ui-roundness, 0px);
  box-shadow: var(--ui-box-shadow, none);
  transform: translateY(10px);

  &.--from-top {
    transform: translateY(-10px);
  }

  &.--empty {
    .listbox__list {
      height: 0px;
      transition: none;
    }
  }

  &.--from-top {
    top: unset;
    bottom: calc(100% + 2px);
  }

  &__max-shown {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--color-white);
    width: 100%;
    font-size: .725rem;
    height: var(--listbox-max-shown-height);
    color: var(--color-gray-2);
    padding: calc(var(--listbox-item-offset) + 10px) var(--field-spacing, 1rem) 6px;
    margin-top: calc(var(--listbox-item-offset) * -1);
    margin-bottom: var(--listbox-item-offset);
    display: flex;
    align-items: center;
    letter-spacing: .3px;
  }

  .field.--active & {
    @include trns;
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }

  .field.--closing & {
    @include trns;
    opacity: 0;
    transform: translateY(0px);
  }

  &__scroll {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(var(--listbox-item-height) * var(--listbox-max-results, 5) + var(--listbox-item-offset) * 2);

    .listbox.--max-shown & {
      max-height: calc(var(--listbox-item-height) * var(--listbox-max-results, 5) + var(--listbox-item-offset) * 2 + var(--listbox-max-shown-height, 0px));
    }

    .listbox.--no-scroll & {
      overflow-y: hidden;
      max-height: unset
    }
  }

  &__container {
    padding: var(--listbox-item-offset);
  }

  &__list {
    display: grid;
  }
}

.listbox-item {
  appearance: none;
  text-align: left;
  position: relative;
  min-height: var(--listbox-item-height, 32px);
  cursor: pointer;
  margin: 0;
  font-size: 0.875rem;
  letter-spacing: .2px;
  border: none;
  background: 0 0;
  padding: 4px calc(var(--field-spacing, 1rem) - var(--listbox-item-offset)) 4px;
  outline: none;
  outline-offset: -1px;
  border-radius: 3px;
  overflow-wrap: anywhere;

  strong {
    font-weight: 500;
  }

  .field.--predictive-search & {
    span {
      font-size: 0.9em;
      margin-left: 0.4em;
      color: var(--color-gray-2);
    }
  }

  @include mouse {
    .listbox__list:not(:hover) &.--focused {
      background-color: var(--listbox-highlight-bg);
      color: var(--listbox-highlight-color);
      --highlighted: 1;
    }

    &:hover {
      background-color: var(--listbox-highlight-bg);
      color: var(--listbox-highlight-color);
      --highlighted: 1;
    }
  }

  @include touch {

    &:active,
    &.--current {
      background-color: var(--listbox-highlight-bg);
      color: var(--listbox-highlight-color);
      --highlighted: 1;
    }
  }

  .field:not(.--predictive-search) .listbox.--selected & {
    strong {
      font-weight: 400;
    }

    &.--current {
      font-weight: 500;

      strong {
        font-weight: 500;
      }
    }
  }

  &:active {
    @include trns(0s);
    background-color: var(--highlight-bg);
    color: var(--highlight-color);
    --highlighted: 1;
  }

  &.--current {
    font-weight: 500;
  }
}

.listbox-empty {
  padding: 10px var(--field-spacing);
  letter-spacing: 0.3px;
  font-size: 0.875rem;
  overflow-wrap: anywhere;

  strong {
    font-weight: 500;
  }
}

.listbox-separator {
  border-bottom: 1px var(--color-gray-4) solid;
  width: 50%;
  margin: 5px calc(var(--field-spacing) - var(--listbox-item-offset, 0));
}