@use "../abstracts" as *;

.reminders {
  &__block {
    h3 {
      font-weight: 500;
      padding-bottom: .675rem;
    }
  }

  &__top-divider {
    border-top: 1px var(--ui-divider-color) solid;
    padding-top: 1rem;
  }

  &__item {
    display: flex;
    padding: 7px 1rem;
    font-size: 0.9375rem;
    min-height: 38px;
    align-items: center;
    border-radius: var(--ui-roundness);
    text-decoration: none;

    span {
      margin-left: auto;
      white-space: nowrap;
      padding-left: 20px;
    }

    div {
      position: relative;
      text-decoration: none;

      &::before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        background-color: var(--color-base);
        pointer-events: none;
        left: 0;
        bottom: -1px;
        opacity: 0;
        transform: translateY(3px);
        @include trns;
      }

    }

    &:hover div::before {
      opacity: 1;
      transform: translateY(0);
    }

    li:nth-child(odd) & {
      background: var(--color-snow);
    }

    ul.--loading & {
      pointer-events: none;
      div,
      span {
        height: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 50px;
        border-radius: var(--ui-roundness, 0);
        @include loader(#efefef, #f6f6f6);
        color: transparent;
        font-size: 0px;
      }

      div {
        width: 130px;
        &::before {
          display: none;
        }
      }
    }
    ul.--loading li:nth-child(odd) & {
      div,
      span {
        @include loader(#e8e8e8, #f3f3f3);
      }
    }
  }

  &__date {
    align-items: center;
    display: flex;
  }

  &__date-icon {
    margin-right: var(--gap);
  }

  &__date-text {
    p {
      font-weight: 500;
    }
  }

  &__view-button {
    border: none;
    padding: 0;
  }
}