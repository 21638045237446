.single-image {
  display: grid;
  &__link {
    border: none;
    margin: 0;
    background: none;
    display: grid;
    border-radius: var(--ui-roundness, 0);
    outline-offset: 3px;
    * {
      pointer-events: all;
    }
  }
}