@use '../abstracts/mixins/breakpoints.scss' as *;

.layout {
    &.featured-product {
        gap: calc(var(--gap) * 2);

        .layout__item {
            @include xs {
                &:not(:last-child) {
                    margin-bottom: 2rem;
                }
            }
        }

        h3 {
            font-weight: 500;
            letter-spacing: .5px;
            margin-top: 10px;
        }
    }
}