@use '../abstracts' as *;

.join-page {
  background-color: var(--background-color-gray);
}

.join-section {
  .layout {
    @include sm {
      grid-template-columns: unset;
    }
  }

}

.wizard#join-flow {
  @include xs {
    max-width: 400px;
    margin: 0 auto;
  }
}

.join-benefits {
  @include xs {
    max-width: 400px;
    margin: 2rem auto 0;
  }

  &__inner {
    width: 100%;
    height: 100%;
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 1rem;

    @include sm {
      @include to_xs {
        grid-template-columns: 1fr;
        gap: 1.5rem;
      }

      gap: 2rem 1rem;
    }

    @include xxs {
      grid-template-columns: 1fr;
    }


    .join-benefits.--hide & {
      opacity: 0;
    }
  }
}

.join-benefit {
  display: grid;
  align-content: start;
  justify-items: center;

  @include sm {
    @include to_xs {
      grid-template-columns: auto 1fr;
      gap: .825rem;
      align-items: center;
    }
  }

  &__image {
    width: 70px;
    margin-bottom: 7px;
    display: grid;

    &.--medium {
      width: 85px;
    }

    @include sm {
      @include to_xs {
        width: 62px;
        margin-bottom: 0;
      }
    }

    svg,
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    text-align: center;
    letter-spacing: .3px;

    @include sm {
      @include to_xs {
        width: 100%;
        text-align: left;
      }
    }

    header {
      font-weight: 500;
      margin-bottom: 4px;
    }

    span {
      letter-spacing: .5px;
      font-size: .825rem;
      display: block;
      line-height: 1.56em;
      max-width: 200px;
      margin: 0 auto;
      color: var(--color-gray-2);

      @include sm {
        @include to_xs {
          font-size: .875rem;
          max-width: unset;
        }
      }
    }
  }
}

.header-badge-secure {
  border-radius: 5px;
  background-color: #cfe3d3a6;
  color: #007024;
  padding: 3px 8px 1px;
  letter-spacing: .4px;
  font-size: .75rem;
  font-size: .65rem;
  transform: translateY(-1px);
  display: inline-flex;
  gap: 5px;
  align-items: center;
  line-height: 1.4em;

  svg {
    margin-top: -2px;
    width: 11px;
    height: 11px;
  }
}

.ssl-footer {
  text-align: center;
  padding: 0 0 1rem;

  p {
    margin-bottom: 0;
  }
}

#register-form,
#checkout-form {
  .trial-expiry-copy {
    margin-bottom: 1rem;
  }

  .layout.form__wrapper {
    gap: calc(var(--layout-spacing-larger) * 2) calc(var(--layout-spacing) * 1);
  }

  .accordion__content {
    padding: 1.2rem 0 0;
  }

  table {
    thead {
      position: relative;
      top: unset;

      th {
        padding: var(--table-cell-block) var(--table-cell-inline);
        letter-spacing: .3px;
        color: var(--color-gray-2);
        font-size: .8125rem;

        @include xxs {
          font-size: .7rem;
          padding: 0 .5rem;
        }
      }
    }

    tbody {
      td {
        @include xxs {
          font-size: .7rem;
          padding: 0 .5rem;
        }
      }
    }
  }
}

.form-zone {
  max-width: 65%;
  flex: 65%;
}

.aside {
  .product-thumb {
    @include sm {
      margin-top: 0;
    }
  }

  .content-zone {
    max-width: 35%;
    flex: 35%;

    &__box {
      @include to_sm {
        background: #f6f6f6;
        padding: 2rem;
        box-shadow: inset 0 0 0 1px var(--color-gray-6);
        border-radius: var(--ui-roundness);
      }

      &.freecard-module {
        margin-bottom: 1rem;
      }

      @include sm {
        br {
          display: none;
        }
      }
    }
  }
}

.signin-page {
  background-color: var(--background-color-gray);

  .content {
    margin: 1.5rem auto 1.5rem;
    max-width: 450px;
  }
}

.join-confirm {
  .solid-divider-line {
    margin: 1rem 0;

    &.--half {
      width: 50%;
      margin: 1rem auto;
    }
  }

  .headings {
    padding-bottom: .5rem;
  }

  dt {
    padding: 0.25rem 0;
  }

  dd {
    font-weight: 500;
  }

  .join-confirm-default {
    width: 50%;
    margin: 0 auto;

    @include sm {
      width: 80%;
    }

    @include xs {
      width: 100%;
    }

    .layout {
      @include sm {
        --layout-scale-column: .5;
      }
    }
  }

  .join-confirm-card {
    @include sm {
      .content-zone__box {
        padding: 1rem;
        margin-bottom: 0;
      }
    }

    .layout {
      @include sm {
        --layout-scale-column: .5;
      }
    }
  }
}

.continue-btn-wrapper {
  padding-top: .5rem;
}

.account-aside-trigger {
  .btn-inline-action {
    display: inline-flex;
  }
}

.freecard-module {
  @include sm {
    padding: 1.5rem 1rem;
  }

  --layout-scale-column: .25;

  .freecard-thumb-module {
    @include xs {
      grid-template-columns: 1fr 1fr;
    }

    --layout-scale-column: .25;
    --layout-scale-row: 0.5;

    &__img-wrapper {
      max-width: 80px;
      margin: 0 auto 0.8rem;
    }
  }

  .ssl-footer {
    padding: 0;
  }
}