@use '../abstracts' as *;

.tabbed-products {
  .manage-membership-page & {
    @include xs {
      overflow: hidden !important;
      padding: 0 var(--offset-gap);
      margin: 0 calc(-1 * var(--offset-gap));
    }
  }

  &.--underline {
    .tabs {
      &__tab {
        background: none;
        color: var(--color-gray-3);
        border-bottom: 3px solid transparent;
        border-radius: 0;
        font-weight: 500;
        font-size: 1.125rem;
        position: relative;

        &:before {
          content: "";
          width: 100%;
          height: 2px;
          background-color: var(--color-gray-3);
          display: block;
          position: absolute;
          bottom: -3px;
          left: 0;
        }

        @include xs {
          font-size: 0.9375rem;
          padding: 0 .5rem 0;
        }

        &.--active {
          border-color: var(--color-gray-1);
          color: var(--color-gray-1);

          &:before {
            background-color: var(--color-gray-1);
          }
        }
      }

      &__tablist {
        gap: 0;
      }

      &__control {
        border-bottom: 0;
        justify-content: center;
        padding: 0;
        margin: 0;

        &.--mb-space-large {
          margin-bottom: 2rem;
        }
      }

      &__subtext {
        &.--align-center {
          margin: auto;
          max-width: 450px;
        }
      }
    }

    .carousel {
      @include xs {
        &.--product-thumbs {
          --slides-per-view: 1;
        }
      }
    }
  }

  .tabs {
    &__subtext {
      margin-bottom: 2rem !important;
    }

    .--spacing-top {
      margin-top: 2rem;
    }
  }

  &.--align-left {
    .tabs__control {
      justify-content: flex-start;

      @include xs {
        justify-content: center;
      }
    }
  }

  &.--align-center {
    .tabs__control {
      justify-content: center;
    }
  }

  &.--toggle {
    .carousel {
      @include xs {
        &.--product-thumbs {
          --slides-per-view: 2;
        }
      }
    }
  }
}