@use '../abstracts' as *;

.app-content {
    padding-top: 30px;

    &.layout {
        --inter-content-spacing: 1rem;
    }

    &__item {
        background: var(--color-white);
        height: 100%;
        border-radius: 4px;

        .--img-button {
            width: 100%;
        }

    }

    &__content {
        @media (any-hover: hover) {
            &:hover, &:focus, &:focus-within {
                .app-content__icon {
                    opacity: 0;
                }

                .app-content__rollover {
                    opacity: 1;
                }
            }
        }
    }

    &__container {
        padding: 1rem;
        position: relative;
        box-shadow: 0 0 2px var(--color-gray-4);
        background: var(--background-color-gray);
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-flow: column;

        @include xs {
            margin-bottom: 2rem;
        }

        .app-icons {
            margin-bottom: 1rem;
        }
    }

    &__content {
        margin: 10px 0;

        li {
            padding: .5rem 0;
        }

        p {
            letter-spacing: .5px;
        }
    }

    &__title {
        &.--headline {
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__text {
        text-align: center;
    }

    &__holder {
        position: absolute;
        width: 100px;
        top: -35px;
        left: calc(50% - 50px);
        border-radius: 20px;
        box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.2);

        @include sm {
            width: 90px;
            left: calc(50% - 45px);
        }

        @include xs {
            width: 80px;
            left: calc(50% - 40px);
            @media (any-hover: hover) {
                &:hover, &:focus {
                    .app-content__icon {
                        opacity: 1;
                    }

                    .app-content__rollover {
                        opacity: 0;
                    }
                }
            }
        }


        .app-content__icon {
            opacity: 1;
            @include trns;
        }

        .app-content__rollover {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            @include trns;

            img {
                border-radius: 20px;
            }
        }
    }

    &.--four-across {
        --slides-per-view: 4;
    }

    &.--three-across {
        --slides-per-view: 3;
    }

    &.--two-across {
        --slides-per-view: 2;
    }
}