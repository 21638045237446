@use "../abstracts" as *;

.save-offer {
  --s-ratio-bot-override: 0.5;
  --s-ratio-top-override: 0.5;

  .form .layout {
    --global-inter-content-spacing: 1.25rem;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    h2 {
      margin-bottom: 2px;
    }
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;

    @include max(540) {
      width: 65%;
    }
  }

  &__background {
    width: 100%;
    .image {
      width: 100%;
    }
    @include min(541) {
      &-mobile {
        display: none;
      }
    }

    @include max(540) {
      &-desktop {
        display: none;
      }
    }
  }

  &__wrapper-trial {
    position: relative;
    background: linear-gradient(251.37deg, rgba(209, 209, 209, 0.4) 0.83%, #FFFFFF 99.17%);
    box-shadow: 0px 5px 12px 0px #0505051A, 0px 21px 21px 0px #05050517, 0px 48px 29px 0px #0505050D, 0px 85px 34px 0px #05050503, 0px 133px 37px 0px #05050500;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    max-width: 494px;
    margin: 0 auto 55px;
    padding: 30px;
    border-radius: 15px;
    @include xs {
      margin: 0 auto 80px;
      max-width: 316px;
    }

    h2 {
      margin-bottom: 2px;
    }
  }

  &__content-trial {
    text-align: left;
    margin-left: 0;
    width: 60%;
    height: 100%;
    border-radius: 15px;
    @include xs {
      text-align: center;
      width: 100%;
      padding-bottom: 1rem;
    }
  }

  &__ribbon {
    position: absolute;
    top: 20px;
    right: 25px;
    width: 165px;
    height: 180px;
    @include xs {
      width: 111px;
      height: 121px;
      top: unset;
      right: unset;
      bottom: -85px;
      left: 50%;
      transform: translate(-50%);
    }
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 118.23 118.22'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23d50032;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath class='cls-1' d='M4.11,118.08c-2.69.39-4.13.18-4.11-3.5q.29-55.5,0-111C0,.6.63,0,3.61,0q55.5.2,111,0c3,0,3.6.6,3.59,3.58q-.2,55.5,0,111c0,3.68-1.42,3.89-4.12,3.5-14.78-5.47-29.66-10.68-44.29-16.52-7.18-2.86-13.62-3.26-21-.23C34.12,107.39,19,112.55,4.11,118.08Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    filter: drop-shadow(1px 2px 6px #0000001A) drop-shadow(6px 9px 11px #00000017) drop-shadow(13px 21px 15px #0000000D) drop-shadow(24px 38px 18px #00000003) drop-shadow(37px 59px 19px #00000000);
  }

  &__price.mimic-h3{
    margin: 0 auto 0.5rem !important;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--background-color-dark-gray);
    width: 50%;

    @include max(540) {
      width: 90%;
    }
  }

  &__total-amount {
    display: block;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 0 !important;
    margin-top: 15px;
    &.--crossed {
      text-decoration: line-through;
      text-decoration-color: var(--color-gray-1);
      text-decoration-thickness: 4px;
    }
    @include xs {
      font-size: 1.25rem;
      margin-top: 10px;
      &.--crossed {
        text-decoration-thickness: 2px;
      }
    }
    &::after, &::before {
      content: "\00a0";
    }
  }

  &__spacer {
    font-size: 2.75rem;
    font-weight: 700;
    margin: 0;
    line-height: .75;
    @include xs {
      font-size: 1.75rem;
    }
  }

  &__saved-amount {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.15;
    @include xs {
      font-size: 1.5rem;
      line-height: 0.75;
    }
  }

  &__actions {
    @include xs {
      flex-flow: column-reverse;
    }
  }

  #trial-save-offer-form.--success {
    .save-offer__ribbon {
      position: relative;
      height: unset;
    }
    .save-offer__wrapper {
      margin-bottom: 0;
    }
  }
}