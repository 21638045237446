@use '../abstracts' as *;

.renewal-promo{
    &__content{
        display: grid;
        grid-template-columns: 1fr 0.9fr;
        gap: 1.5rem;
        @include sm{
            grid-template-columns: 1fr;
            padding: 0 1.5rem;
        }
    }
    &__more {
        .product__actions{
            display: none;
        }
        ul{
            display: grid;
            gap: 1.5rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            padding-left: 1.5rem;
            border-left: 2px solid var(--color-gray-7);
            @include sm{
                grid-template-columns: repeat(4, minmax(0, 1fr));
                padding-left: 0;
                border-left: none;
            }
            @include xxs{
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
        .accessibility-btn{
            position: absolute;
            cursor: pointer;
            inset: 0px;
            background: transparent;
            border: none;
            outline-offset: 2px;
        }
    }
    &__button{
        display: grid;
        grid-template-columns: 1fr 0.9fr;
        gap: 1.5rem;
        margin-top: 2rem;
        @include sm{
            grid-template-columns: 1fr;
        }
        a{
            margin: 0 auto;
        }
    }
}