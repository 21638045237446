@use '../abstracts' as *;

.featured-content {
  &.layout {
    --layout-spacing: 1rem;
  }

  &__item {
    background: var(--color-white);
    height: 100%;
    border-radius: var(--ui-roundness, 0);
    box-shadow: inset 0 0 2px #ccc;
  }

  &__container {
    padding: 1rem;
  }

  &__outer {
    overflow: hidden;
    border-radius: var(--ui-roundness, 0px);
  }

  &__inner {
    @include trns(5s);
    transform: scale(1);
    will-change: transform;
    pointer-events: all;

    &.--thumb-zoom {
      scale: 2;
    }

    &:hover {
      transform: scale(1.1);
    }
  }


  &__image {
    display: block;

    img {
      object-fit: cover;

      &.--thumb-center {
        object-position: center;
      }

      &.--thumb-left {
        object-position: left;
      }

      &.--thumb-right {
        object-position: right;
      }
    }
  }

  &__title {
    &.--headline {
      text-align: center;
      margin-bottom: 0;
    }
  }

  &__text {
    text-align: center;
  }
}