@use '../../abstracts/' as *;

.typeahead {
  position: absolute;
  top: var(--typeahead-top, 0);
  right: var(--typeahead-right, unset);
  bottom: var(--typeahead-bottom, unset);
  left: var(--typeahead-left, 0);
  display: block !important;
  z-index: 101;
  pointer-events: none;
  max-width: 280px;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;

  .listbox {
    position: static;
    --listbox-item-height: 53px;
    --listbox-max-results: 4;
    .field__list-wrapper{
      height: unset;
    }
  }

  .listbox-item {
    display: grid;
    align-items: center;
    justify-items: start;
    white-space: nowrap;
    padding: 6px 1rem 6px;
    position: relative;
    &__primary {
      text-overflow: ellipsis;
      max-width: 100%;
      pointer-events: none;
      font-size: .85rem;
      font-weight: 500;
      position: relative;
      z-index: 1;
    }
    &__secondary {
      position: relative;
      z-index: 1;
      text-overflow: ellipsis;
      max-width: 100%;
      margin-top: 3px;
      font-size: .725rem;
      letter-spacing: .5px;
      pointer-events: none;
    }
    &:not(:last-child):after {
      content: '';
      pointer-events: none;
      top: unset;
      opacity: 1;
      width: calc(100% - 2rem);
      left: 1rem;
      height: 1px;
      bottom: 0;
      position: absolute;
      background-color: var(--ui-divider-color);
    }
  }

  &.--active:not(.--empty) {
    pointer-events: all;
    .listbox {
      @include trns;
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }
  }
  &.--closing {
    .listbox {
      @include trns;
      opacity: 0;
      transform: translateY(0px);
    }
  }
}