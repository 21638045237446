@use '../abstracts' as *;
.category-highlight-item {
    display: grid;
    &__link {
        text-decoration: none;
        display: grid;
        &:focus-visible {
            outline-offset: 2px;
        }
        @media (any-hover: hover) {
            &:hover, &:focus {
                .category-highlight-item__front {
                    opacity: 0;
                }

                .category-highlight-item__rollover {
                    opacity: 1;
                }

                .category-highlight-item__title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__front {
        opacity: 1;
        @include trns;
    }

    &__holder{
        position: relative;
    }

    &__rollover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;
        @include trns;
    }

    &__title {
        padding-top: 1rem;
        text-align: center;
        font-weight: 500;
        letter-spacing: .3px;
        font-size: 0.9375rem;
        padding-bottom: 1px;
        @include md {
            font-size: 0.875rem;
        }
        @include sm {
            font-size: 0.9375rem;
        }
        @include xs {
            font-size: 0.875rem;
        }
        p {
            overflow-wrap: anywhere;
        }
    }
}