@use "./../abstracts" as *;

.app-icons {
  --hover-color: #{rgba($color-gray-1, 15%)};
  --active-color: #{rgba($color-gray-1, 75%)};
  display: flex;
  gap: var(--gap);
  height: 40px;

  &>* {
    display: flex;
  }

  a {
    outline-offset: 2px;
    display: flex;
    border-radius: 5px;
    background-color: #0000;
    box-shadow: 0 0 0 0px #0000;
    @include trns();

    &:hover,
    &:focus {
      background-color: var(--hover-color);
      box-shadow: 0 0 0 4px var(--hover-color);
      @include trns(.1s);
    }

    &:active {
      @include trns(.05s);
      background-color: var(--active-color);
      box-shadow: 0 0 0 var(--active-weight, 2px) var(--active-color);
    }
  }

  @include xs {
    justify-content: center;
  }
}