@use "../abstracts" as *;

.addressbook {
  &__tablist {
    display: flex;
    gap: var(--gap);
    margin-bottom: .6rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px var(--ui-divider-color) solid;

    @include xs {
      justify-content: center;
    }
  }

  &__sync {
    z-index: 5;
    padding-top: 1.3rem;
    position: relative;
    display: flex;
  }

  &__tab {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .4px;
    color: var(--color-gray-1);
    padding: 2px 2rem 0;
    border-radius: 50px;
    outline-offset: 3px;
    height: 42px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    background-color: var(--color-gray-6);
    border: none;
    cursor: pointer;
    @include trns;

    @include mouse {
      &:hover {
        background-color: var(--color-gray-5);
      }
    }

    @include xs {
      padding: 1px 1.5rem 0;
      height: 40px;
    }

    &:active {
      @include trns(0s);
      background-color: var(--color-gray-4-5);
    }

    &[aria-selected="true"] {
      background-color: var(--color-gray-1);
      color: var(--color-white);
    }
  }

  &__actions {
    padding: 1rem 0;
    border-top: 1px var(--ui-divider-color) solid;
    background: var(--color-white);
    box-shadow: 10px 0 0 0 var(--color-white), -10px 0 0 0 var(--color-white);
    position: sticky;
    bottom: 0px;
    z-index: 5;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: -1rem;
    @media (max-height: 500px) {
      position: static;
    }
    .dialog.--no-entries &, .dialog.--no-actions & {
      display: none;
    }
  }
}