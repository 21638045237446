@use '../abstracts' as *;

.scrolling-banner {
  --slide-width: 200px;
  --neg-slide-width: -200px;
  --slide-height: 124px;
  --slider-btn-size: 34px;
  --slider-bg: var(--color-white);
  --arrows-opacity: 1;
  --slider-btn-bg: rgba(51, 51, 51, 0.36) !important;
  --slider-btn-active-bg: var(--color-gray-7);
  --slider-btn-active-color: var(--color-gray-1);
  --slider-roundness: var(--ui-roundness-lg, 0);
  --slider-btn-color: var(--color-white);
  --slider-btn-hover-color: var(--color-gray-1);
  --slider-btn-hover-bg: var(--color-white);
  --slider-btn-active-bg: rgba(255, 255, 255, 0.8);
  padding-top: 1.5rem;

  @include sm {
    --slide-width: 100px;
    --neg-slide-width: -100px;
    --slide-height: 54px;
    padding: 0 0 2rem;
  }

  &__slide-track {
    width: calc(var(--slide-width) * var(--scrollable_cards) * 3);
    -webkit-animation: scroll 30s linear infinite;
    animation: scroll 30s linear infinite;
    height: var(--slide-height);

    &.inline {
      flex-flow: row;
      --gap: 0;
    }
    & > li {
      display: grid;
      padding: 0 8px;
      width: var(--slide-width);
      & > button,
      & > a {
        border: none;
        margin: 0;
        background: none;
        display: grid;
        border-radius: var(--ui-roundness, 0);
        outline-offset: 3px;
      }
    }
  }

  &__slide {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__button {
    position: absolute;
    bottom: 18px;
    left: 10px;
    padding: 0;
    padding-top: 4px;
    width: var(--slider-btn-size);
    height: var(--slider-btn-size);
    border-radius: 50%;
    transform: scale(var(--slider-button-scale, 1));
    color: var(--slider-btn-color);
    background-color: var(--slider-btn-bg);
    border: none;
    box-shadow: 0 0 0 2px var(--slider-bg);
    pointer-events: all;
    opacity: var(--arrows-opacity);
    outline-offset: 2px;
    cursor: pointer;
    @include trns;

    @include mouse {
      &:hover {
        background-color: var(--slider-btn-hover-bg);
        color: var(--slider-btn-hover-color);
      }
    }

    &:active {
      @include trns(0s);
      color: var(--slider-btn-active-color);
      background-color: var(--slider-btn-active-bg);
    }

    @include sm {
      bottom: 50%;
      transform: translate(0, 50%);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(var(--neg-slide-width) * var(--scrollable_cards)));
    }
  }

  .marquee--hover-pause:hover .scrolling-banner__marquee-content {
    animation-play-state: paused;
  }

  .marquee--hover-pause.--pause-animation .scrolling-banner__marquee-content {
    animation-play-state: paused;
  }
}