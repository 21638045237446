@use "../abstracts" as *;

.tabs {
  position: relative;

  &__tablist {
    display: inline-flex;
    gap: var(--gap);

    .tabs.--toggle & {
      gap: 0;
      padding: 4px;
      border-radius: 54px;
      background-color: var(--color-gray-7);
      position: relative;
      isolation: isolate;

      @include xs {
        background-color: transparent;
        border-radius: 0;
        display: flex;
        overflow-x: scroll;
        flex-flow: row;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        margin: calc(var(--offset-gap)* -1);
        padding: var(--offset-gap);
        max-width: var(--client-width, 100vw);

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .tabs__control.--smaller & {
      @include max(375) {
        width: 100%;
      }
    }
  }

  &__wrapper {
    position: static !important;
  }

  &__wrapper-inner {
    margin: -10px;
    padding: 10px;
  }

  &__tab {
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: .4px;
    color: var(--color-gray-1);
    padding: 0 2rem;
    border-radius: 50px;
    outline-offset: 3px;
    height: 42px;
    background-color: var(--color-gray-6);
    border: none;
    cursor: pointer;
    @include trns;

    .tabs__control.--minified & {
      font-size: 0.825rem;
    }

    & * {
      pointer-events: none;
    }

    @include mouse {
      &:hover {
        background-color: var(--color-gray-5);
      }
    }

    @include xs {
      padding: 1px 1.5rem 0;
      height: 40px;
    }

    .tabs__control.--smaller & {
      @include xxs {
        padding: 1px 1rem 0;
      }

      @include max(375) {
        font-size: 0.925rem;
        flex-grow: 1;
        padding: 0;
      }
    }

    &:active {
      @include trns(0s);
      background-color: var(--color-gray-4-5);
    }

    &.--active {
      background-color: var(--color-gray-1);
      color: var(--color-white);
    }

    .tabs.--toggle & {
      background-color: transparent;

      @include xs {
        background-color: var(--color-gray-7);
        white-space: nowrap;
        margin: 0 2px;
      }

      @include mouse {
        &:hover {
          background-color: rgba($color-gray-1, .05);
        }
      }

      &:active {
        background-color: rgba($color-gray-1, .1);
      }

      &.--active {
        background-color: var(--color-gray-1);
      }
    }

    .tabs.--toggle.--toggler-active & {
      &.--active {
        background-color: transparent;

        @include xs {
          background-color: var(--color-gray-1);
        }
      }
    }
  }

  &__panel {
    display: none;
    position: relative;

    &.--active {
      display: grid;
    }
  }

  &__control {
    margin-bottom: .6rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px var(--ui-divider-color) solid;
    display: flex;

    @include xs {
      justify-content: center;
    }

    &.--mb-space-large {
      margin-bottom: 2rem;
    }

    .dialog & {
      margin-bottom: .8rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }

    &.--minified {
      display: flex;
      margin-bottom: 0rem;
      padding-bottom: 0px;
      border-bottom: none;
      justify-content: center;
    }
  }

  &__toggler {
    position: absolute;
    width: var(--toggler-width, 0);
    height: var(--toggler-height, 100%);
    top: var(--toggler-top, 0);
    opacity: 0;
    left: var(--toggler-left, 0);
    border-radius: 50px;
    z-index: -1;
    pointer-events: none;

    @include xs {
      display: none;
    }

    .tabs.--toggler-active & {
      opacity: 1;
      background-color: var(--color-gray-1);
      @include setTransitionOpt(.2s, 2, left, top, width, height);
    }
  }
}