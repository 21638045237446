@use '../abstracts' as *;

.popup-overlay {
    &__container {
        padding: 2rem;

        .popup-heading {
            --h2-size: 1.25rem;
            margin-bottom: 1rem;
        }

        &.--overlay-full {
            .popup-heading {
                margin-bottom: 1.5rem;
                --h2-size: 1.5625rem;
            }

        }

        &.--details-only {
            .icon-list {
                border-left: none;
                padding-left: 0;
            }
        }

        &.--overlay-small {
            max-width: 350px;
        }

        &.--overlay-medium {
            max-width: 415px;
        }

        &.--image-background {
            padding: 0;
        }

        .carousel-container {
            --h2-size: 1.25rem;

            .--overlay-thumbs {
                --slides-per-view: 1;
                margin-bottom: .5rem;
            }

            .carousel {
                &__item {
                    margin-bottom: 0;

                    .image__title {
                        margin-top: .5rem;
                        a {
                            color: var(--color-gray-1);
                        }
                    }
                }

                @include sm {
                    max-width: 250px;
                    margin: auto;

                    .carousel__slider {
                        overflow: hidden;
                    }
                }
            }
        }

        .icon-text-layout {
            align-items: baseline;

            &__image {
                img {
                    width: 130px;
                    margin: auto;
                }
            }

            &__title {
                --h3-size: 1rem;
                margin-bottom: .5rem;
            }

            &.layout {
                @include sm {
                    --columns: 2;
                    --layout-spacing-row: 0.5rem;
                    grid-template-columns: repeat(var(--columns,1),1fr);
                }

                img {
                    width: 110px;

                    @include sm {
                        width: 100px;
                    }
                }
            }
        }

        .bottom-content {
            border-top: 1px solid var(--ui-divider-color);
            padding-top: 1rem;
            margin-top: .5rem;
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            .text-option {
                padding-right: 1rem;
            }

            &.--button-only {
                border-top: none;
                justify-content: center;
            }
        }

        .--decor {
            position: absolute;
            margin:0;
            width: auto;

            &.--top {
                top: -20px;
                left: -20px;
            }

            &.--bottom {
                bottom: 0;
                right: 0;
            }
        }

        .carousel__button {
            color: var(--color-gray-3);
            background-color: transparent;
            box-shadow: 0 0 0 2px var(--color-gray-3);

            &:hover {
                background-color: var(--color-white);
                color: var(--color-gray-1);
                box-shadow: 0 0 0 2px var(--color-gray-1);
            }

            &.--autoplay {
                position: absolute;
                bottom: 2.5rem;
                left: 2rem;
            }
        }

        &.--overlay-full {
            .carousel {
                @include xs {
                    width: 200px;
                }
            }
            .bottom-content {
                @include xs {
                    margin-top: 1rem;
                }
            }
        }

        &.--text-carousel {
            .--autoplay {
                bottom: .5rem;
            }
        }

        &.--image-carousel {
            .--autoplay {
                bottom: 1rem;
            }
        }
    }

    &__full-content {
        &.--one-fifth{
            margin: 0 2rem;
            align-items: unset;

            @include xs {
                margin: 0;
            }
        }

        .icon-list {
            border-left: 1px solid var(--ui-divider-color);
            padding-left: 3rem;

            @include xs {
                border: none;
                padding: 0;
            }
        }
    }

    &__carousel {
        padding-top: 0;
        padding-bottom: 0;

        .carousel__items {
            padding: 0;

            img {
                border-radius: 10px;
            }

            .--text-item {
                border: 1px solid var(--ui-divider-color);
                padding: 2rem;
                box-shadow: var(--ui-box-shadow);
                border-radius: 4px;
            }
        }
    }

    &__image {
        border-radius: var(--dialog-roundness);
    }

    &__text {
        position: absolute;
        top: 50px;
        padding: 0 3rem;

        p {
            line-height: 1.4;
        }

        &.--top-spacing-small {
            top: 106px;
        }

        &.--top-spacing-medium {
            top: 125px;
        }

        &.--top-spacing-large {
            top: 160px;
        }
    }

    .--color-white {
        color: var(--color-white);
    }

    .--color-grey {
        color: var(--color-grey);
    }

    &__button {
        position: absolute;
        bottom: 20px;
        width: 100%;
    }

    &.--bottom {
        --vertical-spacing: 0;

        .dialog__inner {
            --spacing: 0;
        }

        .popup-overlay__image {
            margin: 0;

            p {
                margin-top: .5rem;
                color: var(--color-gray-1);
            }
        }
    }

    .dialog__content {
        --dialog-spacing: 0;
        --dialog-roundness: 10px;
    }

    .dialog__overlay-btn {
        top: .5em;
        right: .5em;
    }
}

.popup_overlay_trigger {
    position: fixed;
    bottom: 0;
    right: 0;

    &.--bottom-center {
        width: 100%;
        text-align: center;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0%);
    }
}

.--scroll-freeze .popup_overlay_trigger {
    right: var(--scrollbar-gap, 0);
}

.popup-overlay-dialog {
    &.--icon {
        border: 0;
        background: none;
        width: 125px;
        cursor: pointer;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
}