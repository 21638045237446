@use '../abstracts' as *;

.page-wrapper {
  margin-bottom: 0px;
  width: 100%;
  min-height: 100vh;
  display: grid;
  margin: 0 auto;
  position: relative;
  background-color: var(--color-background);
  &.--centered {
    place-content: center;
  }
}

.resize-proxy {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  top: 0px;
  left: 0;
  pointer-events: none;
  z-index: -1;
}

.client-logger {
  position: fixed;
  z-index: 999;
  width: 500px;
  max-width: 50%;
  pointer-events: none;
  left: 0;
  bottom: 0;
  margin: 20px;
  background-color: var(--color-white);
  padding: 20px;
  border: 1px var(--color-gray-4) solid;
  border-radius: 10px;
  display: grid;
  pre {
    white-space: pre-wrap;
    font-size: 12px;
    &:not(:last-child) {
      margin-bottom: 10px;
      border-bottom: 1px var(--ui-divider-color) solid;
      padding-bottom: 10px;
    }
    strong {
      margin-right: 5px;
      margin-left: 5px;
      font-weight: 700;
      color: var(--color-primary);
    }
  }
}

.content-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 1;
  body.--scroll-freeze & {
    z-index: unset;
  }
  &.--overflow {
    overflow: visible;
  }
  &.--not-contained {
    z-index: unset;
    overflow: visible;
  }
  &.--aside {
    overflow: visible;
    display: grid;
    gap: calc(var(--spacing) * 1.4);
    padding-top: calc(var(--spacing) * 1.5);
    padding-bottom: calc(var(--spacing) * 1.5);
    grid-template-columns: 0.6fr 2fr;
    max-width: var(--container-gaps);
    @include md{
      grid-template-columns: 200px 1fr;
      gap: var(--spacing);
    }


    @include sm {
      grid-template-columns: 1fr;
    }
  }

  &.--aside-payment {
    overflow: visible;
    display: grid;
    gap: calc(var(--spacing) * 1.4);
    padding-top: calc(var(--spacing) * 1.5);
    padding-bottom: calc(var(--spacing) * 1.5);
    grid-template-columns: 3fr 1.5fr;
    max-width: var(--container-gaps);
    @include md{
      grid-template-columns: 2.5fr 1.3fr;
      gap: var(--spacing);
    }


    @include sm {
      grid-template-columns: 1fr;
    }
  }

  &.--visible {
    overflow: visible;
  }
}

.main-content-wrapper {
  outline: none;
  &>header {
    margin-bottom: calc(var(--ui-vertical-spacing, 1rem) * 1.75);
  }
  &::before {
    content: '';
    @include setInset(10px);
  }
  &:focus-visible::before {
    outline: 2px solid var(--color-base);
    border-radius: var(--ui-roundness);
    z-index: 3;
    pointer-events: none;
  }
}

html .sign-in-page:not(.--no-scrolling), html .join-page:not(.--no-scrolling) {
  overflow-y: scroll !important;
}

.aria-speaker {
  position: fixed;
  top: 0;
  left: 0;
}