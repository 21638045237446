@use '../abstracts/' as *;

.myaccount-dialog {
    --elements-spacing: .3rem;

    &__header {
        &.dialog__header {
            @include sm {
                margin: 0 calc(var(--dialog-spacing) * -1);
                padding: 0 var(--dialog-spacing) 1.6rem;
            }
        }
    }
    #myaccount-dialog__label {
        overflow-wrap: anywhere;
    }
    &__links {
        &.layout {
            --layout-spacing: .75rem;
            @include to_sm {
                padding-bottom: 2rem;
                margin-bottom: 1rem;
                padding-top: 1rem;
                border-bottom: 1px var(--ui-divider-color) solid;
            }
            @include sm {
                --layout-spacing: 0;
            }

            .layout__item {
                &.--separator {
                    height: var(--elements-spacing);
                    @include sm {
                        display: none;
                    }
                }

                @include sm {
                    margin: 0 calc(var(--dialog-spacing) * -1);
                    border-bottom: 1px solid var(--ui-divider-color);
                }
                a {
                    font-size: 0.9375rem;
                    padding: 1px 0;
                    outline-offset: 4px;
                    text-decoration: none;
                    font-weight: 500;
                    display: block;
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }

                    @include sm {
                        outline-offset: -2px;
                        display: flex;
                        padding: .9rem var(--dialog-spacing) .85rem;
                    }
                }
            }
        }
    }
}