@use '../abstracts/' as *;

.nav-menu {
  --elements-spacing: 1.2rem;
  --item-spacing: 20px;
  --ui-roundness: 4px;
  --menu-background: var(--color-white);
  // overflow-y: clip;
  position: relative;

  &__header {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: calc(var(--elements-spacing) * 0.5);
    position: relative;
  }

  &__navigation {
    ul {
      margin: 0 -20px;
      display: grid;
      gap: 1px;
    }
  }

  &__item {
    &.--separator {
      height: var(--elements-spacing);
    }

    a {
      display: inline-flex;
      position: relative;
      letter-spacing: .2px;
      font-size: 1rem;
      text-decoration: none;
      padding: 6px var(--item-spacing) 4px;
      @include trns;

      &:hover {
        text-decoration: underline;
      }

      &:active,
      &:focus {
        color: var(--color-primary-lighter);
        @include trns(.05s);

        &::before {
          box-shadow: inset 0 0 0 2px var(--color-primary-lighter);
          opacity: 1;
          transform: scaleX(1);
          @include trns(.05s);
        }
      }
    }

    &.--current {
      pointer-events: none;
      position: relative;

      a {
        padding-left: calc(var(--item-spacing) + 15px);
        color: var(--color-primary-lighter);
        font-weight: 500;
      }
    }
  }
}