@use "./../abstracts" as *;

.text-block {
  --scale: 1;
  --heading-scale: 1;
  --base-color: var(--color-gray-1);
  --highlight-color: var(--color-primary);
  --headings-color: var(--color-gray-1);
  --link-color: var(--color-primary-lighter);
  --link-hover-color: var(--color-primary-darkest);
  --vertical-spacing: var(--ui-vertical-spacing, 1rem);
  --h1-size: 1.4625rem;
  --h1-scale: 1;
  --h2-size: 1.4625rem;
  --h2-scale: 1;
  --h3-size: 1.2rem;
  --h3-scale: 1;
  --p-size: 0.9375rem;
  --offset-spacing: var(--vertical-spacing);
  --container-width: 750px;
  color: var(--base-color);

  &.--unbalance {
    text-wrap: wrap;
  }

  &.--balance, &.--balanced {
    text-wrap: balance;
  }

  &.auto-renew-text {
    margin-top: 1rem;
  }

  &.--closer {
    --vertical-spacing: 0.5rem;
  }

  .balance {
    text-wrap: balance;
  }

  &.--no-vertical-gap {
    margin: -.75rem 0;
  }

  @include sm {
    --h1-scale: .9;
    --h2-scale: .9;
    --h3-scale: .9;
  }

  &.--half-width {
    @include to_xs {
      width: 50%;
    }
  }

  &.--white {
    --base-color: var(--color-white);
    --headings-color: var(--color-white);
  }

  &.--inline {
    --vertical-spacing: 0;
  }

  &.--lighter {
    --base-color: var(--color-gray-2);
  }

  &.--gray-font {
    --base-color: var(--color-gray-4);
  }

  .dialog:not(.--maintain-size) .form & {
    --p-size: 1rem;
  }

  &.--larger-p {
    --p-size: 1rem;
  }

  &.--underline {
    padding-bottom: 1.2rem;
    border-bottom: 1px solid var(--ui-divider-color);
  }

  &.--info {
    position: relative;
    --base-color: var(--color-info-text);
    padding: 8px;
    border-radius: var(--ui-roundness, 0px);
    background-color: rgba($color-form-info, 0.07);
    padding: 14px calc(var(--scale, 1) * 18px);
    padding-left: 43px;
    a:not(.btn, .btn-action) {
      color: var(--color-info-link) !important;
      &:hover {
        color: var(--color-info-link-hover) !important;
      }
    }
    &:not(:last-child) {
      margin-bottom: calc(var(--fieldset-spacing, 2rem) - 1rem);
    }

    &:not(:first-child) {
      margin-top: calc(var(--fieldset-spacing, 2rem) - 1rem);
    }

    &:before {
      content: "";
      content: "" / "info icon";
      background: var(--icon-info-tip);
      width: 14px;
      height: 14px;
      position: absolute;
      top: calc(var(--scale, 1) * 20px);
      left: 18px;
      opacity: 1;
    }

    &.--success {
      background-color: rgba($color-form-valid, 0.07);
      --base-color: var(--color-success-text);
      a {
        color: var(--color-success-link) !important;
        &:hover {
          color: var(--color-success-link-hover) !important;
        }
      }
      &:before {
        content: "";
        content: "" / "success icon";
        background: var(--icon-success-tip);
      }
    }

    &.--error {
      background-color: rgba($color-form-error, 0.07);
      color: var(--color-form-error);
      &:before {
        content: "";
        content: "" / "error icon";
        background: var(--icon-error-tip);
      }
      ul:not(:last-child) {
        margin-bottom: calc(var(--vertical-spacing)* var(--scale)* 1);
      }
    }

    &.--product-detail-info {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      @include lg {
        --scale: .9;
        p {
          line-height: 1.55em;
          letter-spacing: 0.4px;
        }
        &:before {
          top: 17px;
        }
      }
      @include xs {
        &:not(:last-child) {
          margin-bottom: calc(var(--fieldset-spacing, 2rem) - 1rem) !important;
        }
      }
    }
    &.--smaller {
      p {
        line-height: 1.5em;
        --vertical-spacing: .6rem
      }
      li {
        line-height: 1.5em;
        --vertical-spacing: .6rem
      }
    }
  }

  &.--small {
    --scale: .925;
  }

  &.--smaller {
    --scale: .85;
  }

  &.--smallest {
    --scale: .75;
  }

  &.--headings-smaller {
    --heading-scale: .85;
  }

  &.--headings-smallest {
    --heading-scale: .75;
  }

  h1,
  .mimic-h1,
  h2,
  .mimic-h2,
  h3,
  .mimic-h3,
  h4,
  .mimic-h4,
  h5,
  .mimic-h5,
  h6,
  .mimic-h6 {
    color: var(--headings-color);
    text-decoration: none;
    strong {
      color: var(--highlight-color);
    }

    .--icon {
      background-size: 30px;
      background-repeat: no-repeat;
      padding-left: 35px;
    }
  }

  &>* {
    &:not(:last-child) {
      margin-bottom: calc(var(--vertical-spacing) * 1 * var(--scale));
    }
  }

  h1,
  .mimic-h1 {
    font-size: calc(var(--h1-size) * var(--h1-scale, 1) * var(--scale, 1) * var(--heading-scale, 1));
    line-height: 1.36em;
    letter-spacing: 0px;
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: calc(var(--vertical-spacing) * 0.75 * var(--scale) * var(--heading-scale));
    }

    .form & {
      &:not(:last-child) {
        margin-bottom: calc(var(--vertical-spacing) * 0.5 * var(--scale) * var(--heading-scale));
      }
    }
  }

  h2,
  .mimic-h2 {
    font-size: calc(var(--h2-size) * var(--h2-scale, 1) * var(--scale, 1) * var(--heading-scale, 1));
    line-height: 1.36em;
    letter-spacing: 0px;
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: calc(var(--vertical-spacing) * 0.75 * var(--scale) * var(--heading-scale));
    }

    &.--small-caps {
      text-transform: uppercase;
      font-size: 1.25rem;
      letter-spacing: 3px;
      margin: 0;
    }
    a br {
      display: none;
    }
  }

  h3,
  .mimic-h3 {
    font-size: calc(var(--h3-size) * var(--h3-scale, 1) * var(--scale, 1) * var(--heading-scale));
    line-height: 1.36em;
    letter-spacing: 0.2px;
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: calc(var(--vertical-spacing) * 0.75 * var(--scale));
    }
  }

  .--red-text {
    font-weight: 500;
    color: var(--color-primary-lighter);
  }
  .--error-text {
    font-weight: 500;
    color: var(--color-form-error);
  }

  // Heading offset when using multiple headings
  p:not(.--small-note), ul, ol, div{
    & + h1,
    & + h2,
    & + h3,
    & + .mimic-h1,
    & + .mimic-h2,
    & + .mimic-h3 {
      &:not(:last-child) {
        margin-top: calc(var(--vertical-spacing) * 2.25 * var(--scale));
      }
    }
  }

  &.--form-copy {
    p, li {
      a:not(.btn, .btn-simple, .btn-inline-action),
      .inline-link {
        text-decoration: underline;
        font-weight: 400;
        --link-color: var(--color-gray-1);
        --link-hover-color: var(--color-gray-1);
      }
    }
  }

  .--overflow-text {
    overflow-wrap: anywhere;

    .--lowercase {
      text-transform: lowercase;
    }
  }

  p,
  li {
    font-size: clamp(12px, calc(var(--p-size) * var(--scale)), 2rem);
    line-height: 1.766666em;
    letter-spacing: 0.2px;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: calc(var(--vertical-spacing) * var(--scale));
    }

    a:not(.btn, .btn-simple, .btn-inline-action),
    .inline-link {
      text-decoration: none;
      font-weight: 500;
      color: var(--link-color);
      overflow-wrap: anywhere;

      &:hover {
        text-decoration: underline;
        color: var(--link-hover-color);
      }

      .form &, .--form-copy & {
        text-decoration: underline;
        font-weight: 400;
        --link-color: var(--color-gray-1);
        --link-hover-color: var(--color-gray-1);
        &.text-link {

          color: var(--color-primary-lighter);
          font-weight: 500;

          &:hover {
            color: var(--color-primary-darker);
            text-decoration: underline;
          }
        }

        &.text-link {
          text-decoration: none;

          &.--underline {
            text-decoration: underline;
          }
        }
      }

      &.highlight-link {
        color: var(--color-primary-lighter);
        font-weight: 500;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    strong {
      overflow-wrap: anywhere;
      font-weight: 500;
    }

    kbd {
      border-radius: 3px;
      background-color: var(--color-gray-7);
      color: var(--color-gray-1);
      font-size: .7em;
      font-weight: 500;
      display: inline-flex;
      line-height: 1;
      padding: 4px 5px;
      transform: translateY(-1px);
      margin: -3px 0;
      box-shadow: inset 0 0 0 1px var(--color-gray-5);
    }

    &.--small-note {
      color: var(--color-primary-lighter);
      text-transform: uppercase;
      font-size: .75rem;
    }
  }

  p.--applepay-copy {
    --p-size: .625rem;
    color: var(--color-gray-2);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    a {
      font-weight: 400;
      text-decoration: underline;
      text-underline-offset: 1px;
      color: var(--color-gray-1);
    }
    .product-detail__container & {
      margin-top: 1rem;
      @include to_xs {
        padding-top: 0.8rem;
        border-top: 1px solid var(--ui-divider-color);
      }
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 1.1rem;
    &:not(:last-child) {
      margin-bottom: calc(var(--vertical-spacing) * var(--scale) * .5);
    }
    &.--centered {
      padding-left: 0;
      list-style-position: inside;
    }
  }

  ol {
    &.number-list {
      list-style: none;
      counter-reset: item;
      padding-top: 2px;
      @include xs {
        margin-top:  1rem;
      }

      li {
        counter-increment: item;
        position: relative;
        padding-left: 42px;
        text-align: left;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        &:before {
          content: counter(item);
          background: var(--color-gray-1);
          border-radius: 50%;
          color: var(--color-white);
          width: 28px;
          height: 28px;
          position: absolute;
          display: grid;
          place-content: center;
          font-size: 13px;
          padding: 2px 0 0px;
          line-height: 1em;
          left: 0;
          top: -2px;
          font-weight: 500;
        }
      }
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: calc(var(--vertical-spacing) * var(--scale) * .5);
    }

    line-height: 1.466666em;
  }

  a:focus-visible {
    outline-offset: 3px;
  }

  text-align: left;

  &.--align-center {
    text-align: center;
  }

  &.--align-right {
    text-align: right;
  }

  &.--headings-center {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }
  }

  &.--headings-border-bot {

    h1,
    h2,
    h3 {
      border-bottom: 1px solid var(--color-gray-5);
      padding-bottom: .7125rem;
    }
  }

  &.--headings-larger {
    --heading-scale: 1.25;
  }

  &.--headings-display {
    h2 {
      margin-bottom: calc(var(--vertical-spacing) * 1.4 * var(--scale) * var(--heading-scale));
    }
  }

  &.--caps {

    p,
    li {
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }
  }

  &.--contain-width {
    max-width: var(--container-width);
    margin: auto;

    &.--small-width {
      max-width: 450px;
    }
  }

  .--no-margin{
    margin: 0 !important;
  }
  &.--padding-top {
    padding-top: var(--spacing);
  }
  &.--padding-bot-small {
    padding-bottom: .6rem;
  }

  @include xs {
    &:not(.--remain-left, .--align-right, .--clean, .--info) {
      main & {
        padding-left: var(--offset-spacing);
        padding-right: var(--offset-spacing);
      }
    }
    &:not(.--remain-left, .--align-right), &.--align-center-xs {
      text-align: center;
    }

    .dialog.--full-height & {
      text-align: inherit;
    }
    &.--align-center-xs {
      text-align: center;
    }
  }

  &.--center-sm {
    --offset-spacing: 0;
    @include sm {
      text-align: center;
    }
  }

  .icon-heading {
    display: flex;
    gap: .5rem;
    justify-content: center;
    &__icon {
      width: 35px;
      height: fit-content;
    }
  }
}

.text-with-icon {
  &.--spaced {
    padding: var(--spacing) 0;
  }

  display: grid;
  gap: calc(var(--spacing) * .5);
  grid-template-columns: auto 1fr;

  &.--larger {
    gap: calc(var(--spacing) * .75);
  }

  @include xs {
    gap: calc(var(--spacing) * .5);
    grid-template-columns: 1fr;
    justify-items: center;
  }

}
